// Middleware for creating a project
import apiClient from '../api/apiClient'
import type {
    BinaryResultsResponse,
    ListProjectsOutput,
    ProjectId, SrcModelsSchemasProjectData,
} from '../api/openapi'

// Middleware for fetching all projects
export const fetchProjects = (): Promise<ListProjectsOutput> => {
    return apiClient.fetchProjectsApiV3FetchProjectsPost({})
}

export const fetchProjectData = (projectId: string): Promise<SrcModelsSchemasProjectData> => {
    return apiClient.fetchProjectDataV3FetchProjectDataPost({
        fetchHistory: {
            projectId,
        },
    })
}

// Middleware for creating a project
export const createProject = ({
    title,
    description,
    tags,
    collaborators,
}: {
    title: string
    description: string
    tags: string[]
    collaborators: string[]
}): Promise<ProjectId> => {
    return apiClient.createProjectApiV3CreateProjectPost({
        project: {
            title,
            description,
            tags,
            collaborators,
        },
    })
}

// Middleware for updating a project
export const updateProject = ({
    id,
    title,
    description,
    tags,
    collaborators,
}: {
    id: string
    title: string
    description: string
    tags: string[]
    collaborators: string[]
}): Promise<BinaryResultsResponse> => {
    return apiClient.updateProjectApiV3UpdateProjectPost({
        projectUpdate: {
            id,
            title,
            description,
            tags,
            collaborators,
            status: 'ACTIVE',
        },
    })
}

export const closeProject = (
    projectId: string
): Promise<BinaryResultsResponse> => {
    return apiClient.closeProjectApiV3CloseProjectPost({
        projectId: { projectId },
    })
}
