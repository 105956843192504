import { useEffect, useState } from 'react'
import LocalStorageUtil from '../utils/LocalStorageUtil'

// IMPORTANT: This hook is used to toggle the dark mode on the website
// Needs to be initialized in one of the components to work
// No need to use it in the component, just initialize it
const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const storedPreference = LocalStorageUtil.isDarkMode() // Get the stored preference from localStorage
        return storedPreference !== null ? storedPreference : false // Default to light mode if no preference exists
    })

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark') // Add 'dark' class to the root element
        } else {
            document.documentElement.classList.remove('dark') // Remove 'dark' class from the root element
        }

        // Save the dark mode preference to localStorage
        LocalStorageUtil.setDarkMode(isDarkMode)
    }, [isDarkMode])

    const toggleDarkMode = () => setIsDarkMode((prev) => !prev) // Toggle between dark and light mode

    return { isDarkMode, toggleDarkMode } // Return the current mode and the toggle function
}

export default useDarkMode
