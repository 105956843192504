/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcRoutersUserHistoryItem } from './SrcRoutersUserHistoryItem';
import {
    SrcRoutersUserHistoryItemFromJSON,
    SrcRoutersUserHistoryItemFromJSONTyped,
    SrcRoutersUserHistoryItemToJSON,
} from './SrcRoutersUserHistoryItem';

/**
 * 
 * @export
 * @interface UserHistoryV2
 */
export interface UserHistoryV2 {
    /**
     * List of history entries
     * @type {Array<SrcRoutersUserHistoryItem>}
     * @memberof UserHistoryV2
     */
    items?: Array<SrcRoutersUserHistoryItem>;
}

/**
 * Check if a given object implements the UserHistoryV2 interface.
 */
export function instanceOfUserHistoryV2(value: object): value is UserHistoryV2 {
    return true;
}

export function UserHistoryV2FromJSON(json: any): UserHistoryV2 {
    return UserHistoryV2FromJSONTyped(json, false);
}

export function UserHistoryV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserHistoryV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(SrcRoutersUserHistoryItemFromJSON)),
    };
}

export function UserHistoryV2ToJSON(value?: UserHistoryV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(SrcRoutersUserHistoryItemToJSON)),
    };
}

