/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Authorisation,
  AuthorisationResponse,
  BinaryResultsResponse,
  FeedbackForm,
  FeedbackFormV2,
  FetchArticles,
  FetchArticlesResponse,
  FetchCtsInfo,
  FetchFulltext,
  FetchFulltextFile,
  FetchFulltextResponse,
  FetchHistory,
  FetchIdeaSet,
  FetchIdeas,
  FetchIndividualSearch,
  FetchPicoInfo,
  HTTPValidationError,
  IdeaSetItem,
  IdeasSetResponse,
  IntroTextRequest,
  IntroTextRequestV2,
  IntroTextResponse,
  IntroTextResponseOld,
  ListArticleInfoItem,
  ListArticlesItem,
  ListClinicalTrials,
  ListIdeaHistoryItems,
  ListIdeaItems,
  ListMessages,
  ListProjectsOutput,
  ListResultsResponse,
  ListSearchHistoryItems,
  ListTextHistoryItems,
  MessageInput,
  NewPayment,
  PreviewSearch,
  PreviewSearchResponse,
  Project,
  ProjectId,
  ProjectStatus,
  ProjectUpdate,
  ProjectsResponse,
  QuestionsRequest,
  QuestionsRequestOld,
  QuestionsResponse,
  QuestionsResponseV2,
  RefreshTokenResponse,
  SearchArticle,
  SearchHistoryItem,
  SearchQueryAI,
  SearchQueryGenerationResponse,
  SearchQueryPICO,
  SearchTerm,
  SearchTermResponseV2,
  SetProjectStatus,
  SrcModelsSchemasIdeaItem,
  SrcModelsSchemasProjectData,
  SrcModelsSchemasSearchArticleResponse,
  SrcModelsSchemasSearchTermResponse,
  SrcModelsSchemasUserHistory,
  SrcModelsSchemasUserProfileOutput,
  SrcRoutersIdeaItem,
  SrcRoutersProjectData,
  SrcRoutersSearchArticleResponse,
  SrcRoutersSearchTermResponse,
  SrcRoutersUserHistory,
  SrcRoutersUserProfileOutput,
  StrResultsResponse,
  UserHistoryV2,
  UserProfile,
  UserProfileV2,
} from '../models/index';
import {
    AuthorisationFromJSON,
    AuthorisationToJSON,
    AuthorisationResponseFromJSON,
    AuthorisationResponseToJSON,
    BinaryResultsResponseFromJSON,
    BinaryResultsResponseToJSON,
    FeedbackFormFromJSON,
    FeedbackFormToJSON,
    FeedbackFormV2FromJSON,
    FeedbackFormV2ToJSON,
    FetchArticlesFromJSON,
    FetchArticlesToJSON,
    FetchArticlesResponseFromJSON,
    FetchArticlesResponseToJSON,
    FetchCtsInfoFromJSON,
    FetchCtsInfoToJSON,
    FetchFulltextFromJSON,
    FetchFulltextToJSON,
    FetchFulltextFileFromJSON,
    FetchFulltextFileToJSON,
    FetchFulltextResponseFromJSON,
    FetchFulltextResponseToJSON,
    FetchHistoryFromJSON,
    FetchHistoryToJSON,
    FetchIdeaSetFromJSON,
    FetchIdeaSetToJSON,
    FetchIdeasFromJSON,
    FetchIdeasToJSON,
    FetchIndividualSearchFromJSON,
    FetchIndividualSearchToJSON,
    FetchPicoInfoFromJSON,
    FetchPicoInfoToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    IdeaSetItemFromJSON,
    IdeaSetItemToJSON,
    IdeasSetResponseFromJSON,
    IdeasSetResponseToJSON,
    IntroTextRequestFromJSON,
    IntroTextRequestToJSON,
    IntroTextRequestV2FromJSON,
    IntroTextRequestV2ToJSON,
    IntroTextResponseFromJSON,
    IntroTextResponseToJSON,
    IntroTextResponseOldFromJSON,
    IntroTextResponseOldToJSON,
    ListArticleInfoItemFromJSON,
    ListArticleInfoItemToJSON,
    ListArticlesItemFromJSON,
    ListArticlesItemToJSON,
    ListClinicalTrialsFromJSON,
    ListClinicalTrialsToJSON,
    ListIdeaHistoryItemsFromJSON,
    ListIdeaHistoryItemsToJSON,
    ListIdeaItemsFromJSON,
    ListIdeaItemsToJSON,
    ListMessagesFromJSON,
    ListMessagesToJSON,
    ListProjectsOutputFromJSON,
    ListProjectsOutputToJSON,
    ListResultsResponseFromJSON,
    ListResultsResponseToJSON,
    ListSearchHistoryItemsFromJSON,
    ListSearchHistoryItemsToJSON,
    ListTextHistoryItemsFromJSON,
    ListTextHistoryItemsToJSON,
    MessageInputFromJSON,
    MessageInputToJSON,
    NewPaymentFromJSON,
    NewPaymentToJSON,
    PreviewSearchFromJSON,
    PreviewSearchToJSON,
    PreviewSearchResponseFromJSON,
    PreviewSearchResponseToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectIdFromJSON,
    ProjectIdToJSON,
    ProjectStatusFromJSON,
    ProjectStatusToJSON,
    ProjectUpdateFromJSON,
    ProjectUpdateToJSON,
    ProjectsResponseFromJSON,
    ProjectsResponseToJSON,
    QuestionsRequestFromJSON,
    QuestionsRequestToJSON,
    QuestionsRequestOldFromJSON,
    QuestionsRequestOldToJSON,
    QuestionsResponseFromJSON,
    QuestionsResponseToJSON,
    QuestionsResponseV2FromJSON,
    QuestionsResponseV2ToJSON,
    RefreshTokenResponseFromJSON,
    RefreshTokenResponseToJSON,
    SearchArticleFromJSON,
    SearchArticleToJSON,
    SearchHistoryItemFromJSON,
    SearchHistoryItemToJSON,
    SearchQueryAIFromJSON,
    SearchQueryAIToJSON,
    SearchQueryGenerationResponseFromJSON,
    SearchQueryGenerationResponseToJSON,
    SearchQueryPICOFromJSON,
    SearchQueryPICOToJSON,
    SearchTermFromJSON,
    SearchTermToJSON,
    SearchTermResponseV2FromJSON,
    SearchTermResponseV2ToJSON,
    SetProjectStatusFromJSON,
    SetProjectStatusToJSON,
    SrcModelsSchemasIdeaItemFromJSON,
    SrcModelsSchemasIdeaItemToJSON,
    SrcModelsSchemasProjectDataFromJSON,
    SrcModelsSchemasProjectDataToJSON,
    SrcModelsSchemasSearchArticleResponseFromJSON,
    SrcModelsSchemasSearchArticleResponseToJSON,
    SrcModelsSchemasSearchTermResponseFromJSON,
    SrcModelsSchemasSearchTermResponseToJSON,
    SrcModelsSchemasUserHistoryFromJSON,
    SrcModelsSchemasUserHistoryToJSON,
    SrcModelsSchemasUserProfileOutputFromJSON,
    SrcModelsSchemasUserProfileOutputToJSON,
    SrcRoutersIdeaItemFromJSON,
    SrcRoutersIdeaItemToJSON,
    SrcRoutersProjectDataFromJSON,
    SrcRoutersProjectDataToJSON,
    SrcRoutersSearchArticleResponseFromJSON,
    SrcRoutersSearchArticleResponseToJSON,
    SrcRoutersSearchTermResponseFromJSON,
    SrcRoutersSearchTermResponseToJSON,
    SrcRoutersUserHistoryFromJSON,
    SrcRoutersUserHistoryToJSON,
    SrcRoutersUserProfileOutputFromJSON,
    SrcRoutersUserProfileOutputToJSON,
    StrResultsResponseFromJSON,
    StrResultsResponseToJSON,
    UserHistoryV2FromJSON,
    UserHistoryV2ToJSON,
    UserProfileFromJSON,
    UserProfileToJSON,
    UserProfileV2FromJSON,
    UserProfileV2ToJSON,
} from '../models/index';

export interface AddMessageApiAddMessagePostRequest {
    messageInput: MessageInput;
}

export interface AddMessageApiV3AddMessagePostRequest {
    messageInput: MessageInput;
}

export interface ArticleSearchPubmedSearchArticlePostRequest {
    searchArticle: SearchArticle;
}

export interface ArticleSearchPubmedV2SearchArticleV2PostRequest {
    searchArticle: SearchArticle;
}

export interface ArticleSearchPubmedV3SearchArticlePostRequest {
    searchArticle: SearchArticle;
}

export interface ArticlesSearchPubmedSearchPostRequest {
    searchTerm: SearchTerm;
}

export interface ArticlesSearchPubmedV2SearchV2PostRequest {
    searchTerm: SearchTerm;
}

export interface ArticlesSearchPubmedV3SearchPostRequest {
    searchTerm: SearchTerm;
}

export interface CancelSubscriptionCancelSubscriptionPostRequest {
    newPayment: NewPayment;
}

export interface CancelSubscriptionV3CancelSubscriptionPostRequest {
    newPayment: NewPayment;
}

export interface CloseProjectApiCloseProjectPostRequest {
    projectId: ProjectId;
}

export interface CloseProjectApiV3CloseProjectPostRequest {
    projectId: ProjectId;
}

export interface CreateProjectApiCreateProjectPostRequest {
    project: Project;
}

export interface CreateProjectApiV3CreateProjectPostRequest {
    project: Project;
}

export interface CreateSubscriptionCreateSubscriptionPostRequest {
    newPayment: NewPayment;
}

export interface CreateSubscriptionV3CreateSubscriptionPostRequest {
    newPayment: NewPayment;
}

export interface DeleteProjectApiDeleteProjectPostRequest {
    projectId: ProjectId;
}

export interface DeleteProjectApiV3DeleteProjectPostRequest {
    projectId: ProjectId;
}

export interface FetchArticlesFetchArticlesPostRequest {
    fetchArticles: FetchArticles;
}

export interface FetchArticlesV2FetchArticlesV2PostRequest {
    fetchArticles: FetchArticles;
}

export interface FetchArticlesV3FetchArticlesPostRequest {
    fetchArticles: FetchArticles;
}

export interface FetchCtsInfoFetchCtsInfoPostRequest {
    fetchCtsInfo: FetchCtsInfo;
}

export interface FetchCtsInfoV2FetchCtsInfoV2PostRequest {
    fetchCtsInfo: FetchCtsInfo;
}

export interface FetchCtsInfoV3FetchCtsInfoPostRequest {
    fetchCtsInfo: FetchCtsInfo;
}

export interface FetchFulltextFetchFulltextFilePostRequest {
    fetchFulltextFile: FetchFulltextFile;
}

export interface FetchFulltextFetchFulltextPostRequest {
    fetchFulltext: FetchFulltext;
}

export interface FetchFulltextV3FetchFulltextFilePostRequest {
    fetchFulltextFile: FetchFulltextFile;
}

export interface FetchFulltextV3FetchFulltextPostRequest {
    fetchFulltext: FetchFulltext;
}

export interface FetchIdeasFetchIdeasPostRequest {
    fetchIdeas: FetchIdeas;
}

export interface FetchIdeasSetByIdeaIdFetchIdeaSetPostRequest {
    fetchIdeaSet: FetchIdeaSet;
}

export interface FetchIdeasSetByIdeaIdV2FetchIdeaSetV2PostRequest {
    fetchIdeaSet: FetchIdeaSet;
}

export interface FetchIdeasSetByIdeaIdV3FetchIdeaSetPostRequest {
    fetchIdeaSet: FetchIdeaSet;
}

export interface FetchIdeasV2FetchIdeasV2PostRequest {
    fetchIdeas: FetchIdeas;
}

export interface FetchIdeasV3FetchIdeasPostRequest {
    fetchIdeas: FetchIdeas;
}

export interface FetchIndividualIdeaFetchIndividualIdeaPostRequest {
    fetchIdeaSet: FetchIdeaSet;
}

export interface FetchIndividualIdeaV3FetchIndividualIdeaPostRequest {
    fetchIdeaSet: FetchIdeaSet;
}

export interface FetchIndividualIdeasSetFetchIndividualIdeasSetPostRequest {
    fetchIdeas: FetchIdeas;
}

export interface FetchIndividualIdeasSetV3FetchIndividualIdeasSetPostRequest {
    fetchIdeas: FetchIdeas;
}

export interface FetchMessagesApiFetchMessagesPostRequest {
    projectId: ProjectId;
}

export interface FetchMessagesApiV3FetchMessagesPostRequest {
    projectId: ProjectId;
}

export interface FetchPicoInfoFetchPicoInfoPostRequest {
    fetchPicoInfo: FetchPicoInfo;
}

export interface FetchPicoInfoV2FetchPicoInfoV2PostRequest {
    fetchPicoInfo: FetchPicoInfo;
}

export interface FetchPicoInfoV3FetchPicoInfoPostRequest {
    fetchPicoInfo: FetchPicoInfo;
}

export interface FetchProjectDataFetchProjectDataPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchProjectDataV3FetchProjectDataPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchProjectStatusApiFetchProjectStatusPostRequest {
    projectId: ProjectId;
}

export interface FetchUserHistoryFetchHistoryPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserHistoryFetchHistoryV2PostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserHistoryV3FetchHistoryPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserIdeaHistoryFetchIdeaHistoryPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserIdeaHistoryV2FetchIdeaHistoryV2PostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserIndividualSearchFetchIndividualSearchPostRequest {
    fetchIndividualSearch: FetchIndividualSearch;
}

export interface FetchUserIndividualSearchV2FetchIndividualSearchV2PostRequest {
    fetchIndividualSearch: FetchIndividualSearch;
}

export interface FetchUserIndividualSearchV3FetchIndividualSearchPostRequest {
    fetchIndividualSearch: FetchIndividualSearch;
}

export interface FetchUserSearchHistoryFetchSearchHistoryPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserSearchHistoryV2FetchSearchHistoryV2PostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserTextHistoryFetchTextHistoryPostRequest {
    fetchHistory: FetchHistory;
}

export interface FetchUserTextHistoryV2FetchTextHistoryV2PostRequest {
    fetchHistory: FetchHistory;
}

export interface GenerateProtocolApiV3GenerateIntroPostRequest {
    introTextRequestV2: IntroTextRequestV2;
}

export interface GenerateSearchQueryFromPicoGeneratePicoQueryPostRequest {
    searchQueryPICO: SearchQueryPICO;
}

export interface GenerateSearchQueryFromPicoV3GeneratePicoQueryPostRequest {
    searchQueryPICO: SearchQueryPICO;
}

export interface GenerateSearchQueryFromStringGenerateAiQueryPostRequest {
    searchQueryAI: SearchQueryAI;
}

export interface GenerateSearchQueryFromStringV3GenerateAiQueryPostRequest {
    searchQueryAI: SearchQueryAI;
}

export interface GetIntroductionTextGenerateIntroPostRequest {
    introTextRequest: IntroTextRequest;
}

export interface GetIntroductionTextV2GenerateIntroV2PostRequest {
    introTextRequestV2: IntroTextRequestV2;
}

export interface GetResearchQuestionsIdeasGeneratorPostRequest {
    questionsRequestOld: QuestionsRequestOld;
}

export interface GetResearchQuestionsV2IdeasGeneratorV2PostRequest {
    questionsRequest: QuestionsRequest;
}

export interface GetResearchQuestionsV3IdeasGeneratorPostRequest {
    questionsRequest: QuestionsRequest;
}

export interface GoogleAuthGoogleAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface GoogleAuthV3GoogleAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface LinkedinAuthLinkedinAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface LinkedinAuthV3LinkedinAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface MicrosoftAuthMicrosoftAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface MicrosoftAuthV3MicrosoftAuthorisePostRequest {
    authorisation: Authorisation;
}

export interface PreviewSearchPubmedSearchPreviewPostRequest {
    previewSearch: PreviewSearch;
}

export interface PreviewSearchPubmedV3SearchPreviewPostRequest {
    previewSearch: PreviewSearch;
}

export interface PurchaseProtocolV3PurchaseProtocolPostRequest {
    newPayment: NewPayment;
}

export interface RefreshAccessTokenTokenRefreshPostRequest {
    refreshToken: string;
}

export interface RefreshAccessTokenV3TokenRefreshPostRequest {
    refreshToken: string;
}

export interface RegisterUserProfileCreateProfilePostRequest {
    userProfile: UserProfile;
}

export interface RegisterUserProfileCreateProfileV2PostRequest {
    userProfileV2: UserProfileV2;
}

export interface RegisterUserProfileV3CreateProfilePostRequest {
    userProfileV2: UserProfileV2;
}

export interface SetProjectStatusApiSetProjectStatusPostRequest {
    setProjectStatus: SetProjectStatus;
}

export interface SubmitFeedbackSubmitFeedbackPostRequest {
    feedbackForm: FeedbackForm;
}

export interface SubmitFeedbackV3SubmitFeedbackPostRequest {
    feedbackFormV2: FeedbackFormV2;
}

export interface UpdateProjectApiUpdateProjectPostRequest {
    projectUpdate: ProjectUpdate;
}

export interface UpdateProjectApiV3UpdateProjectPostRequest {
    projectUpdate: ProjectUpdate;
}

export interface UpdateUserProfV3UpdateProfilePostRequest {
    userProfileV2: UserProfileV2;
}

export interface UpdateUserProfileUpdateProfilePostRequest {
    userProfile: UserProfile;
}

export interface UpdateUserProfileUpdateProfileV2PostRequest {
    userProfileV2: UserProfileV2;
}

export interface LoginV3LoginAuthorisePostRequest {
    login: {
        email: string;
        password: string;
    };
}

export interface PasswordResetV3PasswordResetPostRequest {
    email: string;
}

export interface SetPasswordV3SetPasswordPostRequest {
    verification_token: string;
    password: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add new message to a given project and stage
     * Add Message Api
     */
    async addMessageApiAddMessagePostRaw(requestParameters: AddMessageApiAddMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['messageInput'] == null) {
            throw new runtime.RequiredError(
                'messageInput',
                'Required parameter "messageInput" was null or undefined when calling addMessageApiAddMessagePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/add_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageInputToJSON(requestParameters['messageInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Add new message to a given project and stage
     * Add Message Api
     */
    async addMessageApiAddMessagePost(requestParameters: AddMessageApiAddMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.addMessageApiAddMessagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add new message to a given project and stage
     * Add Message Api
     */
    async addMessageApiV3AddMessagePostRaw(requestParameters: AddMessageApiV3AddMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['messageInput'] == null) {
            throw new runtime.RequiredError(
                'messageInput',
                'Required parameter "messageInput" was null or undefined when calling addMessageApiV3AddMessagePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/add_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageInputToJSON(requestParameters['messageInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Add new message to a given project and stage
     * Add Message Api
     */
    async addMessageApiV3AddMessagePost(requestParameters: AddMessageApiV3AddMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.addMessageApiV3AddMessagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed
     */
    async articleSearchPubmedSearchArticlePostRaw(requestParameters: ArticleSearchPubmedSearchArticlePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersSearchArticleResponse>> {
        if (requestParameters['searchArticle'] == null) {
            throw new runtime.RequiredError(
                'searchArticle',
                'Required parameter "searchArticle" was null or undefined when calling articleSearchPubmedSearchArticlePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search_article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchArticleToJSON(requestParameters['searchArticle']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersSearchArticleResponseFromJSON(jsonValue));
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed
     */
    async articleSearchPubmedSearchArticlePost(requestParameters: ArticleSearchPubmedSearchArticlePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersSearchArticleResponse> {
        const response = await this.articleSearchPubmedSearchArticlePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed V2
     */
    async articleSearchPubmedV2SearchArticleV2PostRaw(requestParameters: ArticleSearchPubmedV2SearchArticleV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersSearchArticleResponse>> {
        if (requestParameters['searchArticle'] == null) {
            throw new runtime.RequiredError(
                'searchArticle',
                'Required parameter "searchArticle" was null or undefined when calling articleSearchPubmedV2SearchArticleV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search_article/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchArticleToJSON(requestParameters['searchArticle']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersSearchArticleResponseFromJSON(jsonValue));
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed V2
     */
    async articleSearchPubmedV2SearchArticleV2Post(requestParameters: ArticleSearchPubmedV2SearchArticleV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersSearchArticleResponse> {
        const response = await this.articleSearchPubmedV2SearchArticleV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed
     */
    async articleSearchPubmedV3SearchArticlePostRaw(requestParameters: ArticleSearchPubmedV3SearchArticlePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasSearchArticleResponse>> {
        if (requestParameters['searchArticle'] == null) {
            throw new runtime.RequiredError(
                'searchArticle',
                'Required parameter "searchArticle" was null or undefined when calling articleSearchPubmedV3SearchArticlePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/search_article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchArticleToJSON(requestParameters['searchArticle']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasSearchArticleResponseFromJSON(jsonValue));
    }

    /**
     * Search for individual articles based on a given DOI/s.
     * Article Search Pubmed
     */
    async articleSearchPubmedV3SearchArticlePost(requestParameters: ArticleSearchPubmedV3SearchArticlePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasSearchArticleResponse> {
        const response = await this.articleSearchPubmedV3SearchArticlePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed
     */
    async articlesSearchPubmedSearchPostRaw(requestParameters: ArticlesSearchPubmedSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersSearchTermResponse>> {
        if (requestParameters['searchTerm'] == null) {
            throw new runtime.RequiredError(
                'searchTerm',
                'Required parameter "searchTerm" was null or undefined when calling articlesSearchPubmedSearchPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchTermToJSON(requestParameters['searchTerm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersSearchTermResponseFromJSON(jsonValue));
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed
     */
    async articlesSearchPubmedSearchPost(requestParameters: ArticlesSearchPubmedSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersSearchTermResponse> {
        const response = await this.articlesSearchPubmedSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed V2
     */
    async articlesSearchPubmedV2SearchV2PostRaw(requestParameters: ArticlesSearchPubmedV2SearchV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchTermResponseV2>> {
        if (requestParameters['searchTerm'] == null) {
            throw new runtime.RequiredError(
                'searchTerm',
                'Required parameter "searchTerm" was null or undefined when calling articlesSearchPubmedV2SearchV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchTermToJSON(requestParameters['searchTerm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchTermResponseV2FromJSON(jsonValue));
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed V2
     */
    async articlesSearchPubmedV2SearchV2Post(requestParameters: ArticlesSearchPubmedV2SearchV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchTermResponseV2> {
        const response = await this.articlesSearchPubmedV2SearchV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed
     */
    async articlesSearchPubmedV3SearchPostRaw(requestParameters: ArticlesSearchPubmedV3SearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasSearchTermResponse>> {
        if (requestParameters['searchTerm'] == null) {
            throw new runtime.RequiredError(
                'searchTerm',
                'Required parameter "searchTerm" was null or undefined when calling articlesSearchPubmedV3SearchPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchTermToJSON(requestParameters['searchTerm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasSearchTermResponseFromJSON(jsonValue));
    }

    /**
     * Search articles in PubMed, based on a given search query.
     * Articles Search Pubmed
     */
    async articlesSearchPubmedV3SearchPost(requestParameters: ArticlesSearchPubmedV3SearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasSearchTermResponse> {
        const response = await this.articlesSearchPubmedV3SearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel an existing subscription
     * Cancel Subscription
     */
    async cancelSubscriptionCancelSubscriptionPostRaw(requestParameters: CancelSubscriptionCancelSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['newPayment'] == null) {
            throw new runtime.RequiredError(
                'newPayment',
                'Required parameter "newPayment" was null or undefined when calling cancelSubscriptionCancelSubscriptionPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cancel_subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentToJSON(requestParameters['newPayment']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cancel an existing subscription
     * Cancel Subscription
     */
    async cancelSubscriptionCancelSubscriptionPost(requestParameters: CancelSubscriptionCancelSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.cancelSubscriptionCancelSubscriptionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel an existing subscription
     * Cancel Subscription
     */
    async cancelSubscriptionV3CancelSubscriptionPostRaw(requestParameters: CancelSubscriptionV3CancelSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['newPayment'] == null) {
            throw new runtime.RequiredError(
                'newPayment',
                'Required parameter "newPayment" was null or undefined when calling cancelSubscriptionV3CancelSubscriptionPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/cancel_subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentToJSON(requestParameters['newPayment']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cancel an existing subscription
     * Cancel Subscription
     */
    async cancelSubscriptionV3CancelSubscriptionPost(requestParameters: CancelSubscriptionV3CancelSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.cancelSubscriptionV3CancelSubscriptionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Close active project
     * Close Project Api
     */
    async closeProjectApiCloseProjectPostRaw(requestParameters: CloseProjectApiCloseProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling closeProjectApiCloseProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/close_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Close active project
     * Close Project Api
     */
    async closeProjectApiCloseProjectPost(requestParameters: CloseProjectApiCloseProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.closeProjectApiCloseProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Close active project
     * Close Project Api
     */
    async closeProjectApiV3CloseProjectPostRaw(requestParameters: CloseProjectApiV3CloseProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling closeProjectApiV3CloseProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/close_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Close active project
     * Close Project Api
     */
    async closeProjectApiV3CloseProjectPost(requestParameters: CloseProjectApiV3CloseProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.closeProjectApiV3CloseProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new project with the provided information.
     * Create Project Api
     */
    async createProjectApiCreateProjectPostRaw(requestParameters: CreateProjectApiCreateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectId>> {
        if (requestParameters['project'] == null) {
            throw new runtime.RequiredError(
                'project',
                'Required parameter "project" was null or undefined when calling createProjectApiCreateProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters['project']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectIdFromJSON(jsonValue));
    }

    /**
     * Create a new project with the provided information.
     * Create Project Api
     */
    async createProjectApiCreateProjectPost(requestParameters: CreateProjectApiCreateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectId> {
        const response = await this.createProjectApiCreateProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new project with the provided information.
     * Create Project Api
     */
    async createProjectApiV3CreateProjectPostRaw(requestParameters: CreateProjectApiV3CreateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectId>> {
        if (requestParameters['project'] == null) {
            throw new runtime.RequiredError(
                'project',
                'Required parameter "project" was null or undefined when calling createProjectApiV3CreateProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/create_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters['project']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectIdFromJSON(jsonValue));
    }

    /**
     * Create a new project with the provided information.
     * Create Project Api
     */
    async createProjectApiV3CreateProjectPost(requestParameters: CreateProjectApiV3CreateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectId> {
        const response = await this.createProjectApiV3CreateProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process new subscription
     * Create Subscription
     */
    async createSubscriptionCreateSubscriptionPostRaw(requestParameters: CreateSubscriptionCreateSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['newPayment'] == null) {
            throw new runtime.RequiredError(
                'newPayment',
                'Required parameter "newPayment" was null or undefined when calling createSubscriptionCreateSubscriptionPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create_subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentToJSON(requestParameters['newPayment']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Process new subscription
     * Create Subscription
     */
    async createSubscriptionCreateSubscriptionPost(requestParameters: CreateSubscriptionCreateSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createSubscriptionCreateSubscriptionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process new subscription
     * Create Subscription
     */
    async createSubscriptionV3CreateSubscriptionPostRaw(requestParameters: CreateSubscriptionV3CreateSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['newPayment'] == null) {
            throw new runtime.RequiredError(
                'newPayment',
                'Required parameter "newPayment" was null or undefined when calling createSubscriptionV3CreateSubscriptionPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/create_subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentToJSON(requestParameters['newPayment']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Process new subscription
     * Create Subscription
     */
    async createSubscriptionV3CreateSubscriptionPost(requestParameters: CreateSubscriptionV3CreateSubscriptionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createSubscriptionV3CreateSubscriptionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete user profile
     * Delete Profile
     */
    async deleteProfileDeleteProfilePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delete_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Delete user profile
     * Delete Profile
     */
    async deleteProfileDeleteProfilePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.deleteProfileDeleteProfilePostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete user profile
     * Delete Profile
     */
    async deleteProfileV3DeleteProfilePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/delete_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Delete user profile
     * Delete Profile
     */
    async deleteProfileV3DeleteProfilePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.deleteProfileV3DeleteProfilePostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete project
     * Delete Project Api
     */
    async deleteProjectApiDeleteProjectPostRaw(requestParameters: DeleteProjectApiDeleteProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling deleteProjectApiDeleteProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delete_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Delete project
     * Delete Project Api
     */
    async deleteProjectApiDeleteProjectPost(requestParameters: DeleteProjectApiDeleteProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.deleteProjectApiDeleteProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete project
     * Delete Project Api
     */
    async deleteProjectApiV3DeleteProjectPostRaw(requestParameters: DeleteProjectApiV3DeleteProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling deleteProjectApiV3DeleteProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/delete_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Delete project
     * Delete Project Api
     */
    async deleteProjectApiV3DeleteProjectPost(requestParameters: DeleteProjectApiV3DeleteProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.deleteProjectApiV3DeleteProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles
     */
    async fetchArticlesFetchArticlesPostRaw(requestParameters: FetchArticlesFetchArticlesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchArticlesResponse>> {
        if (requestParameters['fetchArticles'] == null) {
            throw new runtime.RequiredError(
                'fetchArticles',
                'Required parameter "fetchArticles" was null or undefined when calling fetchArticlesFetchArticlesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_articles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchArticlesToJSON(requestParameters['fetchArticles']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchArticlesResponseFromJSON(jsonValue));
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles
     */
    async fetchArticlesFetchArticlesPost(requestParameters: FetchArticlesFetchArticlesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchArticlesResponse> {
        const response = await this.fetchArticlesFetchArticlesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles V2
     */
    async fetchArticlesV2FetchArticlesV2PostRaw(requestParameters: FetchArticlesV2FetchArticlesV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListArticlesItem>> {
        if (requestParameters['fetchArticles'] == null) {
            throw new runtime.RequiredError(
                'fetchArticles',
                'Required parameter "fetchArticles" was null or undefined when calling fetchArticlesV2FetchArticlesV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_articles/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchArticlesToJSON(requestParameters['fetchArticles']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListArticlesItemFromJSON(jsonValue));
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles V2
     */
    async fetchArticlesV2FetchArticlesV2Post(requestParameters: FetchArticlesV2FetchArticlesV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListArticlesItem> {
        const response = await this.fetchArticlesV2FetchArticlesV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles
     */
    async fetchArticlesV3FetchArticlesPostRaw(requestParameters: FetchArticlesV3FetchArticlesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListArticlesItem>> {
        if (requestParameters['fetchArticles'] == null) {
            throw new runtime.RequiredError(
                'fetchArticles',
                'Required parameter "fetchArticles" was null or undefined when calling fetchArticlesV3FetchArticlesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_articles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchArticlesToJSON(requestParameters['fetchArticles']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListArticlesItemFromJSON(jsonValue));
    }

    /**
     * Fetch information available from a set of articles and reviews.
     * Fetch Articles
     */
    async fetchArticlesV3FetchArticlesPost(requestParameters: FetchArticlesV3FetchArticlesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListArticlesItem> {
        const response = await this.fetchArticlesV3FetchArticlesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info
     */
    async fetchCtsInfoFetchCtsInfoPostRaw(requestParameters: FetchCtsInfoFetchCtsInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListResultsResponse>> {
        if (requestParameters['fetchCtsInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchCtsInfo',
                'Required parameter "fetchCtsInfo" was null or undefined when calling fetchCtsInfoFetchCtsInfoPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_cts_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchCtsInfoToJSON(requestParameters['fetchCtsInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info
     */
    async fetchCtsInfoFetchCtsInfoPost(requestParameters: FetchCtsInfoFetchCtsInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListResultsResponse> {
        const response = await this.fetchCtsInfoFetchCtsInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info V2
     */
    async fetchCtsInfoV2FetchCtsInfoV2PostRaw(requestParameters: FetchCtsInfoV2FetchCtsInfoV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListClinicalTrials>> {
        if (requestParameters['fetchCtsInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchCtsInfo',
                'Required parameter "fetchCtsInfo" was null or undefined when calling fetchCtsInfoV2FetchCtsInfoV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_cts_info/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchCtsInfoToJSON(requestParameters['fetchCtsInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListClinicalTrialsFromJSON(jsonValue));
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info V2
     */
    async fetchCtsInfoV2FetchCtsInfoV2Post(requestParameters: FetchCtsInfoV2FetchCtsInfoV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListClinicalTrials> {
        const response = await this.fetchCtsInfoV2FetchCtsInfoV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info
     */
    async fetchCtsInfoV3FetchCtsInfoPostRaw(requestParameters: FetchCtsInfoV3FetchCtsInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListClinicalTrials>> {
        if (requestParameters['fetchCtsInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchCtsInfo',
                'Required parameter "fetchCtsInfo" was null or undefined when calling fetchCtsInfoV3FetchCtsInfoPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_cts_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchCtsInfoToJSON(requestParameters['fetchCtsInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListClinicalTrialsFromJSON(jsonValue));
    }

    /**
     * Fetch clinical trials information.
     * Fetch Cts Info
     */
    async fetchCtsInfoV3FetchCtsInfoPost(requestParameters: FetchCtsInfoV3FetchCtsInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListClinicalTrials> {
        const response = await this.fetchCtsInfoV3FetchCtsInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch protocol file (PDF or DOCX).
     * Fetch Fulltext
     */
    async fetchFulltextFetchFulltextFilePostRaw(requestParameters: FetchFulltextFetchFulltextFilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['fetchFulltextFile'] == null) {
            throw new runtime.RequiredError(
                'fetchFulltextFile',
                'Required parameter "fetchFulltextFile" was null or undefined when calling fetchFulltextFetchFulltextFilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_fulltext_file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchFulltextFileToJSON(requestParameters['fetchFulltextFile']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch protocol file (PDF or DOCX).
     * Fetch Fulltext
     */
    async fetchFulltextFetchFulltextFilePost(requestParameters: FetchFulltextFetchFulltextFilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.fetchFulltextFetchFulltextFilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch content of a systematic review protocol.
     * Fetch Fulltext
     */
    async fetchFulltextFetchFulltextPostRaw(requestParameters: FetchFulltextFetchFulltextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchFulltextResponse>> {
        if (requestParameters['fetchFulltext'] == null) {
            throw new runtime.RequiredError(
                'fetchFulltext',
                'Required parameter "fetchFulltext" was null or undefined when calling fetchFulltextFetchFulltextPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_fulltext`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchFulltextToJSON(requestParameters['fetchFulltext']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchFulltextResponseFromJSON(jsonValue));
    }

    /**
     * Fetch content of a systematic review protocol.
     * Fetch Fulltext
     */
    async fetchFulltextFetchFulltextPost(requestParameters: FetchFulltextFetchFulltextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchFulltextResponse> {
        const response = await this.fetchFulltextFetchFulltextPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch protocol file (PDF or DOCX).
     * Fetch Fulltext
     */
    async fetchFulltextV3FetchFulltextFilePostRaw(requestParameters: FetchFulltextV3FetchFulltextFilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['fetchFulltextFile'] == null) {
            throw new runtime.RequiredError(
                'fetchFulltextFile',
                'Required parameter "fetchFulltextFile" was null or undefined when calling fetchFulltextV3FetchFulltextFilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_fulltext_file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchFulltextFileToJSON(requestParameters['fetchFulltextFile']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Fetch protocol file (PDF or DOCX).
     * Fetch Fulltext
     */
    async fetchFulltextV3FetchFulltextFilePost(requestParameters: FetchFulltextV3FetchFulltextFilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.fetchFulltextV3FetchFulltextFilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch content of a systematic review protocol.
     * Fetch Fulltext
     */
    async fetchFulltextV3FetchFulltextPostRaw(requestParameters: FetchFulltextV3FetchFulltextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchFulltextResponse>> {
        if (requestParameters['fetchFulltext'] == null) {
            throw new runtime.RequiredError(
                'fetchFulltext',
                'Required parameter "fetchFulltext" was null or undefined when calling fetchFulltextV3FetchFulltextPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_fulltext`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchFulltextToJSON(requestParameters['fetchFulltext']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchFulltextResponseFromJSON(jsonValue));
    }

    /**
     * Fetch content of a systematic review protocol.
     * Fetch Fulltext
     */
    async fetchFulltextV3FetchFulltextPost(requestParameters: FetchFulltextV3FetchFulltextPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchFulltextResponse> {
        const response = await this.fetchFulltextV3FetchFulltextPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas
     */
    async fetchIdeasFetchIdeasPostRaw(requestParameters: FetchIdeasFetchIdeasPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchIdeas'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeas',
                'Required parameter "fetchIdeas" was null or undefined when calling fetchIdeasFetchIdeasPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_ideas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeasToJSON(requestParameters['fetchIdeas']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas
     */
    async fetchIdeasFetchIdeasPost(requestParameters: FetchIdeasFetchIdeasPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchIdeasFetchIdeasPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id
     */
    async fetchIdeasSetByIdeaIdFetchIdeaSetPostRaw(requestParameters: FetchIdeasSetByIdeaIdFetchIdeaSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchIdeaSet'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeaSet',
                'Required parameter "fetchIdeaSet" was null or undefined when calling fetchIdeasSetByIdeaIdFetchIdeaSetPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_idea_set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeaSetToJSON(requestParameters['fetchIdeaSet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id
     */
    async fetchIdeasSetByIdeaIdFetchIdeaSetPost(requestParameters: FetchIdeasSetByIdeaIdFetchIdeaSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchIdeasSetByIdeaIdFetchIdeaSetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id V2
     */
    async fetchIdeasSetByIdeaIdV2FetchIdeaSetV2PostRaw(requestParameters: FetchIdeasSetByIdeaIdV2FetchIdeaSetV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdeaSetItem>> {
        if (requestParameters['fetchIdeaSet'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeaSet',
                'Required parameter "fetchIdeaSet" was null or undefined when calling fetchIdeasSetByIdeaIdV2FetchIdeaSetV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_idea_set/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeaSetToJSON(requestParameters['fetchIdeaSet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaSetItemFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id V2
     */
    async fetchIdeasSetByIdeaIdV2FetchIdeaSetV2Post(requestParameters: FetchIdeasSetByIdeaIdV2FetchIdeaSetV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdeaSetItem> {
        const response = await this.fetchIdeasSetByIdeaIdV2FetchIdeaSetV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id
     */
    async fetchIdeasSetByIdeaIdV3FetchIdeaSetPostRaw(requestParameters: FetchIdeasSetByIdeaIdV3FetchIdeaSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdeaSetItem>> {
        if (requestParameters['fetchIdeaSet'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeaSet',
                'Required parameter "fetchIdeaSet" was null or undefined when calling fetchIdeasSetByIdeaIdV3FetchIdeaSetPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_idea_set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeaSetToJSON(requestParameters['fetchIdeaSet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaSetItemFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea id.
     * Fetch Ideas Set By Idea Id
     */
    async fetchIdeasSetByIdeaIdV3FetchIdeaSetPost(requestParameters: FetchIdeasSetByIdeaIdV3FetchIdeaSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdeaSetItem> {
        const response = await this.fetchIdeasSetByIdeaIdV3FetchIdeaSetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea set id.
     * Fetch Ideas V2
     */
    async fetchIdeasV2FetchIdeasV2PostRaw(requestParameters: FetchIdeasV2FetchIdeasV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListIdeaItems>> {
        if (requestParameters['fetchIdeas'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeas',
                'Required parameter "fetchIdeas" was null or undefined when calling fetchIdeasV2FetchIdeasV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_ideas/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeasToJSON(requestParameters['fetchIdeas']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIdeaItemsFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea set id.
     * Fetch Ideas V2
     */
    async fetchIdeasV2FetchIdeasV2Post(requestParameters: FetchIdeasV2FetchIdeasV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListIdeaItems> {
        const response = await this.fetchIdeasV2FetchIdeasV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas set by a given idea set id.
     * Fetch Ideas
     */
    async fetchIdeasV3FetchIdeasPostRaw(requestParameters: FetchIdeasV3FetchIdeasPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListIdeaItems>> {
        if (requestParameters['fetchIdeas'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeas',
                'Required parameter "fetchIdeas" was null or undefined when calling fetchIdeasV3FetchIdeasPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_ideas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeasToJSON(requestParameters['fetchIdeas']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIdeaItemsFromJSON(jsonValue));
    }

    /**
     * Fetch ideas set by a given idea set id.
     * Fetch Ideas
     */
    async fetchIdeasV3FetchIdeasPost(requestParameters: FetchIdeasV3FetchIdeasPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListIdeaItems> {
        const response = await this.fetchIdeasV3FetchIdeasPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given idea.
     * Fetch Individual Idea
     */
    async fetchIndividualIdeaFetchIndividualIdeaPostRaw(requestParameters: FetchIndividualIdeaFetchIndividualIdeaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersIdeaItem>> {
        if (requestParameters['fetchIdeaSet'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeaSet',
                'Required parameter "fetchIdeaSet" was null or undefined when calling fetchIndividualIdeaFetchIndividualIdeaPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_individual_idea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeaSetToJSON(requestParameters['fetchIdeaSet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersIdeaItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given idea.
     * Fetch Individual Idea
     */
    async fetchIndividualIdeaFetchIndividualIdeaPost(requestParameters: FetchIndividualIdeaFetchIndividualIdeaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersIdeaItem> {
        const response = await this.fetchIndividualIdeaFetchIndividualIdeaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given idea.
     * Fetch Individual Idea
     */
    async fetchIndividualIdeaV3FetchIndividualIdeaPostRaw(requestParameters: FetchIndividualIdeaV3FetchIndividualIdeaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasIdeaItem>> {
        if (requestParameters['fetchIdeaSet'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeaSet',
                'Required parameter "fetchIdeaSet" was null or undefined when calling fetchIndividualIdeaV3FetchIndividualIdeaPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_individual_idea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeaSetToJSON(requestParameters['fetchIdeaSet']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasIdeaItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given idea.
     * Fetch Individual Idea
     */
    async fetchIndividualIdeaV3FetchIndividualIdeaPost(requestParameters: FetchIndividualIdeaV3FetchIndividualIdeaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasIdeaItem> {
        const response = await this.fetchIndividualIdeaV3FetchIndividualIdeaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given ideas set.
     * Fetch Individual Ideas Set
     */
    async fetchIndividualIdeasSetFetchIndividualIdeasSetPostRaw(requestParameters: FetchIndividualIdeasSetFetchIndividualIdeasSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdeaSetItem>> {
        if (requestParameters['fetchIdeas'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeas',
                'Required parameter "fetchIdeas" was null or undefined when calling fetchIndividualIdeasSetFetchIndividualIdeasSetPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_individual_ideas_set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeasToJSON(requestParameters['fetchIdeas']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaSetItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given ideas set.
     * Fetch Individual Ideas Set
     */
    async fetchIndividualIdeasSetFetchIndividualIdeasSetPost(requestParameters: FetchIndividualIdeasSetFetchIndividualIdeasSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdeaSetItem> {
        const response = await this.fetchIndividualIdeasSetFetchIndividualIdeasSetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given ideas set.
     * Fetch Individual Ideas Set
     */
    async fetchIndividualIdeasSetV3FetchIndividualIdeasSetPostRaw(requestParameters: FetchIndividualIdeasSetV3FetchIndividualIdeasSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdeaSetItem>> {
        if (requestParameters['fetchIdeas'] == null) {
            throw new runtime.RequiredError(
                'fetchIdeas',
                'Required parameter "fetchIdeas" was null or undefined when calling fetchIndividualIdeasSetV3FetchIndividualIdeasSetPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_individual_ideas_set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIdeasToJSON(requestParameters['fetchIdeas']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeaSetItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given ideas set.
     * Fetch Individual Ideas Set
     */
    async fetchIndividualIdeasSetV3FetchIndividualIdeasSetPost(requestParameters: FetchIndividualIdeasSetV3FetchIndividualIdeasSetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdeaSetItem> {
        const response = await this.fetchIndividualIdeasSetV3FetchIndividualIdeasSetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches project\'s messages
     * Fetch Messages Api
     */
    async fetchMessagesApiFetchMessagesPostRaw(requestParameters: FetchMessagesApiFetchMessagesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListMessages>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling fetchMessagesApiFetchMessagesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListMessagesFromJSON(jsonValue));
    }

    /**
     * Fetches project\'s messages
     * Fetch Messages Api
     */
    async fetchMessagesApiFetchMessagesPost(requestParameters: FetchMessagesApiFetchMessagesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListMessages> {
        const response = await this.fetchMessagesApiFetchMessagesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches project\'s messages
     * Fetch Messages Api
     */
    async fetchMessagesApiV3FetchMessagesPostRaw(requestParameters: FetchMessagesApiV3FetchMessagesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListMessages>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling fetchMessagesApiV3FetchMessagesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListMessagesFromJSON(jsonValue));
    }

    /**
     * Fetches project\'s messages
     * Fetch Messages Api
     */
    async fetchMessagesApiV3FetchMessagesPost(requestParameters: FetchMessagesApiV3FetchMessagesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListMessages> {
        const response = await this.fetchMessagesApiV3FetchMessagesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info
     */
    async fetchPicoInfoFetchPicoInfoPostRaw(requestParameters: FetchPicoInfoFetchPicoInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FetchArticlesResponse>> {
        if (requestParameters['fetchPicoInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchPicoInfo',
                'Required parameter "fetchPicoInfo" was null or undefined when calling fetchPicoInfoFetchPicoInfoPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_pico_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchPicoInfoToJSON(requestParameters['fetchPicoInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetchArticlesResponseFromJSON(jsonValue));
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info
     */
    async fetchPicoInfoFetchPicoInfoPost(requestParameters: FetchPicoInfoFetchPicoInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FetchArticlesResponse> {
        const response = await this.fetchPicoInfoFetchPicoInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info V2
     */
    async fetchPicoInfoV2FetchPicoInfoV2PostRaw(requestParameters: FetchPicoInfoV2FetchPicoInfoV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListArticleInfoItem>> {
        if (requestParameters['fetchPicoInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchPicoInfo',
                'Required parameter "fetchPicoInfo" was null or undefined when calling fetchPicoInfoV2FetchPicoInfoV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_pico_info/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchPicoInfoToJSON(requestParameters['fetchPicoInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListArticleInfoItemFromJSON(jsonValue));
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info V2
     */
    async fetchPicoInfoV2FetchPicoInfoV2Post(requestParameters: FetchPicoInfoV2FetchPicoInfoV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListArticleInfoItem> {
        const response = await this.fetchPicoInfoV2FetchPicoInfoV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info
     */
    async fetchPicoInfoV3FetchPicoInfoPostRaw(requestParameters: FetchPicoInfoV3FetchPicoInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListArticleInfoItem>> {
        if (requestParameters['fetchPicoInfo'] == null) {
            throw new runtime.RequiredError(
                'fetchPicoInfo',
                'Required parameter "fetchPicoInfo" was null or undefined when calling fetchPicoInfoV3FetchPicoInfoPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_pico_info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchPicoInfoToJSON(requestParameters['fetchPicoInfo']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListArticleInfoItemFromJSON(jsonValue));
    }

    /**
     * Fetch PICO information available from a set of articles and reviews.
     * Fetch Pico Info
     */
    async fetchPicoInfoV3FetchPicoInfoPost(requestParameters: FetchPicoInfoV3FetchPicoInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListArticleInfoItem> {
        const response = await this.fetchPicoInfoV3FetchPicoInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile
     */
    async fetchProfileFetchProfilePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListResultsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile
     */
    async fetchProfileFetchProfilePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListResultsResponse> {
        const response = await this.fetchProfileFetchProfilePostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile V2
     */
    async fetchProfileV2FetchProfileV2PostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersUserProfileOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_profile/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersUserProfileOutputFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile V2
     */
    async fetchProfileV2FetchProfileV2Post(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersUserProfileOutput> {
        const response = await this.fetchProfileV2FetchProfileV2PostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile
     */
    async fetchProfileV3FetchProfilePostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasUserProfileOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasUserProfileOutputFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s profile.
     * Fetch Profile
     */
    async fetchProfileV3FetchProfilePost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasUserProfileOutput> {
        const response = await this.fetchProfileV3FetchProfilePostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch project information, history and ownership
     * Fetch Project Data
     */
    async fetchProjectDataFetchProjectDataPostRaw(requestParameters: FetchProjectDataFetchProjectDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersProjectData>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchProjectDataFetchProjectDataPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_project_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersProjectDataFromJSON(jsonValue));
    }

    /**
     * Fetch project information, history and ownership
     * Fetch Project Data
     */
    async fetchProjectDataFetchProjectDataPost(requestParameters: FetchProjectDataFetchProjectDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersProjectData> {
        const response = await this.fetchProjectDataFetchProjectDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch project information, history and ownership
     * Fetch Project Data
     */
    async fetchProjectDataV3FetchProjectDataPostRaw(requestParameters: FetchProjectDataV3FetchProjectDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasProjectData>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchProjectDataV3FetchProjectDataPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_project_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasProjectDataFromJSON(jsonValue));
    }

    /**
     * Fetch project information, history and ownership
     * Fetch Project Data
     */
    async fetchProjectDataV3FetchProjectDataPost(requestParameters: FetchProjectDataV3FetchProjectDataPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasProjectData> {
        const response = await this.fetchProjectDataV3FetchProjectDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches project\'s status (version to be shared with collaborators)
     * Fetch Project Status Api
     */
    async fetchProjectStatusApiFetchProjectStatusPostRaw(requestParameters: FetchProjectStatusApiFetchProjectStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectStatus>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling fetchProjectStatusApiFetchProjectStatusPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_project_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectIdToJSON(requestParameters['projectId']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStatusFromJSON(jsonValue));
    }

    /**
     * Fetches project\'s status (version to be shared with collaborators)
     * Fetch Project Status Api
     */
    async fetchProjectStatusApiFetchProjectStatusPost(requestParameters: FetchProjectStatusApiFetchProjectStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectStatus> {
        const response = await this.fetchProjectStatusApiFetchProjectStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api
     */
    async fetchProjectsApiFetchProjectsPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsResponseFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api
     */
    async fetchProjectsApiFetchProjectsPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectsResponse> {
        const response = await this.fetchProjectsApiFetchProjectsPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api V2
     */
    async fetchProjectsApiV2FetchProjectsV2PostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectsOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_projects/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectsOutputFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api V2
     */
    async fetchProjectsApiV2FetchProjectsV2Post(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectsOutput> {
        const response = await this.fetchProjectsApiV2FetchProjectsV2PostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api
     */
    async fetchProjectsApiV3FetchProjectsPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListProjectsOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListProjectsOutputFromJSON(jsonValue));
    }

    /**
     * Fetches user\'s active and closed projects.
     * Fetch Projects Api
     */
    async fetchProjectsApiV3FetchProjectsPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListProjectsOutput> {
        const response = await this.fetchProjectsApiV3FetchProjectsPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryFetchHistoryPostRaw(requestParameters: FetchUserHistoryFetchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcRoutersUserHistory>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserHistoryFetchHistoryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcRoutersUserHistoryFromJSON(jsonValue));
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryFetchHistoryPost(requestParameters: FetchUserHistoryFetchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcRoutersUserHistory> {
        const response = await this.fetchUserHistoryFetchHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryFetchHistoryV2PostRaw(requestParameters: FetchUserHistoryFetchHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserHistoryV2>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserHistoryFetchHistoryV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_history/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserHistoryV2FromJSON(jsonValue));
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryFetchHistoryV2Post(requestParameters: FetchUserHistoryFetchHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserHistoryV2> {
        const response = await this.fetchUserHistoryFetchHistoryV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryV3FetchHistoryPostRaw(requestParameters: FetchUserHistoryV3FetchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SrcModelsSchemasUserHistory>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserHistoryV3FetchHistoryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SrcModelsSchemasUserHistoryFromJSON(jsonValue));
    }

    /**
     * Fetch user search, ideas and protocols history
     * Fetch User History
     */
    async fetchUserHistoryV3FetchHistoryPost(requestParameters: FetchUserHistoryV3FetchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SrcModelsSchemasUserHistory> {
        const response = await this.fetchUserHistoryV3FetchHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas history generated by a given user.
     * Fetch User Idea History
     */
    async fetchUserIdeaHistoryFetchIdeaHistoryPostRaw(requestParameters: FetchUserIdeaHistoryFetchIdeaHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserIdeaHistoryFetchIdeaHistoryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_idea_history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch ideas history generated by a given user.
     * Fetch User Idea History
     */
    async fetchUserIdeaHistoryFetchIdeaHistoryPost(requestParameters: FetchUserIdeaHistoryFetchIdeaHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchUserIdeaHistoryFetchIdeaHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch ideas history generated by a given user.
     * Fetch User Idea History V2
     */
    async fetchUserIdeaHistoryV2FetchIdeaHistoryV2PostRaw(requestParameters: FetchUserIdeaHistoryV2FetchIdeaHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListIdeaHistoryItems>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserIdeaHistoryV2FetchIdeaHistoryV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_idea_history/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListIdeaHistoryItemsFromJSON(jsonValue));
    }

    /**
     * Fetch ideas history generated by a given user.
     * Fetch User Idea History V2
     */
    async fetchUserIdeaHistoryV2FetchIdeaHistoryV2Post(requestParameters: FetchUserIdeaHistoryV2FetchIdeaHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListIdeaHistoryItems> {
        const response = await this.fetchUserIdeaHistoryV2FetchIdeaHistoryV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search
     */
    async fetchUserIndividualSearchFetchIndividualSearchPostRaw(requestParameters: FetchUserIndividualSearchFetchIndividualSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchIndividualSearch'] == null) {
            throw new runtime.RequiredError(
                'fetchIndividualSearch',
                'Required parameter "fetchIndividualSearch" was null or undefined when calling fetchUserIndividualSearchFetchIndividualSearchPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_individual_search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIndividualSearchToJSON(requestParameters['fetchIndividualSearch']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search
     */
    async fetchUserIndividualSearchFetchIndividualSearchPost(requestParameters: FetchUserIndividualSearchFetchIndividualSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchUserIndividualSearchFetchIndividualSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search V2
     */
    async fetchUserIndividualSearchV2FetchIndividualSearchV2PostRaw(requestParameters: FetchUserIndividualSearchV2FetchIndividualSearchV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchHistoryItem>> {
        if (requestParameters['fetchIndividualSearch'] == null) {
            throw new runtime.RequiredError(
                'fetchIndividualSearch',
                'Required parameter "fetchIndividualSearch" was null or undefined when calling fetchUserIndividualSearchV2FetchIndividualSearchV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_individual_search/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIndividualSearchToJSON(requestParameters['fetchIndividualSearch']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHistoryItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search V2
     */
    async fetchUserIndividualSearchV2FetchIndividualSearchV2Post(requestParameters: FetchUserIndividualSearchV2FetchIndividualSearchV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchHistoryItem> {
        const response = await this.fetchUserIndividualSearchV2FetchIndividualSearchV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search
     */
    async fetchUserIndividualSearchV3FetchIndividualSearchPostRaw(requestParameters: FetchUserIndividualSearchV3FetchIndividualSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchHistoryItem>> {
        if (requestParameters['fetchIndividualSearch'] == null) {
            throw new runtime.RequiredError(
                'fetchIndividualSearch',
                'Required parameter "fetchIndividualSearch" was null or undefined when calling fetchUserIndividualSearchV3FetchIndividualSearchPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/fetch_individual_search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchIndividualSearchToJSON(requestParameters['fetchIndividualSearch']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHistoryItemFromJSON(jsonValue));
    }

    /**
     * Fetch information related to a given previous search.
     * Fetch User Individual Search
     */
    async fetchUserIndividualSearchV3FetchIndividualSearchPost(requestParameters: FetchUserIndividualSearchV3FetchIndividualSearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchHistoryItem> {
        const response = await this.fetchUserIndividualSearchV3FetchIndividualSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch user search history
     * Fetch User Search History
     */
    async fetchUserSearchHistoryFetchSearchHistoryPostRaw(requestParameters: FetchUserSearchHistoryFetchSearchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserSearchHistoryFetchSearchHistoryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_search_history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch user search history
     * Fetch User Search History
     */
    async fetchUserSearchHistoryFetchSearchHistoryPost(requestParameters: FetchUserSearchHistoryFetchSearchHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchUserSearchHistoryFetchSearchHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch user search history
     * Fetch User Search History V2
     */
    async fetchUserSearchHistoryV2FetchSearchHistoryV2PostRaw(requestParameters: FetchUserSearchHistoryV2FetchSearchHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSearchHistoryItems>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserSearchHistoryV2FetchSearchHistoryV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_search_history/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSearchHistoryItemsFromJSON(jsonValue));
    }

    /**
     * Fetch user search history
     * Fetch User Search History V2
     */
    async fetchUserSearchHistoryV2FetchSearchHistoryV2Post(requestParameters: FetchUserSearchHistoryV2FetchSearchHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSearchHistoryItems> {
        const response = await this.fetchUserSearchHistoryV2FetchSearchHistoryV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch fulltexts history generated by a given user.
     * Fetch User Text History
     */
    async fetchUserTextHistoryFetchTextHistoryPostRaw(requestParameters: FetchUserTextHistoryFetchTextHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrResultsResponse>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserTextHistoryFetchTextHistoryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_text_history`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrResultsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch fulltexts history generated by a given user.
     * Fetch User Text History
     */
    async fetchUserTextHistoryFetchTextHistoryPost(requestParameters: FetchUserTextHistoryFetchTextHistoryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrResultsResponse> {
        const response = await this.fetchUserTextHistoryFetchTextHistoryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch fulltexts history generated by a given user.
     * Fetch User Text History V2
     */
    async fetchUserTextHistoryV2FetchTextHistoryV2PostRaw(requestParameters: FetchUserTextHistoryV2FetchTextHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTextHistoryItems>> {
        if (requestParameters['fetchHistory'] == null) {
            throw new runtime.RequiredError(
                'fetchHistory',
                'Required parameter "fetchHistory" was null or undefined when calling fetchUserTextHistoryV2FetchTextHistoryV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fetch_text_history/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchHistoryToJSON(requestParameters['fetchHistory']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTextHistoryItemsFromJSON(jsonValue));
    }

    /**
     * Fetch fulltexts history generated by a given user.
     * Fetch User Text History V2
     */
    async fetchUserTextHistoryV2FetchTextHistoryV2Post(requestParameters: FetchUserTextHistoryV2FetchTextHistoryV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTextHistoryItems> {
        const response = await this.fetchUserTextHistoryV2FetchTextHistoryV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Generate Protocol Api
     */
    async generateProtocolApiV3GenerateIntroPostRaw(requestParameters: GenerateProtocolApiV3GenerateIntroPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntroTextResponse>> {
        if (requestParameters['introTextRequestV2'] == null) {
            throw new runtime.RequiredError(
                'introTextRequestV2',
                'Required parameter "introTextRequestV2" was null or undefined when calling generateProtocolApiV3GenerateIntroPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/generate_intro`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntroTextRequestV2ToJSON(requestParameters['introTextRequestV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntroTextResponseFromJSON(jsonValue));
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Generate Protocol Api
     */
    async generateProtocolApiV3GenerateIntroPost(requestParameters: GenerateProtocolApiV3GenerateIntroPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntroTextResponse> {
        const response = await this.generateProtocolApiV3GenerateIntroPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a PubMed search query from a set of PICO values
     * Generate Search Query From Pico
     */
    async generateSearchQueryFromPicoGeneratePicoQueryPostRaw(requestParameters: GenerateSearchQueryFromPicoGeneratePicoQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchQueryGenerationResponse>> {
        if (requestParameters['searchQueryPICO'] == null) {
            throw new runtime.RequiredError(
                'searchQueryPICO',
                'Required parameter "searchQueryPICO" was null or undefined when calling generateSearchQueryFromPicoGeneratePicoQueryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generate_pico_query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryPICOToJSON(requestParameters['searchQueryPICO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchQueryGenerationResponseFromJSON(jsonValue));
    }

    /**
     * Generate a PubMed search query from a set of PICO values
     * Generate Search Query From Pico
     */
    async generateSearchQueryFromPicoGeneratePicoQueryPost(requestParameters: GenerateSearchQueryFromPicoGeneratePicoQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchQueryGenerationResponse> {
        const response = await this.generateSearchQueryFromPicoGeneratePicoQueryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a PubMed search query from a set of PICO values
     * Generate Search Query From Pico
     */
    async generateSearchQueryFromPicoV3GeneratePicoQueryPostRaw(requestParameters: GenerateSearchQueryFromPicoV3GeneratePicoQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchQueryGenerationResponse>> {
        if (requestParameters['searchQueryPICO'] == null) {
            throw new runtime.RequiredError(
                'searchQueryPICO',
                'Required parameter "searchQueryPICO" was null or undefined when calling generateSearchQueryFromPicoV3GeneratePicoQueryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/generate_pico_query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryPICOToJSON(requestParameters['searchQueryPICO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchQueryGenerationResponseFromJSON(jsonValue));
    }

    /**
     * Generate a PubMed search query from a set of PICO values
     * Generate Search Query From Pico
     */
    async generateSearchQueryFromPicoV3GeneratePicoQueryPost(requestParameters: GenerateSearchQueryFromPicoV3GeneratePicoQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchQueryGenerationResponse> {
        const response = await this.generateSearchQueryFromPicoV3GeneratePicoQueryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a PubMed search query from a free text user query
     * Generate Search Query From String
     */
    async generateSearchQueryFromStringGenerateAiQueryPostRaw(requestParameters: GenerateSearchQueryFromStringGenerateAiQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchQueryGenerationResponse>> {
        if (requestParameters['searchQueryAI'] == null) {
            throw new runtime.RequiredError(
                'searchQueryAI',
                'Required parameter "searchQueryAI" was null or undefined when calling generateSearchQueryFromStringGenerateAiQueryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generate_ai_query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryAIToJSON(requestParameters['searchQueryAI']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchQueryGenerationResponseFromJSON(jsonValue));
    }

    /**
     * Generate a PubMed search query from a free text user query
     * Generate Search Query From String
     */
    async generateSearchQueryFromStringGenerateAiQueryPost(requestParameters: GenerateSearchQueryFromStringGenerateAiQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchQueryGenerationResponse> {
        const response = await this.generateSearchQueryFromStringGenerateAiQueryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a PubMed search query from a free text user query
     * Generate Search Query From String
     */
    async generateSearchQueryFromStringV3GenerateAiQueryPostRaw(requestParameters: GenerateSearchQueryFromStringV3GenerateAiQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchQueryGenerationResponse>> {
        if (requestParameters['searchQueryAI'] == null) {
            throw new runtime.RequiredError(
                'searchQueryAI',
                'Required parameter "searchQueryAI" was null or undefined when calling generateSearchQueryFromStringV3GenerateAiQueryPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/generate_ai_query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryAIToJSON(requestParameters['searchQueryAI']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchQueryGenerationResponseFromJSON(jsonValue));
    }

    /**
     * Generate a PubMed search query from a free text user query
     * Generate Search Query From String
     */
    async generateSearchQueryFromStringV3GenerateAiQueryPost(requestParameters: GenerateSearchQueryFromStringV3GenerateAiQueryPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchQueryGenerationResponse> {
        const response = await this.generateSearchQueryFromStringV3GenerateAiQueryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Get Introduction Text
     */
    async getIntroductionTextGenerateIntroPostRaw(requestParameters: GetIntroductionTextGenerateIntroPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntroTextResponseOld>> {
        if (requestParameters['introTextRequest'] == null) {
            throw new runtime.RequiredError(
                'introTextRequest',
                'Required parameter "introTextRequest" was null or undefined when calling getIntroductionTextGenerateIntroPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generate_intro`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntroTextRequestToJSON(requestParameters['introTextRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntroTextResponseOldFromJSON(jsonValue));
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Get Introduction Text
     */
    async getIntroductionTextGenerateIntroPost(requestParameters: GetIntroductionTextGenerateIntroPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntroTextResponseOld> {
        const response = await this.getIntroductionTextGenerateIntroPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Get Introduction Text V2
     */
    async getIntroductionTextV2GenerateIntroV2PostRaw(requestParameters: GetIntroductionTextV2GenerateIntroV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IntroTextResponse>> {
        if (requestParameters['introTextRequestV2'] == null) {
            throw new runtime.RequiredError(
                'introTextRequestV2',
                'Required parameter "introTextRequestV2" was null or undefined when calling getIntroductionTextV2GenerateIntroV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generate_intro/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntroTextRequestV2ToJSON(requestParameters['introTextRequestV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntroTextResponseFromJSON(jsonValue));
    }

    /**
     * Generates a Systematic Review Protocol draft.
     * Get Introduction Text V2
     */
    async getIntroductionTextV2GenerateIntroV2Post(requestParameters: GetIntroductionTextV2GenerateIntroV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IntroTextResponse> {
        const response = await this.getIntroductionTextV2GenerateIntroV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions
     */
    async getResearchQuestionsIdeasGeneratorPostRaw(requestParameters: GetResearchQuestionsIdeasGeneratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionsResponse>> {
        if (requestParameters['questionsRequestOld'] == null) {
            throw new runtime.RequiredError(
                'questionsRequestOld',
                'Required parameter "questionsRequestOld" was null or undefined when calling getResearchQuestionsIdeasGeneratorPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ideas_generator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionsRequestOldToJSON(requestParameters['questionsRequestOld']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionsResponseFromJSON(jsonValue));
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions
     */
    async getResearchQuestionsIdeasGeneratorPost(requestParameters: GetResearchQuestionsIdeasGeneratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionsResponse> {
        const response = await this.getResearchQuestionsIdeasGeneratorPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions V2
     */
    async getResearchQuestionsV2IdeasGeneratorV2PostRaw(requestParameters: GetResearchQuestionsV2IdeasGeneratorV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuestionsResponseV2>> {
        if (requestParameters['questionsRequest'] == null) {
            throw new runtime.RequiredError(
                'questionsRequest',
                'Required parameter "questionsRequest" was null or undefined when calling getResearchQuestionsV2IdeasGeneratorV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ideas_generator/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionsRequestToJSON(requestParameters['questionsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionsResponseV2FromJSON(jsonValue));
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions V2
     */
    async getResearchQuestionsV2IdeasGeneratorV2Post(requestParameters: GetResearchQuestionsV2IdeasGeneratorV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuestionsResponseV2> {
        const response = await this.getResearchQuestionsV2IdeasGeneratorV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions
     */
    async getResearchQuestionsV3IdeasGeneratorPostRaw(requestParameters: GetResearchQuestionsV3IdeasGeneratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdeasSetResponse>> {
        if (requestParameters['questionsRequest'] == null) {
            throw new runtime.RequiredError(
                'questionsRequest',
                'Required parameter "questionsRequest" was null or undefined when calling getResearchQuestionsV3IdeasGeneratorPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/ideas_generator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionsRequestToJSON(requestParameters['questionsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdeasSetResponseFromJSON(jsonValue));
    }

    /**
     * Generate a set of ideas, based on a given research theme and a set of literature results.
     * Get Research Questions
     */
    async getResearchQuestionsV3IdeasGeneratorPost(requestParameters: GetResearchQuestionsV3IdeasGeneratorPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdeasSetResponse> {
        const response = await this.getResearchQuestionsV3IdeasGeneratorPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via Google OAuth2.
     * Google Auth
     */
    async googleAuthGoogleAuthorisePostRaw(requestParameters: GoogleAuthGoogleAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling googleAuthGoogleAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/google/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via Google OAuth2.
     * Google Auth
     */
    async googleAuthGoogleAuthorisePost(requestParameters: GoogleAuthGoogleAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.googleAuthGoogleAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via Google OAuth2.
     * Google Auth
     */
    async googleAuthV3GoogleAuthorisePostRaw(requestParameters: GoogleAuthV3GoogleAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling googleAuthV3GoogleAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/google/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via Google OAuth2.
     * Google Auth
     */
    async googleAuthV3GoogleAuthorisePost(requestParameters: GoogleAuthV3GoogleAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.googleAuthV3GoogleAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via LinkedIn OAuth2.
     * Linkedin Auth
     */
    async linkedinAuthLinkedinAuthorisePostRaw(requestParameters: LinkedinAuthLinkedinAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling linkedinAuthLinkedinAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/linkedin/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via LinkedIn OAuth2.
     * Linkedin Auth
     */
    async linkedinAuthLinkedinAuthorisePost(requestParameters: LinkedinAuthLinkedinAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.linkedinAuthLinkedinAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via LinkedIn OAuth2.
     * Linkedin Auth
     */
    async linkedinAuthV3LinkedinAuthorisePostRaw(requestParameters: LinkedinAuthV3LinkedinAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling linkedinAuthV3LinkedinAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/linkedin/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via LinkedIn OAuth2.
     * Linkedin Auth
     */
    async linkedinAuthV3LinkedinAuthorisePost(requestParameters: LinkedinAuthV3LinkedinAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.linkedinAuthV3LinkedinAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via Microsoft OAuth2.
     * Microsoft Auth
     */
    async microsoftAuthMicrosoftAuthorisePostRaw(requestParameters: MicrosoftAuthMicrosoftAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling microsoftAuthMicrosoftAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/microsoft/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via Microsoft OAuth2.
     * Microsoft Auth
     */
    async microsoftAuthMicrosoftAuthorisePost(requestParameters: MicrosoftAuthMicrosoftAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.microsoftAuthMicrosoftAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via Microsoft OAuth2.
     * Microsoft Auth
     */
    async microsoftAuthV3MicrosoftAuthorisePostRaw(requestParameters: MicrosoftAuthV3MicrosoftAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['authorisation'] == null) {
            throw new runtime.RequiredError(
                'authorisation',
                'Required parameter "authorisation" was null or undefined when calling microsoftAuthV3MicrosoftAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/microsoft/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorisationToJSON(requestParameters['authorisation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via Microsoft OAuth2.
     * Microsoft Auth
     */
    async microsoftAuthV3MicrosoftAuthorisePost(requestParameters: MicrosoftAuthV3MicrosoftAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.microsoftAuthV3MicrosoftAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get number of articles and reviews found for a given search query.
     * Preview Search Pubmed
     */
    async previewSearchPubmedSearchPreviewPostRaw(requestParameters: PreviewSearchPubmedSearchPreviewPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewSearchResponse>> {
        if (requestParameters['previewSearch'] == null) {
            throw new runtime.RequiredError(
                'previewSearch',
                'Required parameter "previewSearch" was null or undefined when calling previewSearchPubmedSearchPreviewPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/search_preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewSearchToJSON(requestParameters['previewSearch']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewSearchResponseFromJSON(jsonValue));
    }

    /**
     * Get number of articles and reviews found for a given search query.
     * Preview Search Pubmed
     */
    async previewSearchPubmedSearchPreviewPost(requestParameters: PreviewSearchPubmedSearchPreviewPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewSearchResponse> {
        const response = await this.previewSearchPubmedSearchPreviewPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get number of articles and reviews found for a given search query.
     * Preview Search Pubmed
     */
    async previewSearchPubmedV3SearchPreviewPostRaw(requestParameters: PreviewSearchPubmedV3SearchPreviewPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewSearchResponse>> {
        if (requestParameters['previewSearch'] == null) {
            throw new runtime.RequiredError(
                'previewSearch',
                'Required parameter "previewSearch" was null or undefined when calling previewSearchPubmedV3SearchPreviewPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/search_preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewSearchToJSON(requestParameters['previewSearch']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewSearchResponseFromJSON(jsonValue));
    }

    /**
     * Get number of articles and reviews found for a given search query.
     * Preview Search Pubmed
     */
    async previewSearchPubmedV3SearchPreviewPost(requestParameters: PreviewSearchPubmedV3SearchPreviewPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewSearchResponse> {
        const response = await this.previewSearchPubmedV3SearchPreviewPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process new protocol purchase
     * Purchase Protocol
     */
    async purchaseProtocolV3PurchaseProtocolPostRaw(requestParameters: PurchaseProtocolV3PurchaseProtocolPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['newPayment'] == null) {
            throw new runtime.RequiredError(
                'newPayment',
                'Required parameter "newPayment" was null or undefined when calling purchaseProtocolV3PurchaseProtocolPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/purchase_protocol`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentToJSON(requestParameters['newPayment']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Process new protocol purchase
     * Purchase Protocol
     */
    async purchaseProtocolV3PurchaseProtocolPost(requestParameters: PurchaseProtocolV3PurchaseProtocolPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.purchaseProtocolV3PurchaseProtocolPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh access token
     * Refresh Access Token
     */
    async refreshAccessTokenTokenRefreshPostRaw(requestParameters: RefreshAccessTokenTokenRefreshPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
        if (requestParameters['refreshToken'] == null) {
            throw new runtime.RequiredError(
                'refreshToken',
                'Required parameter "refreshToken" was null or undefined when calling refreshAccessTokenTokenRefreshPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['refreshToken'] != null) {
            queryParameters['refresh_token'] = requestParameters['refreshToken'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/token/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
    }

    /**
     * Refresh access token
     * Refresh Access Token
     */
    async refreshAccessTokenTokenRefreshPost(requestParameters: RefreshAccessTokenTokenRefreshPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshTokenResponse> {
        const response = await this.refreshAccessTokenTokenRefreshPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh access token
     * Refresh Access Token
     */
    async refreshAccessTokenV3TokenRefreshPostRaw(requestParameters: RefreshAccessTokenV3TokenRefreshPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshTokenResponse>> {
        if (requestParameters['refreshToken'] == null) {
            throw new runtime.RequiredError(
                'refreshToken',
                'Required parameter "refreshToken" was null or undefined when calling refreshAccessTokenV3TokenRefreshPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['refreshToken'] != null) {
            queryParameters['refresh_token'] = requestParameters['refreshToken'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v3/token/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseFromJSON(jsonValue));
    }

    /**
     * Refresh access token
     * Refresh Access Token
     */
    async refreshAccessTokenV3TokenRefreshPost(requestParameters: RefreshAccessTokenV3TokenRefreshPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshTokenResponse> {
        const response = await this.refreshAccessTokenV3TokenRefreshPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileCreateProfilePostRaw(requestParameters: RegisterUserProfileCreateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfile'] == null) {
            throw new runtime.RequiredError(
                'userProfile',
                'Required parameter "userProfile" was null or undefined when calling registerUserProfileCreateProfilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileToJSON(requestParameters['userProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileCreateProfilePost(requestParameters: RegisterUserProfileCreateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.registerUserProfileCreateProfilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileCreateProfileV2PostRaw(requestParameters: RegisterUserProfileCreateProfileV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfileV2'] == null) {
            throw new runtime.RequiredError(
                'userProfileV2',
                'Required parameter "userProfileV2" was null or undefined when calling registerUserProfileCreateProfileV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create_profile/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileV2ToJSON(requestParameters['userProfileV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileCreateProfileV2Post(requestParameters: RegisterUserProfileCreateProfileV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.registerUserProfileCreateProfileV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileV3CreateProfilePostRaw(requestParameters: RegisterUserProfileV3CreateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfileV2'] == null) {
            throw new runtime.RequiredError(
                'userProfileV2',
                'Required parameter "userProfileV2" was null or undefined when calling registerUserProfileV3CreateProfilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/create_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileV2ToJSON(requestParameters['userProfileV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Create user profile with the provided information.
     * Register User Profile
     */
    async registerUserProfileV3CreateProfilePost(requestParameters: RegisterUserProfileV3CreateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.registerUserProfileV3CreateProfilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set project status to be shared with collaborators
     * Set Project Status Api
     */
    async setProjectStatusApiSetProjectStatusPostRaw(requestParameters: SetProjectStatusApiSetProjectStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['setProjectStatus'] == null) {
            throw new runtime.RequiredError(
                'setProjectStatus',
                'Required parameter "setProjectStatus" was null or undefined when calling setProjectStatusApiSetProjectStatusPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/set_project_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetProjectStatusToJSON(requestParameters['setProjectStatus']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Set project status to be shared with collaborators
     * Set Project Status Api
     */
    async setProjectStatusApiSetProjectStatusPost(requestParameters: SetProjectStatusApiSetProjectStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.setProjectStatusApiSetProjectStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit feedback comment.
     * Submit Feedback
     */
    async submitFeedbackSubmitFeedbackPostRaw(requestParameters: SubmitFeedbackSubmitFeedbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['feedbackForm'] == null) {
            throw new runtime.RequiredError(
                'feedbackForm',
                'Required parameter "feedbackForm" was null or undefined when calling submitFeedbackSubmitFeedbackPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/submit_feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackFormToJSON(requestParameters['feedbackForm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Submit feedback comment.
     * Submit Feedback
     */
    async submitFeedbackSubmitFeedbackPost(requestParameters: SubmitFeedbackSubmitFeedbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.submitFeedbackSubmitFeedbackPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit feedback comment.
     * Submit Feedback
     */
    async submitFeedbackV3SubmitFeedbackPostRaw(requestParameters: SubmitFeedbackV3SubmitFeedbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['feedbackFormV2'] == null) {
            throw new runtime.RequiredError(
                'feedbackFormV2',
                'Required parameter "feedbackFormV2" was null or undefined when calling submitFeedbackV3SubmitFeedbackPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/submit_feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackFormV2ToJSON(requestParameters['feedbackFormV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Submit feedback comment.
     * Submit Feedback
     */
    async submitFeedbackV3SubmitFeedbackPost(requestParameters: SubmitFeedbackV3SubmitFeedbackPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.submitFeedbackV3SubmitFeedbackPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update project
     * Update Project Api
     */
    async updateProjectApiUpdateProjectPostRaw(requestParameters: UpdateProjectApiUpdateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectUpdate'] == null) {
            throw new runtime.RequiredError(
                'projectUpdate',
                'Required parameter "projectUpdate" was null or undefined when calling updateProjectApiUpdateProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateToJSON(requestParameters['projectUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Update project
     * Update Project Api
     */
    async updateProjectApiUpdateProjectPost(requestParameters: UpdateProjectApiUpdateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.updateProjectApiUpdateProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update project
     * Update Project Api
     */
    async updateProjectApiV3UpdateProjectPostRaw(requestParameters: UpdateProjectApiV3UpdateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['projectUpdate'] == null) {
            throw new runtime.RequiredError(
                'projectUpdate',
                'Required parameter "projectUpdate" was null or undefined when calling updateProjectApiV3UpdateProjectPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/update_project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateToJSON(requestParameters['projectUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Update project
     * Update Project Api
     */
    async updateProjectApiV3UpdateProjectPost(requestParameters: UpdateProjectApiV3UpdateProjectPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.updateProjectApiV3UpdateProjectPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update user\'s profile.
     * Update User Prof
     */
    async updateUserProfV3UpdateProfilePostRaw(requestParameters: UpdateUserProfV3UpdateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfileV2'] == null) {
            throw new runtime.RequiredError(
                'userProfileV2',
                'Required parameter "userProfileV2" was null or undefined when calling updateUserProfV3UpdateProfilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v3/update_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileV2ToJSON(requestParameters['userProfileV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Update user\'s profile.
     * Update User Prof
     */
    async updateUserProfV3UpdateProfilePost(requestParameters: UpdateUserProfV3UpdateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.updateUserProfV3UpdateProfilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update user\'s profile.
     * Update User Profile
     */
    async updateUserProfileUpdateProfilePostRaw(requestParameters: UpdateUserProfileUpdateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfile'] == null) {
            throw new runtime.RequiredError(
                'userProfile',
                'Required parameter "userProfile" was null or undefined when calling updateUserProfileUpdateProfilePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileToJSON(requestParameters['userProfile']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Update user\'s profile.
     * Update User Profile
     */
    async updateUserProfileUpdateProfilePost(requestParameters: UpdateUserProfileUpdateProfilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.updateUserProfileUpdateProfilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update user\'s profile.
     * Update User Profile
     */
    async updateUserProfileUpdateProfileV2PostRaw(requestParameters: UpdateUserProfileUpdateProfileV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinaryResultsResponse>> {
        if (requestParameters['userProfileV2'] == null) {
            throw new runtime.RequiredError(
                'userProfileV2',
                'Required parameter "userProfileV2" was null or undefined when calling updateUserProfileUpdateProfileV2Post().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update_profile/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileV2ToJSON(requestParameters['userProfileV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinaryResultsResponseFromJSON(jsonValue));
    }

    /**
     * Update user\'s profile.
     * Update User Profile
     */
    async updateUserProfileUpdateProfileV2Post(requestParameters: UpdateUserProfileUpdateProfileV2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinaryResultsResponse> {
        const response = await this.updateUserProfileUpdateProfileV2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authorises user via email and password.
     * Email Password Login
     */
    async loginV3LoginAuthorisePostRaw(requestParameters: LoginV3LoginAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorisationResponse>> {
        if (requestParameters['login'] == null) {
            throw new runtime.RequiredError(
                'login',
                'Required parameter "login" was null or undefined when calling loginV3LoginAuthorisePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/login/authorise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['login'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorisationResponseFromJSON(jsonValue));
    }

    /**
     * Authorises user via email and password.
     * Email Password Login
     */
    async loginV3LoginAuthorisePost(requestParameters: LoginV3LoginAuthorisePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorisationResponse> {
        const response = await this.loginV3LoginAuthorisePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request password reset
     * Password Reset
     */
    async passwordResetV3PasswordResetPostRaw(requestParameters: PasswordResetV3PasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling passwordResetV3PasswordResetPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/password_reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: { email: requestParameters['email'] },
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Request password reset
     * Password Reset
     */
    async passwordResetV3PasswordResetPost(requestParameters: PasswordResetV3PasswordResetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.passwordResetV3PasswordResetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    public async setPasswordV3SetPasswordPostRaw(requestParameters: SetPasswordV3SetPasswordPostRequest): Promise<Response> {
        if (requestParameters.verification_token === null || requestParameters.verification_token === undefined) {
            throw new Error('Required parameter verification_token was null or undefined when calling setPasswordV3SetPasswordPost.');
        }
        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling setPasswordV3SetPasswordPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/set_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters,
        });

        return response;
    }

    public async setPasswordV3SetPasswordPost(requestParameters: SetPasswordV3SetPasswordPostRequest): Promise<void> {
        await this.setPasswordV3SetPasswordPostRaw(requestParameters);
    }

}
