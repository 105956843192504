import { LoadingSpinner } from '../atoms/LoadingSpinner'

export const CustomButton = ({
    text,
    onClick,
    isDisabled = false,
    backgroundColor = 'yellow',
    textColor = 'blue',
    loading = false,
    icon,
}: {
    text: string
    onClick: () => void
    isDisabled?: boolean
    backgroundColor?: 'yellow' | 'blue' | 'lightGray' | 'red'
    textColor?: string
    loading?: boolean
    icon?: React.ReactNode
}) => {
    return (
        <button
            disabled={isDisabled || loading}
            onClick={onClick}
            className={`flex items-center justify-center rounded-md px-4 py-1.5 min-w-[90px] min-h-[39px] font-bold text-lg transition-transform transform hover:scale-110 
        ${loading || isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} 
        ${
            isDisabled
                ? 'bg-veryLightGray text-darkGray shadow-grayCustomShadow'
                : `bg-${backgroundColor} text-${textColor} ${
                      backgroundColor === 'yellow'
                          ? 'shadow-yellowCustomShadow'
                          : backgroundColor === 'blue'
                            ? 'shadow-blueCustomShadow'
                            : backgroundColor === 'lightGray'
                              ? 'shadow-grayCustomShadow'
                              : 'shadow-redCustomShadow'
                  }`
        }
    `}
        >
            {loading ? (
                <LoadingSpinner color="gray" />
            ) : (
                <div className={'flex flex-row gap-2'}>
                    <span>{text}</span>
                    {icon}
                </div>
            )}
        </button>
    )
}
