import React from 'react'
import Card from '../../components/atoms/Card'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '../../components/buttons/IconButton'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg'
import {
    SrcModelsSchemasUserHistoryItem,
    SrcRoutersUserHistoryItem,
} from '../../api/openapi'
import HoverOnTables from '../../components/atoms/HoverOnTables'

export const HistoryTable = ({
    historyItems,
    onClose,
}: {
    historyItems: SrcModelsSchemasUserHistoryItem[]
    onClose: () => void
}) => {
    const { projectId } = useParams<{ projectId: string }>()
    const navigate = useNavigate()

    const onSearchClick = (searchId: string) => {
        navigate(`/wizard/${projectId}/literature-search/${searchId}`)
        onClose()
    }

    const onResultExtractionClick = (searchId: string) => {
        navigate(`/wizard/${projectId}/results-extraction/${searchId}`)
    }

    const onIdeaSetClick = (searchId: string, ideasSetId: string) => {
        navigate(
            `/wizard/${projectId}/ideas-generation/${searchId}/${ideasSetId}`
        )
    }

    const onProtocolClick = (
        ideasSetId: string,
        ideaId: string,
        protocolId: string
    ) => {
        navigate(
            `/wizard/${projectId}/protocol-generation/${ideasSetId}/${ideaId}/${protocolId}`
        )
    }

    const rows: GridRowsProp = historyItems.map((historyItem, index) => ({
        id: index, // DataGrid requires a unique `id` for each row
        timestamp: historyItem.timestamp,
        search: historyItem.search,
        query: historyItem.query,
        results: `${historyItem.numArticles || 0} articles, ${
            historyItem.numReviews || 0
        } reviews`,
        ideas: historyItem.numIdeas ? `${historyItem.numIdeas} ideas` : '',
        protocol: historyItem.protocolTitle,
    }))

    const columns: GridColDef[] = [
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1.5,
            renderCell: (params) => (
                <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                    {new Date(params.value)
                        .toISOString()
                        .slice(0, 16)
                        .replace('T', '  ')}
                </span>
            ),
        },
        {
            field: 'search',
            headerName: 'Search',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span
                        className="cursor-pointer text-veryDarkGray dark:text-veryLightGray hover:underline"
                        onClick={() =>
                            onSearchClick(historyItems[params.row.id].searchId!)
                        }
                    >
                        {params.value.length > 100
                            ? `${params.value.slice(0, 100)}...`
                            : params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'query',
            headerName: 'Query',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span
                        className="cursor-pointer text-veryDarkGray dark:text-veryLightGray hover:underline"
                        onClick={() =>
                            onSearchClick(historyItems[params.row.id].searchId!)
                        }
                    >
                        {params.value.length > 100
                            ? `${params.value.slice(0, 100)}...`
                            : params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'results',
            headerName: 'Search Results',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span
                        className="cursor-pointer text-veryDarkGray dark:text-veryLightGray hover:underline"
                        onClick={() =>
                            onResultExtractionClick(
                                historyItems[params.row.id].searchId!
                            )
                        }
                    >
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'ideas',
            headerName: 'Ideas',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span
                        className="cursor-pointer text-veryDarkGray dark:text-veryLightGray hover:underline"
                        onClick={() =>
                            onIdeaSetClick(
                                historyItems[params.row.id].searchId!,
                                historyItems[params.row.id].ideasSetId!
                            )
                        }
                    >
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'protocol',
            headerName: 'Protocol',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span
                        className="cursor-pointer text-veryDarkGray dark:text-veryLightGray hover:underline"
                        onClick={() =>
                            onProtocolClick(
                                historyItems[params.row.id].ideasSetId!,
                                historyItems[params.row.id].ideaId!,
                                historyItems[params.row.id].protocolId!
                            )
                        }
                    >
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
    ]

    return (
        <Card>
            <div className="flex flex-col w-full md:w-[700px] lg:w-[1000px] h-full text-veryDarkGray dark:text-veryLightGray">
                <div className="flex mr-auto mb-4">
                    <IconButton onClick={onClose} icon={<BackIcon />} />
                </div>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        hideFooter={true}
                        rows={rows}
                        columns={columns}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: '#EBF0F3',
                            backgroundColor: '#EBF0F3',
                        }}
                        className="dark:bg-darkBlue dark:border-darkBlue"
                    />
                </div>
            </div>
        </Card>
    )
}
