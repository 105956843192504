import React, { useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import AppRouter from './router/AppRouter'
import Footer from './components/molecules/Footer'
import { ToastContainer } from 'react-toastify'

const App = () => {
    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        >
            <div className="flex flex-col min-h-screen bg-lightGray dark:bg-darkBlue">
                <AppRouter />
                {/* Footer is fixed at the bottom */}
                <div className="bottom-0 w-full">
                    <Footer />
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </GoogleOAuthProvider>
    )
}

export default App
