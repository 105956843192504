/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdeaSetsRunning } from './IdeaSetsRunning';
import {
    IdeaSetsRunningFromJSON,
    IdeaSetsRunningFromJSONTyped,
    IdeaSetsRunningToJSON,
} from './IdeaSetsRunning';
import type { ProtocolRunning } from './ProtocolRunning';
import {
    ProtocolRunningFromJSON,
    ProtocolRunningFromJSONTyped,
    ProtocolRunningToJSON,
} from './ProtocolRunning';
import type { ProjectOutput } from './ProjectOutput';
import {
    ProjectOutputFromJSON,
    ProjectOutputFromJSONTyped,
    ProjectOutputToJSON,
} from './ProjectOutput';
import type { SrcModelsSchemasUserHistory } from './SrcModelsSchemasUserHistory';
import {
    SrcModelsSchemasUserHistoryFromJSON,
    SrcModelsSchemasUserHistoryFromJSONTyped,
    SrcModelsSchemasUserHistoryToJSON,
} from './SrcModelsSchemasUserHistory';

/**
 * 
 * @export
 * @interface SrcModelsSchemasProjectData
 */
export interface SrcModelsSchemasProjectData {
    /**
     * Project settings information
     * @type {ProjectOutput}
     * @memberof SrcModelsSchemasProjectData
     */
    information?: ProjectOutput;
    /**
     * Project history
     * @type {SrcModelsSchemasUserHistory}
     * @memberof SrcModelsSchemasProjectData
     */
    history?: SrcModelsSchemasUserHistory;
    /**
     * List of ideas set that are being generated, associated to this project
     * @type {Array<IdeaSetsRunning>}
     * @memberof SrcModelsSchemasProjectData
     */
    ideasSetRunning?: Array<IdeaSetsRunning>;
    /**
     * List of protocols that are being generated, associated to this project
     * @type {Array<ProtocolRunning>}
     * @memberof SrcModelsSchemasProjectData
     */
    protocolsRunning?: Array<ProtocolRunning>;
}

/**
 * Check if a given object implements the SrcModelsSchemasProjectData interface.
 */
export function instanceOfSrcModelsSchemasProjectData(value: object): value is SrcModelsSchemasProjectData {
    return true;
}

export function SrcModelsSchemasProjectDataFromJSON(json: any): SrcModelsSchemasProjectData {
    return SrcModelsSchemasProjectDataFromJSONTyped(json, false);
}

export function SrcModelsSchemasProjectDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasProjectData {
    if (json == null) {
        return json;
    }
    return {
        
        'information': json['information'] == null ? undefined : ProjectOutputFromJSON(json['information']),
        'history': json['history'] == null ? undefined : SrcModelsSchemasUserHistoryFromJSON(json['history']),
        'ideasSetRunning': json['ideas_set_running'] == null ? undefined : ((json['ideas_set_running'] as Array<any>).map(IdeaSetsRunningFromJSON)),
        'protocolsRunning': json['protocols_running'] == null ? undefined : ((json['protocols_running'] as Array<any>).map(ProtocolRunningFromJSON)),
    };
}

export function SrcModelsSchemasProjectDataToJSON(value?: SrcModelsSchemasProjectData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'information': ProjectOutputToJSON(value['information']),
        'history': SrcModelsSchemasUserHistoryToJSON(value['history']),
        'ideas_set_running': value['ideasSetRunning'] == null ? undefined : ((value['ideasSetRunning'] as Array<any>).map(IdeaSetsRunningToJSON)),
        'protocols_running': value['protocolsRunning'] == null ? undefined : ((value['protocolsRunning'] as Array<any>).map(ProtocolRunningToJSON)),
    };
}

