import apiClient, { loginApiClient } from '../api/apiClient'
import type {
    AuthorisationResponse,
    RefreshTokenResponse,
} from '../api/openapi'

export const googleAuthorization = async (
    code: string
): Promise<AuthorisationResponse> => {
    try {
        if (!code) {
            throw new Error('Authorization code is missing')
        }

        const response = await apiClient.googleAuthV3GoogleAuthorisePost({
            authorisation: {
                code,
                redirectUri: 'postmessage',
            },
        })

        if (!response) {
            throw new Error('No response received from the server')
        }

        if (response.statusCode !== 200) {
            throw new Error(
                `Authentication failed with status code: ${response.statusCode}`
            )
        }

        if (!response.result) {
            throw new Error('Authentication was unsuccessful')
        }

        return response
    } catch (error: any) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorMessage =
                error.response.data?.message || 'Authentication failed'
            throw new Error(`Server error: ${errorMessage}`)
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error(
                'No response received from the server. Please check your internet connection.'
            )
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(`Authentication error: ${error.message}`)
        }
    }
}

export const microsoftAuthorization = (
    code: string
): Promise<AuthorisationResponse> => {
    return apiClient.microsoftAuthV3MicrosoftAuthorisePost({
        authorisation: {
            code,
            redirectUri: 'postmessage',
        },
    })
}

export const linkedInAuthorization = (
    code: string
): Promise<AuthorisationResponse> => {
    return apiClient.linkedinAuthV3LinkedinAuthorisePost({
        authorisation: {
            code,
            redirectUri: 'postmessage',
        },
    })
}

export const refreshToken = (
    refreshToken: string
): Promise<RefreshTokenResponse> => {
    return apiClient.refreshAccessTokenV3TokenRefreshPost({
        refreshToken,
    })
}

export const emailPasswordLogin = async (
    email: string,
    password: string
): Promise<AuthorisationResponse> => {
    try {
        const response = await loginApiClient.loginV3LoginAuthorisePost({
            login: {
                email,
                password,
            },
        })

        // Verificar si la autenticación fue exitosa
        if (!response.result || !response.jwtToken) {
            throw new Error(
                'Authentication failed. Please check your credentials.'
            )
        }

        return response
    } catch (error: any) {
        if (error.response?.status === 401) {
            // Si el servidor devuelve un mensaje de error específico, úsalo
            if (error.response?.data) {
                throw new Error(error.response.data)
            }
            throw new Error('Invalid email or password')
        }
        throw error
    }
}
