import { Configuration, DefaultApi } from './openapi'
import { refreshToken } from '../middleware/authService'
import LocalStorageUtil from '../utils/LocalStorageUtil'

// Custom event for session expired
const SESSION_EXPIRED_EVENT = 'sessionExpired'

// Custom error type for API errors
interface ApiError extends Error {
    response?: {
        status: number
    }
}

// Function to show session expired modal
const showSessionExpiredModal = () => {
    // Dispatch custom event that will be handled by the SessionExpiredModal component
    window.dispatchEvent(new Event(SESSION_EXPIRED_EVENT))
}

// Function to dynamically add the Authorization header to the configuration
const createApiConfig = (skipAuth = false) => {
    const config: any = {
        basePath: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000',
    }

    // Only add Authorization header if not skipping auth (for login endpoints)
    if (!skipAuth) {
        config.headers = {
            Authorization: `Bearer ${LocalStorageUtil.getToken()}`,
        }
    }

    return new Configuration(config)
}

// Function to handle 401 responses globally
const handleUnauthorized = async () => {
    // if user doesn't have a token redirect to login
    if (!LocalStorageUtil.getToken()) {
        showSessionExpiredModal()
    } else if (!LocalStorageUtil.getRefreshToken()) {
        showSessionExpiredModal()
    } else {
        await refreshTokenHandler()
    }
}

const handleForbidden = async () => {
    if (!LocalStorageUtil.getToken()) {
        showSessionExpiredModal()
    } else if (!LocalStorageUtil.getRefreshToken()) {
        showSessionExpiredModal()
    } else if (LocalStorageUtil.getRefreshToken()) {
        await refreshTokenHandler()
    }
}

const refreshTokenHandler = async () => {
    refreshToken(LocalStorageUtil.getRefreshToken()!).then(
        (data) => {
            LocalStorageUtil.setToken(data.jwtToken)
            window.location.reload()
        },
        (error) => {
            if (
                error.response?.status === 401 ||
                error.response?.status === 403
            ) {
                showSessionExpiredModal()
            }
        }
    )
}

const createApiClient = (skipAuth = false): DefaultApi => {
    // Create the actual API client instance
    const api = new DefaultApi(createApiConfig(skipAuth))

    // Proxy the original request to add interceptor behavior
    const proxyHandler = {
        get(target: any, prop: string) {
            if (typeof target[prop] === 'function') {
                return async (...args: any[]) => {
                    try {
                        // Execute the original API method
                        return await target[prop](...args)
                    } catch (error: any) {
                        if (error.response?.status === 401) {
                            await handleUnauthorized() // Handle 401 errors
                        }
                        if (error.response?.status === 403) {
                            await handleForbidden()
                        }
                        throw error // Re-throw the error for further handling
                    }
                }
            }
            return target[prop] // Return other properties as is
        },
    }

    // Return the API client wrapped with the proxy, ensuring it remains typed as `DefaultApi`
    return new Proxy(api, proxyHandler) as DefaultApi
}

// API client instance (typed correctly)
const apiClient = createApiClient()

// Create a separate instance for login endpoints that doesn't include auth headers
const loginApiClient = createApiClient(true)

export { loginApiClient }
export default apiClient
