/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectOutput
 */
export interface ProjectOutput {
    /**
     * Project ID
     * @type {string}
     * @memberof ProjectOutput
     */
    id: string;
    /**
     * Email of the project owner
     * @type {string}
     * @memberof ProjectOutput
     */
    owner: string;
    /**
     * Project title
     * @type {string}
     * @memberof ProjectOutput
     */
    title: string;
    /**
     * Project description
     * @type {string}
     * @memberof ProjectOutput
     */
    description: string;
    /**
     * List of tags associated to the project
     * @type {Array<any>}
     * @memberof ProjectOutput
     */
    tags: Array<any>;
    /**
     * List of emails associated to the collaborators
     * @type {Array<any>}
     * @memberof ProjectOutput
     */
    collaborators: Array<any>;
    /**
     * Project status. Any value among: ['ACTIVE', 'DELETED', 'CLOSED']
     * @type {string}
     * @memberof ProjectOutput
     */
    status: string;
    /**
     * Datetime of last update
     * @type {Date}
     * @memberof ProjectOutput
     */
    updatedAt: Date;
    /**
     * Datetime of creation
     * @type {Date}
     * @memberof ProjectOutput
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ProjectOutput interface.
 */
export function instanceOfProjectOutput(value: object): value is ProjectOutput {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('collaborators' in value) || value['collaborators'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function ProjectOutputFromJSON(json: any): ProjectOutput {
    return ProjectOutputFromJSONTyped(json, false);
}

export function ProjectOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'title': json['title'],
        'description': json['description'],
        'tags': json['tags'],
        'collaborators': json['collaborators'],
        'status': json['status'],
        'updatedAt': (new Date(json['updated_at'])),
        'createdAt': (new Date(json['created_at'])),
    };
}

export function ProjectOutputToJSON(value?: ProjectOutput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'owner': value['owner'],
        'title': value['title'],
        'description': value['description'],
        'tags': value['tags'],
        'collaborators': value['collaborators'],
        'status': value['status'],
        'updated_at': ((value['updatedAt']).toISOString()),
        'created_at': ((value['createdAt']).toISOString()),
    };
}

