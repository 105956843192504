/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Authorisation
 */
export interface Authorisation {
    /**
     * Authorization code provided by OAuth2 server
     * @type {string}
     * @memberof Authorisation
     */
    code: string;
    /**
     * Redirect uri used when calling the authorization endpoint
     * @type {string}
     * @memberof Authorisation
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof Authorisation
     */
    codeVerifier?: string;
}

/**
 * Check if a given object implements the Authorisation interface.
 */
export function instanceOfAuthorisation(value: object): value is Authorisation {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('redirectUri' in value) || value['redirectUri'] === undefined) return false;
    return true;
}

export function AuthorisationFromJSON(json: any): Authorisation {
    return AuthorisationFromJSONTyped(json, false);
}

export function AuthorisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authorisation {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'redirectUri': json['redirect_uri'],
        'codeVerifier': json['code_verifier'] == null ? undefined : json['code_verifier'],
    };
}

export function AuthorisationToJSON(value?: Authorisation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'redirect_uri': value['redirectUri'],
        'code_verifier': value['codeVerifier'],
    };
}

