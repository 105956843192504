import Card from '../atoms/Card'
import { Tooltip } from 'react-tooltip'
import React, { useEffect, useState } from 'react'
import CustomInputField from '../atoms/CustomInputField'
import { CustomButton } from '../buttons/CustomButton'
import CustomCheckbox from '../atoms/CustomCheckbox'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import {
    createUserProfile,
    deleteUserProfile,
    getUserProfile,
    updateUserProfile,
} from '../../middleware/profileService'
import { useFetch } from '../../hooks/useFetch'
import Modal from '../atoms/Modal'
import { IoMdInformationCircle } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import ToggleButtonGroup from '../atoms/ToggleButtonGroup'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import TextButton from '../buttons/TextButton'
import useDarkMode from '../../hooks/useDarkMode'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../atoms/LoadingSpinner'
import { SubscriptionModal } from './SubscriptionModal'

interface UserProfileCardProps {
    onClose: (isFilled: boolean) => void // Assuming you went with Option 2 from the previous response
    isModalOpen: boolean
    disableClose: boolean
    onProfileSaved: () => void
}

export const UserProfileCard = ({
    onClose,
    isModalOpen,
    disableClose,
    onProfileSaved,
}: UserProfileCardProps) => {
    const navigate = useNavigate()

    const [name, setName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [industry, setIndustry] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [pubMedKey, setPubMedKey] = useState<string>('')
    const [communication, setCommunication] = useState<boolean>(false)

    const [nameError, setNameError] = useState<string | null>(null)
    const [lastNameError, setLastNameError] = useState<string | null>(null)
    const [industryError, setIndustryError] = useState<string | null>(null)
    const [jobTitleError, setJobTitleError] = useState<string | null>(null)
    const [companyError, setCompanyError] = useState<string | null>(null)
    const [countryError, setCountryError] = useState<string | null>(null)

    const { toggleDarkMode } = useDarkMode()
    const { data, loading, error } = useFetch(getUserProfile)
    const { fetchData: updateProfile } = useFetchOnDemand(() =>
        updateUserProfile(
            name,
            lastName,
            '',
            company,
            jobTitle,
            industry,
            country,
            communication,
            false,
            pubMedKey || ''
        )
    )

    // Reset form state when component unmounts
    useEffect(() => {
        return () => {
            setName('')
            setLastName('')
            setIndustry('')
            setJobTitle('')
            setCompany('')
            setCountry('')
            setPubMedKey('')
            setCommunication(false)
        }
    }, []) // Empty dependency array since we only want to run cleanup on unmount

    const { fetchData: createProfile } = useFetchOnDemand(() =>
        createUserProfile(
            name,
            lastName,
            '',
            company,
            jobTitle,
            industry,
            country,
            communication,
            false,
            pubMedKey || ''
        )
    )

    const { fetchData: deleteProfileCall } = useFetchOnDemand(() =>
        deleteUserProfile()
    )

    useEffect(() => {
        if (data) {
            setName(data.firstName)
            setLastName(data.lastName)
            setIndustry(data.industry)
            setJobTitle(data.jobTitle)
            setCompany(data.company)
            setCountry(data.country)
            setPubMedKey(data.pubmedApiKey ?? '')
            setCommunication(data.communications)
        }
    }, [data])

    const [isLoading, setIsLoading] = useState(false)
    const [confirmationInput, setConfirmationInput] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState(true)
    const [isClosing, setIsClosing] = useState(false)
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] =
        useState(false)

    const validateFields = () => {
        let isValid = true
        setNameError(name ? null : 'This field is required')
        setLastNameError(lastName ? null : 'This field is required')
        setIndustryError(industry ? null : 'This field is required')
        setJobTitleError(jobTitle ? null : 'This field is required')
        setCompanyError(company ? null : 'This field is required')
        setCountryError(country ? null : 'This field is required')

        if (
            !name ||
            !lastName ||
            !industry ||
            !jobTitle ||
            !company ||
            !country
        ) {
            isValid = false
        }
        setIsSaveDisabled(!isValid)
        return isValid
    }

    useEffect(() => {
        validateFields()
    }, [name, lastName, industry, jobTitle, company, country])

    const handleSave = () => {
        if (!validateFields()) {
            return
        }
        setIsLoading(true)
        if (!data) {
            createProfile().then(() => {
                setIsLoading(false)
                setIsClosing(true)
                setTimeout(() => {
                    setIsClosing(false)
                    onProfileSaved() // Call the onProfileSaved prop after animation
                    onClose(true) // Indicate profile is filled on save
                }, 300)
            })
        } else {
            updateProfile().then(() => {
                setIsLoading(false)
                setIsClosing(true)
                setTimeout(() => {
                    setIsClosing(false)
                    onProfileSaved() // Call the onProfileSaved prop after animation
                    onClose(true) // Indicate profile is filled on save
                }, 300)
            })
        }
    }

    const handleClose = () => {
        const isProfileCurrentlyFilled = validateFields()
        setIsClosing(true)
        setTimeout(() => {
            setIsClosing(false)
            onClose(isProfileCurrentlyFilled)
        }, 300)
    }

    const [closeProjectModalOpen, setCloseProjectModalOpen] =
        useState<boolean>(false)

    const handleDeleteAccount = () => {
        if (confirmationInput === 'Eliminate my account') {
            setIsLoading(true)
            deleteProfileCall()
                .then(() => {
                    setConfirmationInput('')
                    setCloseProjectModalOpen(false)
                    toast.success('Account eliminated successfully!')
                    // Clear local storage and redirect to login
                    LocalStorageUtil.clearAll()
                    navigate('/login')
                })
                .catch((error) => {
                    toast.error('Failed to delete account. Please try again.')
                    setIsLoading(false)
                })
        } else {
            toast.error('Please type "Eliminate my account" to proceed.')
        }
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={
                disableClose
                    ? () => {}
                    : () => {
                          setIsClosing(true)
                          setTimeout(() => {
                              setIsClosing(false)
                              onClose(validateFields())
                          }, 300)
                      }
            }
            disableAnimation={true}
        >
            <Card isClosing={isClosing}>
                <div
                    className={`flex flex-col gap-3 px-5 pt-4 pb-5 min-w-[600px] w-auto text-veryDarkGray overflow-auto`}
                >
                    <div className={`text-2xl font-bold text-yellow text-left`}>
                        Profile
                    </div>
                    {loading && (
                        <div
                            className={
                                'text-veryDarkGray dark:text-veryLightGray'
                            }
                        >
                            <div className="flex justify-center p-5">
                                <LoadingSpinner color="#2874A6" />
                            </div>
                        </div>
                    )}
                    {!loading && data && (
                        <div className="flex flex-col gap-3">
                            <div
                                className={`grid grid-cols-3 gap-x-5 gap-y-2 pb-4`}
                            >
                                <CustomInputField
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    label="Name *"
                                    errorMessage={nameError}
                                />

                                <CustomInputField
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    label="Last Name *"
                                    errorMessage={lastNameError}
                                />

                                <CustomInputField
                                    value={industry}
                                    onChange={(e) =>
                                        setIndustry(e.target.value)
                                    }
                                    label="Industry *"
                                    errorMessage={industryError}
                                />

                                <CustomInputField
                                    value={jobTitle}
                                    onChange={(e) =>
                                        setJobTitle(e.target.value)
                                    }
                                    label="Position *"
                                    errorMessage={jobTitleError}
                                />

                                <CustomInputField
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    label="Company/Institution *"
                                    errorMessage={companyError}
                                />

                                <CustomInputField
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    label="Country *"
                                    errorMessage={countryError}
                                />
                            </div>

                            <div className="pt-2">
                                <TextButton
                                    onClick={() =>
                                        setIsSubscriptionModalOpen(true)
                                    }
                                    buttonText="Manage"
                                    additionalText="my subscription."
                                    buttonColor="bg-yellow"
                                    additionalTextColor="text-blue"
                                />
                            </div>
                        </div>
                    )}

                    <div className={`text-2xl font-bold text-yellow text-left`}>
                        General
                    </div>
                    {loading && (
                        <div
                            className={
                                'text-veryDarkGray dark:text-veryLightGray'
                            }
                        >
                            <div className="flex justify-center p-5">
                                <LoadingSpinner color="#2874A6" />
                            </div>
                        </div>
                    )}
                    {!loading && (
                        <div className={`flex flex-col gap-2`}>
                            <div className={`grid grid-cols-2 gap-2`}>
                                <div className={`flex flex-col gap-1`}>
                                    <label className="font-medium text-blue">
                                        Preferred appearance
                                    </label>
                                    <ToggleButtonGroup
                                        options={['Light', 'Dark']}
                                        onToggle={(selected) => {
                                            toggleDarkMode()
                                        }}
                                        preSelected={
                                            LocalStorageUtil.isDarkMode()
                                                ? 'Dark'
                                                : 'Light'
                                        }
                                    />
                                </div>
                                {/* Placeholder DIV */}
                                <div className={`flex flex-col gap-2`}></div>
                                <div className={`flex flex-col gap-2`}>
                                    <CustomInputField
                                        value={pubMedKey || ''}
                                        onChange={(e) =>
                                            setPubMedKey(e.target.value)
                                        }
                                        label="PubMed API key"
                                        icon={
                                            <IoMdInformationCircle
                                                data-tooltip-id="info-tooltip"
                                                data-tooltip-html={`We need this information to provide you the best experience as possible. </br> 
                                                It just takes 2 minutes and it is completely free! <a href="https://support.nlm.nih.gov/knowledgebase/article/KA-05317/en-us" target="_blank" style="color: #FFAC27;">Instructions</a>`}
                                                className="cursor-pointer"
                                            />
                                        }
                                    />
                                </div>

                                {/* Tooltip element */}
                                <Tooltip
                                    id="info-tooltip"
                                    place="right"
                                    openOnClick
                                    clickable
                                    style={{
                                        backgroundColor: '#EBF0F3',
                                        color: '#767676',
                                        border: '1px solid #767676',
                                        boxShadow:
                                            '0px 4px 6px rgba(118, 118, 118, 0.6)',
                                    }}
                                />
                                {/* Placeholder DIV */}
                                <div className={`flex flex-col gap-2`}></div>

                                <div className={`flex flex-col gap-1`}>
                                    <label className="font-medium text-blue">
                                        Communications
                                    </label>
                                    <CustomCheckbox
                                        checked={communication}
                                        onChange={(e) => {
                                            setCommunication(e)
                                        }}
                                        label="Allow Erudita to send me promotions."
                                    />
                                </div>
                            </div>
                            {/* Horizontal line */}
                            <div className={`border-b pt-2 border-yellow`} />
                            <div>
                                <TextButton
                                    onClick={() =>
                                        setCloseProjectModalOpen(true)
                                    }
                                    buttonText="Eliminate"
                                    additionalText="my account."
                                />
                            </div>
                        </div>
                    )}
                    <div className={`flex flex-row gap-3 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <CustomButton
                            text={'Save'}
                            onClick={handleSave}
                            loading={isLoading} // Pass loading state to button
                        />
                    </div>
                </div>
            </Card>

            <Modal
                isOpen={closeProjectModalOpen}
                onClose={() => setCloseProjectModalOpen(false)}
            >
                <Card>
                    <div className={'flex flex-col gap-4'}>
                        <div
                            className={
                                'md:max-w-[500px] lg:max-w-[700px] text-veryDarkGray dark:text-veryLightGray'
                            }
                        >
                            To{' '}
                            <span className={'text-red font-bold'}>
                                eliminate
                            </span>{' '}
                            your account, type "Eliminate my account" below.
                        </div>
                        <CustomInputField
                            value={confirmationInput}
                            onChange={(e) =>
                                setConfirmationInput(e.target.value)
                            }
                            placeholder={'Eliminate my account'}
                        />
                        <div className={'flex flex-row gap-4 justify-center'}>
                            <CustomButton
                                backgroundColor={'lightGray'}
                                textColor={'blue'}
                                text={'Cancel'}
                                onClick={() => {
                                    setConfirmationInput('') // Reset confirmation input
                                    setCloseProjectModalOpen(false)
                                }}
                            />
                            <CustomButton
                                textColor={'white'}
                                backgroundColor={'red'}
                                text={'Eliminate account'}
                                onClick={handleDeleteAccount}
                                loading={loading && closeProjectModalOpen}
                            />
                        </div>
                    </div>
                </Card>
            </Modal>

            {data && (
                <SubscriptionModal
                    isOpen={isSubscriptionModalOpen}
                    onClose={() => setIsSubscriptionModalOpen(false)}
                    userProfile={data}
                />
            )}
        </Modal>
    )
}
