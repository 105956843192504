import ArrowButton from '../buttons/ArrowButton'

export const ArrowToggleButton = ({
    value,
    toggle,
    label,
    disabled,
    onClick, // Accept the onClick prop here
}: {
    value: boolean
    toggle: () => void
    label: string
    disabled?: boolean
    onClick?: () => void // This is the type for the onClick prop
}) => {
    // Handle the click event, execute the toggle function and the additional onClick if provided
    const handleClick = () => {
        toggle() // Execute the toggle function
        if (onClick) onClick() // If an onClick prop is provided, execute it as well
    }

    return (
        <div
            className={`flex flex-row gap-2 items-center cursor-pointer ${disabled ? 'opacity-50' : ''}`}
            onClick={handleClick} // Use the new handler for the click event
        >
            <div
                className={`text-blue text-base font-medium ${disabled ? 'cursor-not-allowed' : ''}`}
            >
                {label}
            </div>
            <div className={`${disabled ? 'cursor-not-allowed' : ''}`}>
                <ArrowButton
                    iconSize={4}
                    backgroundColor={'yellow'}
                    direction={value ? 'up' : 'down'}
                    onClick={handleClick} // Also use the handleClick function here for ArrowButton
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
