/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProtocolRunning
 */
export interface ProtocolRunning {
    /**
     * Protocol ID
     * @type {string}
     * @memberof ProtocolRunning
     */
    protocolId: string;
    /**
     * Authors
     * @type {string}
     * @memberof ProtocolRunning
     */
    authors: string;
    /**
     * Institutions
     * @type {string}
     * @memberof ProtocolRunning
     */
    institutions: string;
}

/**
 * Check if a given object implements the ProtocolRunning interface.
 */
export function instanceOfProtocolRunning(value: object): value is ProtocolRunning {
    if (!('protocolId' in value) || value['protocolId'] === undefined) return false;
    if (!('authors' in value) || value['authors'] === undefined) return false;
    if (!('institutions' in value) || value['institutions'] === undefined) return false;
    return true;
}

export function ProtocolRunningFromJSON(json: any): ProtocolRunning {
    return ProtocolRunningFromJSONTyped(json, false);
}

export function ProtocolRunningFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProtocolRunning {
    if (json == null) {
        return json;
    }
    return {
        
        'protocolId': json['protocol_id'],
        'authors': json['authors'],
        'institutions': json['institutions'],
    };
}

export function ProtocolRunningToJSON(value?: ProtocolRunning | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'protocol_id': value['protocolId'],
        'authors': value['authors'],
        'institutions': value['institutions'],
    };
}

