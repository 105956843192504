/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleItem } from './ArticleItem';
import {
    ArticleItemFromJSON,
    ArticleItemFromJSONTyped,
    ArticleItemToJSON,
} from './ArticleItem';

/**
 * 
 * @export
 * @interface SrcModelsSchemasSearchArticleResponse
 */
export interface SrcModelsSchemasSearchArticleResponse {
    /**
     * 
     * @type {number}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    statusCode: number;
    /**
     * List of DOIs added correctly
     * @type {Array<any>}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    validDois: Array<any>;
    /**
     * List of DOIs containing errors
     * @type {Array<any>}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    errorDois: Array<any>;
    /**
     * List of articles found, and their information attached
     * @type {Array<ArticleItem>}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    articles: Array<ArticleItem>;
    /**
     * List of reviews found, and their information attached
     * @type {Array<ArticleItem>}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    reviews: Array<ArticleItem>;
    /**
     * Whether the articles and reviews where saved in the database.
     * @type {boolean}
     * @memberof SrcModelsSchemasSearchArticleResponse
     */
    saved: boolean;
}

/**
 * Check if a given object implements the SrcModelsSchemasSearchArticleResponse interface.
 */
export function instanceOfSrcModelsSchemasSearchArticleResponse(value: object): value is SrcModelsSchemasSearchArticleResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('validDois' in value) || value['validDois'] === undefined) return false;
    if (!('errorDois' in value) || value['errorDois'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    if (!('saved' in value) || value['saved'] === undefined) return false;
    return true;
}

export function SrcModelsSchemasSearchArticleResponseFromJSON(json: any): SrcModelsSchemasSearchArticleResponse {
    return SrcModelsSchemasSearchArticleResponseFromJSONTyped(json, false);
}

export function SrcModelsSchemasSearchArticleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasSearchArticleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'validDois': json['valid_dois'],
        'errorDois': json['error_dois'],
        'articles': ((json['articles'] as Array<any>).map(ArticleItemFromJSON)),
        'reviews': ((json['reviews'] as Array<any>).map(ArticleItemFromJSON)),
        'saved': json['saved'],
    };
}

export function SrcModelsSchemasSearchArticleResponseToJSON(value?: SrcModelsSchemasSearchArticleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'valid_dois': value['validDois'],
        'error_dois': value['errorDois'],
        'articles': ((value['articles'] as Array<any>).map(ArticleItemToJSON)),
        'reviews': ((value['reviews'] as Array<any>).map(ArticleItemToJSON)),
        'saved': value['saved'],
    };
}

