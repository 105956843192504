import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

export const useFetch = <T, R = T>(
    apiFunc: () => Promise<T>,
    transform?: (data: T) => R,
    dependencies: any[] = []
) => {
    const [data, setData] = useState<R | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        setLoading(true)
        setError(null)

        apiFunc()
            .then((result) => {
                const transformedData = transform
                    ? transform(result)
                    : (result as unknown as R)
                setData(transformedData)
                setLoading(false)
            })
            .catch((err) => {
                setError(err.message || 'Error fetching data')
                toast.error('Error processing request', {
                    style: {
                        backgroundColor: '#FF3D3D',
                        color: 'white',
                        borderRadius: '8px',
                        fontSize: '16px',
                        padding: '10px',
                    },
                })
                setLoading(false)
            })
    }, dependencies)

    return { data, loading, error }
}
