export const LoadingSpinner = ({ color = '#FFFFFF' }: { color?: string }) => {
    return (
        <svg
            className="inline h-8 w-8 -m-2 animate-spin"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill={color} // Fill color set to the passed or default color
            strokeWidth="0.00016"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M8,1V2.8A5.2,5.2,0,1,1,2.8,8H1A7,7,0,1,0,8,1Z"></path>
                </g>
            </g>
        </svg>
    )
}
