import apiClient from '../api/apiClient'
import type {
    BinaryResultsResponse,
    SrcModelsSchemasUserProfileOutput,
} from '../api/openapi'

export const getUserProfile =
    (): Promise<SrcModelsSchemasUserProfileOutput> => {
        return apiClient.fetchProfileV3FetchProfilePost()
    }

export const updateUserProfile = (
    firstName: string,
    lastName: string,
    otherProfile: string,
    company: string,
    jobTitle: string,
    industry: string,
    country: string,
    communications: boolean,
    notifications: boolean,
    pubmedApiKey: string
): Promise<BinaryResultsResponse> => {
    return apiClient.updateUserProfV3UpdateProfilePost({
        userProfileV2: {
            firstName,
            lastName,
            otherProfile,
            company,
            jobTitle,
            industry,
            country,
            communications,
            notifications,
            pubmedApiKey,
            timestamp: new Date().toISOString(),
        },
    })
}

export const createUserProfile = (
    firstName: string,
    lastName: string,
    otherProfile: string,
    company: string,
    jobTitle: string,
    industry: string,
    country: string,
    communications: boolean,
    notifications: boolean,
    pubmedApiKey: string
): Promise<BinaryResultsResponse> => {
    return apiClient.registerUserProfileV3CreateProfilePost({
        userProfileV2: {
            firstName,
            lastName,
            otherProfile,
            company,
            jobTitle,
            industry,
            country,
            communications,
            notifications,
            pubmedApiKey,
            timestamp: new Date().toISOString(),
        },
    })
}

export const deleteUserProfile = (): Promise<BinaryResultsResponse> => {
    return apiClient.deleteProfileV3DeleteProfilePost()
}
