import React, { useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import type { SrcModelsSchemasClinicalTrial } from '../../api/openapi'
import Card from '../../components/atoms/Card'
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg'
import { IconButton } from '../../components/buttons/IconButton'
import HoverOnTables from '../../components/atoms/HoverOnTables'
import { IoMdInformationCircle } from 'react-icons/io'
import { Tooltip as ReactTooltip } from 'react-tooltip' // Import with alias "ReactTooltip"

export const ClinicalTrialsTable = ({
    trials,
    onClose,
}: {
    trials: SrcModelsSchemasClinicalTrial[]
    onClose: () => void
}) => {
    const [isClosing, setIsClosing] = useState(false)

    const handleClose = () => {
        setIsClosing(true)
        setTimeout(() => {
            setIsClosing(false)
            onClose()
        }, 300)
    }

    const columns: GridColDef[] = [
        {
            field: 'nctNumber',
            headerName: 'NCT',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'studyTitle',
            headerName: 'Title',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1.5,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <a
                        href={`https://clinicaltrials.gov/study/${params.row.nctNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm font-bold text-blue hover:text-yellow dark:text-blue-400 dark:hover:text-blue-500"
                    >
                        {params.value}
                    </a>
                </HoverOnTables>
            ),
        },
        {
            field: 'studyStatus',
            headerName: 'Status',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.7,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
        {
            field: 'briefSummary',
            headerName: 'Summary',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <span className="cursor-pointer text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </span>
                </HoverOnTables>
            ),
        },
    ]

    return (
        <Card
            className={`w-screen flex-grow ${isClosing ? 'animate-slideOut' : 'animate-slideIn'}`}
        >
            <div className="flex flex-col w-full h-full text-veryDarkGray">
                <div className="pr-4 flex mr-auto mb-4 items-center justify-between w-full">
                    <IconButton onClick={handleClose} icon={<BackIcon />} />
                    <IoMdInformationCircle
                        data-tooltip-id="info-tooltip"
                        data-tooltip-html={`In this table Erudita shows the most relevant clinical trials related to your generated idea set.`}
                        className="cursor-pointer text-blue w-6 h-6 transition-transform transform hover:scale-110"
                    />
                    {/* Tooltip element */}
                    <ReactTooltip
                        className="z-[9999]"
                        id="info-tooltip"
                        place="left"
                        openOnClick
                        clickable
                        style={{
                            backgroundColor: '#EBF0F3',
                            color: '#767676',
                            border: '1px solid #767676',
                            boxShadow: '0px 4px 6px rgba(118, 118, 118, 0.6)',
                        }}
                    />
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        hideFooter={true}
                        rows={trials.map((trial) => ({
                            ...trial,
                            id: trial.nctNumber,
                        }))}
                        columns={columns}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: '#EBF0F3',
                            backgroundColor: '#EBF0F3',
                        }}
                        className="dark:bg-darkBlue dark:border-darkBlue"
                    />
                </div>
            </div>
        </Card>
    )
}
