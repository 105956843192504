import React from 'react'
import { FaLinkedin, FaYoutube, FaMedium } from 'react-icons/fa' // Importing icons from react-icons

// Helper function to get the current year with a fallback to 2025
const getCurrentYear = () => {
    try {
        // Try getting the current year
        return new Date().getFullYear()
    } catch (error) {
        // If an error occurs (e.g. in very rare environments), return 2025
        console.error(
            'Error getting the current year, defaulting to 2025:',
            error
        )
        return 2025
    }
}

const Footer = () => {
    const currentYear = getCurrentYear() // Get the current year dynamically

    return (
        <footer className="bg-white dark:bg-bgBlack text-darkGray py-3 transition-colors duration-100 ease-in">
            <div className="container mx-auto flex justify-between items-center px-4">
                <div className="flex space-x-2 font-bold ">
                    <a
                        href="https://erudita.tech/terms-of-service/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow"
                    >
                        Terms & Conditions
                    </a>
                    <span>·</span>
                    <a
                        href="https://erudita.tech/contact/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow"
                    >
                        Contact
                    </a>
                    <span>·</span>
                    <a
                        href="https://erudita.tech/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow"
                    >
                        Privacy Policy
                    </a>
                </div>

                <div className="text-center">
                    {/* Display dynamic current year or fallback to 2025 */}©{' '}
                    {currentYear} Erudita Tech. All rights reserved
                </div>

                <div className="flex items-center space-x-2">
                    <span className="pr-4">
                        Follow us on social media <br />
                        to get the latest updates!
                    </span>
                    <a
                        href="https://www.linkedin.com/company/eruditatech/posts/?feedView=all"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow transition-transform transform hover:scale-110"
                        aria-label="LinkedIn"
                    >
                        <FaLinkedin size={30} />
                    </a>
                    <a
                        href="https://www.youtube.com/playlist?list=PL7LSKp9_kH7Oz0YaKpgpBsnbksI9S1Qq_"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow transition-transform transform hover:scale-110"
                        aria-label="X/Twitter"
                    >
                        <FaYoutube size={30} />
                    </a>
                    <a
                        href="https://medium.com/@some1_14742"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-yellow transition-transform transform hover:scale-110"
                        aria-label="Medium"
                    >
                        <FaMedium size={30} />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
