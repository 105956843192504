/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchArticlesResponse
 */
export interface FetchArticlesResponse {
    /**
     * 
     * @type {number}
     * @memberof FetchArticlesResponse
     */
    statusCode: number;
    /**
     * List of article (ENCODED AS STR)
     * @type {string}
     * @memberof FetchArticlesResponse
     */
    articles: string;
    /**
     * List of review (ENCODED AS STR)
     * @type {string}
     * @memberof FetchArticlesResponse
     */
    reviews: string;
}

/**
 * Check if a given object implements the FetchArticlesResponse interface.
 */
export function instanceOfFetchArticlesResponse(value: object): value is FetchArticlesResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    return true;
}

export function FetchArticlesResponseFromJSON(json: any): FetchArticlesResponse {
    return FetchArticlesResponseFromJSONTyped(json, false);
}

export function FetchArticlesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchArticlesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'articles': json['articles'],
        'reviews': json['reviews'],
    };
}

export function FetchArticlesResponseToJSON(value?: FetchArticlesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'articles': value['articles'],
        'reviews': value['reviews'],
    };
}

