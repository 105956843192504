import React, { useEffect, useState } from 'react'
import CustomInputField from '../atoms/CustomInputField'
import { MinusButton } from '../buttons/MinusButton'
import { colorMapping } from '../../utils/colorMappings'
import { IconButton } from '../buttons/IconButton'
import { FaPlus } from 'react-icons/fa'

interface DynamicListManagerProps {
    placeholder: string
    initialItems?: string[] // Optional initial list
    onListChange: (updatedList: string[]) => void // Callback for list changes
    backgroundColor: string
    emailValidation?: boolean
    maxItems?: number
    disabled?: boolean
    collaboratorLimit?: number // New prop for collaborator limit
}

const DynamicListManager = ({
    placeholder,
    initialItems = [], // Default to empty array if not provided
    onListChange,
    backgroundColor,
    emailValidation = false,
    maxItems = 10,
    disabled = false,
    collaboratorLimit,
}: DynamicListManagerProps) => {
    const [inputValue, setInputValue] = useState('')
    const [items, setItems] = useState<string[]>(initialItems)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark
    const [inputError, setInputError] = useState<boolean>(false) // New state for input error

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Effect to notify parent of the initial items on mount
    useEffect(() => {
        setItems(initialItems) // Sincronizar con initialItems
    }, [initialItems]) // Se ejecuta cada vez que initialItems cambia

    const handleAddItem = () => {
        // Check if we're at the collaborator limit
        if (
            collaboratorLimit !== undefined &&
            items.length >= collaboratorLimit
        ) {
            setErrorMessage(
                `You can not add more than ${collaboratorLimit} collaborators with your current plan.`
            )
            setInputError(true) // Set error state
            return
        }

        if (maxItems && items.length >= maxItems) {
            setErrorMessage(`You can only add up to ${maxItems} items.`)
            setInputError(true) // Set error state
            return
        }

        // Check duplicate
        if (items.includes(inputValue.trim())) {
            setErrorMessage('This item already exists.')
            setInputError(true) // Set error state
            return
        }

        if (inputValue.trim()) {
            // Ensure input is not just spaces
            if (emailValidation && !emailRegex.test(inputValue)) {
                setErrorMessage('Please enter a valid email address.')
                setInputError(true) // Set error state
                return
            }
            const updatedItems = [...items, inputValue.trim()]
            setItems(updatedItems)
            setInputValue('') // Clear the input field after adding
            onListChange(updatedItems) // Notify the parent of the list change
            setErrorMessage(null) // Clear any previous error messages
            setInputError(false) // Clear error state
        }
    }

    const handleRemoveItem = (indexToRemove: number) => {
        const updatedItems = items.filter((_, index) => index !== indexToRemove)
        setItems(updatedItems)
        onListChange(updatedItems) // Notify the parent of the list change
    }

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (event.key === 'Enter') {
            handleAddItem()
        }
    }

    // Clear error state when input changes
    useEffect(() => {
        if (inputValue.length > 0) {
            setInputError(false)
            setErrorMessage(null)
        }
    }, [inputValue])

    return (
        <div className={'flex flex-wrap gap-2 items-baseline'}>
            <div className={'flex flex-col gap-2'}>
                <div className={'flex flex-row items-center gap-2'}>
                    <IconButton
                        buttonSize={6}
                        onClick={handleAddItem}
                        icon={<FaPlus className={`w-3 h-3`} />}
                        disabled={disabled}
                        className="mb-6"
                    />
                    <CustomInputField
                        backgroundColor={'white'}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        disabled={disabled}
                        errorMessage={inputError ? errorMessage : null} // Pass errorMessage to CustomInputField
                        className="min-h-6"
                    />
                </div>
                {errorMessage && !inputError && (
                    <div className="text-red text-sm">{errorMessage}</div>
                )}
            </div>
            {items.map((item, index) => (
                <div
                    key={index}
                    className={` flex flex-row p-2 bg-${lightColor} dark:bg-${darkColor} items-center gap-3 rounded-md`}
                >
                    <span className={'text-yellow font-bold text-base'}>
                        {item}
                    </span>
                    <MinusButton
                        iconSize={3}
                        onClick={() => handleRemoveItem(index)}
                        disabled={disabled}
                    />
                </div>
            ))}
        </div>
    )
}

export default DynamicListManager
