import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import { UserProfileCard } from '../../components/molecules/UserProfileCard'
import { InfoModal } from '../../features/info-modal/InfoModal'

const CreateProfile = () => {
    const navigate = useNavigate()
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showWelcomeModal, setShowWelcomeModal] = useState(true)

    useEffect(() => {
        if (!LocalStorageUtil.isUserLoggedIn()) {
            navigate('/login')
        }
    }, [navigate])

    const handleProfileCardCloseAttempt = (isProfileFilled: boolean) => {
        if (!isProfileFilled) {
            setShowInfoModal(true)
        }
    }

    const handleInfoModalClose = () => {
        setShowInfoModal(false)
    }

    const handleProfileCardSaved = () => {
        navigate('/')
    }

    const handleWelcomeModalClose = () => {
        setShowWelcomeModal(false)
    }

    return (
        <div className="flex items-center justify-center pt-10">
            <div className="w-full px-2 md:w-[760px] h-full flex items-center justify-center">
                <UserProfileCard
                    onClose={handleProfileCardCloseAttempt}
                    isModalOpen={true}
                    disableClose={true}
                    onProfileSaved={handleProfileCardSaved}
                />
            </div>
            <InfoModal
                isOpen={showInfoModal}
                closeAction={handleInfoModalClose}
                title="Profile information Required"
                text="You must fill in your profile information before continuing to Erudita."
            />
            <InfoModal
                isOpen={showWelcomeModal}
                closeAction={handleWelcomeModalClose}
                title="Welcome to Erudita!"
                text="Before continuing to the platform we need some information about you in order to be able to give you the best experience possible, please, fill the form before continuing."
            />
        </div>
    )
}

export default CreateProfile
