/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPayment
 */
export interface NewPayment {
    /**
     * Email of the user setting up the subscription
     * @type {string}
     * @memberof NewPayment
     */
    userEmail: string;
    /**
     * Name of the product
     * @type {string}
     * @memberof NewPayment
     */
    productName: string;
}

/**
 * Check if a given object implements the NewPayment interface.
 */
export function instanceOfNewPayment(value: object): value is NewPayment {
    if (!('userEmail' in value) || value['userEmail'] === undefined) return false;
    if (!('productName' in value) || value['productName'] === undefined) return false;
    return true;
}

export function NewPaymentFromJSON(json: any): NewPayment {
    return NewPaymentFromJSONTyped(json, false);
}

export function NewPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPayment {
    if (json == null) {
        return json;
    }
    return {
        
        'userEmail': json['user_email'],
        'productName': json['product_name'],
    };
}

export function NewPaymentToJSON(value?: NewPayment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_email': value['userEmail'],
        'product_name': value['productName'],
    };
}

