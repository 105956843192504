import React, { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'

const HoverOnTables = ({
    title,
    children,
}: {
    title: string
    children: React.ReactNode
}) => {
    const [open, setOpen] = useState(false)
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

    const handleMouseEnter = () => {
        if (timeoutId) clearTimeout(timeoutId)
        const newTimeout = setTimeout(() => setOpen(true), 2000)
        setTimeoutId(newTimeout)
    }

    const handleMouseLeave = () => {
        if (timeoutId) clearTimeout(timeoutId)
        setOpen(false)
    }

    return (
        <Tooltip title={title} open={open} arrow onClose={() => setOpen(false)}>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </Tooltip>
    )
}

export default HoverOnTables
