/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileEnum } from './FileEnum';
import {
    FileEnumFromJSON,
    FileEnumFromJSONTyped,
    FileEnumToJSON,
} from './FileEnum';

/**
 * 
 * @export
 * @interface FetchFulltextFile
 */
export interface FetchFulltextFile {
    /**
     * Project ID
     * @type {string}
     * @memberof FetchFulltextFile
     */
    projectId: string;
    /**
     * Full-text ID
     * @type {string}
     * @memberof FetchFulltextFile
     */
    fulltextId: string;
    /**
     * File format
     * @type {FileEnum}
     * @memberof FetchFulltextFile
     */
    format: FileEnum;
}



/**
 * Check if a given object implements the FetchFulltextFile interface.
 */
export function instanceOfFetchFulltextFile(value: object): value is FetchFulltextFile {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('fulltextId' in value) || value['fulltextId'] === undefined) return false;
    if (!('format' in value) || value['format'] === undefined) return false;
    return true;
}

export function FetchFulltextFileFromJSON(json: any): FetchFulltextFile {
    return FetchFulltextFileFromJSONTyped(json, false);
}

export function FetchFulltextFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchFulltextFile {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'fulltextId': json['fulltext_id'],
        'format': FileEnumFromJSON(json['format']),
    };
}

export function FetchFulltextFileToJSON(value?: FetchFulltextFile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'fulltext_id': value['fulltextId'],
        'format': FileEnumToJSON(value['format']),
    };
}

