import { Home } from '../pages/Home'
import { Login } from '../pages/login/Login'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from 'react-router-dom'
import { Wizard } from '../pages/wizard/Wizard'
import { LiteratureSearch } from '../pages/wizard/LiteratureSearch'
import { ResultsExtraction } from '../pages/wizard/ResultsExtraction'
import { IdeasGeneration } from '../pages/wizard/IdeasGeneration'
import { IdeaPreview } from '../pages/wizard/IdeaPreview'
import { ProtocolGeneration } from '../pages/wizard/ProtocolGeneration'
import React from 'react'
import Navbar from '../components/molecules/Navbar'
import LinkedInRedirect from '../pages/login/LinkedInRedirect'
import CreateProfile from '../pages/login/CreateProfile'
import { RecoverPassword } from '../pages/recover-password/RecoverPassword'
import { SetPassword } from '../pages/set-password/SetPassword'
import SessionExpiredModal from '../components/molecules/SessionExpiredModal'

// Componente para condicionar la renderización de la navbar
const NavbarWrapper = () => {
    const location = useLocation()

    // No mostrar la navbar en estas rutas
    const hideNavbarPaths = ['/login', '/recover-password', '/set-password']

    if (hideNavbarPaths.includes(location.pathname)) {
        return null
    }

    return <Navbar />
}

const AppRouter = () => (
    <Router>
        {/* Navbar condicionada por la ruta actual */}
        <NavbarWrapper />
        <div className="flex-grow flex flex-col mt-[40px] pb-[80px]">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/recover-password" element={<RecoverPassword />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/create-profile" element={<CreateProfile />} />
                <Route path="/linkedin/auth" element={<LinkedInRedirect />} />
                <Route path="wizard/:projectId" element={<Wizard />}>
                    <Route
                        index
                        element={<Navigate to="literature-search" />}
                    />
                    <Route
                        path="literature-search"
                        element={<LiteratureSearch />}
                    />
                    <Route
                        path="literature-search/:searchId"
                        element={<LiteratureSearch />}
                    />
                    <Route
                        path="results-extraction/:searchId"
                        element={<ResultsExtraction />}
                    />
                    <Route
                        path="ideas-generation/:searchId/:ideasSetId"
                        element={<IdeasGeneration />}
                    />
                    <Route
                        path="idea-preview/:ideasSetId/:ideaId"
                        element={<IdeaPreview />}
                    />
                    <Route
                        path="protocol-generation/:ideasSetId/:ideaId/:protocolId"
                        element={<ProtocolGeneration />}
                    />
                    <Route
                        path="protocol-generation/:ideasSetId/:ideaId"
                        element={<ProtocolGeneration />}
                    />
                </Route>
            </Routes>
        </div>
        <SessionExpiredModal />
    </Router>
)

export default AppRouter
