import React, { createContext, useContext, useState, useEffect } from 'react'
import {
    IdeaSetsRunning,
    ProjectOutput,
    ProtocolRunning,
    SrcModelsSchemasUserHistory,
} from '../api/openapi'
import { useParams } from 'react-router-dom'
import { fetchProjectData } from '../middleware/projectService'
import LocalStorageUtil from '../utils/LocalStorageUtil'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../components/atoms/LoadingSpinner'
import { Subtitle } from '../components/atoms/Subtitle'

// Create the context
const WizardContext = createContext<{
    historyData: SrcModelsSchemasUserHistory | null
    projectData: ProjectOutput | null
    ideasSetRunning: boolean
    protocolsRunning: ProtocolRunning[] | null
    institutions: string[]
    authors: string[]
    loading: boolean
    error: any
    isOwner: boolean
    refreshData: () => void
    loadingGenerateIdeas: boolean
    setLoadingGenerateIdeasContext: (loading: boolean) => void
    selectedInstitutions: string[]
    selectedAuthors: string[]
    setSelectedInstitutions: (institutions: string[]) => void
    setSelectedAuthors: (authors: string[]) => void
}>({
    historyData: null,
    projectData: null,
    ideasSetRunning: false,
    protocolsRunning: null,
    institutions: [],
    authors: [],
    loading: true,
    error: null,
    isOwner: false,
    refreshData: () => {},
    loadingGenerateIdeas: false,
    setLoadingGenerateIdeasContext: () => {},
    selectedInstitutions: [],
    selectedAuthors: [],
    setSelectedInstitutions: () => {},
    setSelectedAuthors: () => {},
})

// Custom hook to use the WizardContext
export const useData = () => useContext(WizardContext)

// Provider component
export const WizardProvider = ({ children }: { children: React.ReactNode }) => {
    const { projectId, searchId, ideasSetId, ideaId } = useParams<{
        projectId: string
        searchId: string
        ideasSetId: string
        ideaId: string
    }>()
    const urlParams = useParams()

    const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>(
        []
    )
    const [selectedAuthors, setSelectedAuthors] = useState<string[]>([])
    const [projectData, setProjectData] = useState<ProjectOutput | null>(null)
    const [historyData, setHistoryData] =
        useState<SrcModelsSchemasUserHistory | null>(null)
    const [ideasSetRunning, setIdeasSetRunning] = useState<
        IdeaSetsRunning[] | null
    >(null)
    const [protocolsRunning, setProtocolsRunning] = useState<
        ProtocolRunning[] | null
    >(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<any>(null)
    const [isOwner, setIsOwner] = useState(false)
    const [loadingGenerateIdeas, setLoadingGenerateIdeas] = useState(false)
    const [institutions, setInstitutions] = useState<string[]>([])
    const [authors, setAuthors] = useState<string[]>([])

    // Fetch data from the API
    const fetchData = async () => {
        setLoading(true)
        try {
            const data = await fetchProjectData(projectId!) //Ensure projectId is not undefined

            // Set owner status based on fetched data
            setIsOwner(
                data.information?.owner.toLowerCase() ===
                    LocalStorageUtil.getEmail()!.toLowerCase()
            )
            setProjectData(data.information || null)
            setHistoryData(data.history || null)
            setIdeasSetRunning(data.ideasSetRunning || [])
            setProtocolsRunning(data.protocolsRunning || [])
            setLoading(false)

            //Ensure the value is always boolean
            const isGeneratingIdeas = !!data.ideasSetRunning?.length
            setLoadingGenerateIdeas(isGeneratingIdeas)

            if (isGeneratingIdeas) {
                toast.info('Ideas are being generated.', {})
            }

            // TODO add toast if a protocol is running

            // Handle authors and institutions for protocols
            if (data.protocolsRunning && data.protocolsRunning.length > 0) {
                setAuthors(
                    data.protocolsRunning.flatMap(
                        (protocol) => protocol.authors
                    )
                )

                setInstitutions(
                    data.protocolsRunning.flatMap(
                        (protocol) => protocol.institutions
                    )
                )
            }
        } catch (err) {
            setError(err) //Handles error type in `setError`
            setLoading(false)
        }
    }

    const setLoadingGenerateIdeasContext = (loading: boolean) => {
        setLoadingGenerateIdeas(loading)
    }

    // Fetch data on component mount or when dependencies change
    useEffect(() => {
        fetchData()
    }, [projectId, searchId, ideasSetId, ideaId])

    const refreshData = () => {
        fetchData()
    }

    //Ensure `useEffect` runs before rendering context
    useEffect(() => {
        setSelectedAuthors(authors)
        setSelectedInstitutions(institutions)
    }, [authors, institutions])

    return (
        <WizardContext.Provider
            value={{
                projectData,
                historyData,
                ideasSetRunning: !!ideasSetRunning?.length,
                protocolsRunning: protocolsRunning,
                institutions,
                authors,
                loading,
                error,
                isOwner,
                refreshData,
                loadingGenerateIdeas,
                setLoadingGenerateIdeasContext,
                selectedInstitutions,
                selectedAuthors,
                setSelectedInstitutions,
                setSelectedAuthors,
            }}
        >
            {loading ? (
                <div className="flex flex-col items-center gap-6">
                    <div className="flex justify-center p-5">
                        <LoadingSpinner color="#FFAC27" />
                    </div>
                </div>
            ) : (
                children
            )}
        </WizardContext.Provider>
    )
}
