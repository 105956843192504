import apiClient from '../api/apiClient'
import {
    type BinaryResultsResponse,
    type ListMessages,
    StageEnum,
} from '../api/openapi'
import localStorageUtil from '../utils/LocalStorageUtil'

export const getMessages = async (projectId: string): Promise<ListMessages> => {
    return apiClient.fetchMessagesApiV3FetchMessagesPost({
        projectId: { projectId: projectId },
    })
}

export const sendMessage = async (
    projectId: string,
    stage: StageEnum,
    message: string
): Promise<BinaryResultsResponse> => {
    return apiClient.addMessageApiV3AddMessagePost({
        messageInput: {
            projectId: projectId,
            stage: stage,
            author: localStorageUtil.getEmail() || '',
            message: message,
        },
    })
}
