import Card from '../../components/atoms/Card'
import { IdeaCard } from '../../components/molecules/IdeaCard'

import React, { useEffect, useState } from 'react'
import { CustomButton } from '../../components/buttons/CustomButton'
import { useNavigate, useParams } from 'react-router-dom'
import { type SrcRoutersIdeaItem, StageEnum } from '../../api/openapi'
import { useFetch } from '../../hooks/useFetch'
import { fetchIdeas, generateIdeas } from '../../middleware/ideasService'
import CommentSection from '../../features/comment-section/CommentSection'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { FiRefreshCcw } from 'react-icons/fi'
import { IoEye } from 'react-icons/io5'
import { ArticlesTable } from '../../features/articles-table/ArticlesTable'
import { TableViewButton } from '../../components/buttons/TableViewButton'
import { useData } from '../../context/WizardContext'
import { InfoModal } from '../../features/info-modal/InfoModal'
import { InfoTextIdeaGeneration } from '../../components/atoms/InfoTexts'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import { Subtitle } from '../../components/atoms/Subtitle'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import { IconButton } from '../../components/buttons/IconButton'
import HoverOnTables from '../../components/atoms/HoverOnTables'

export const IdeasGeneration = () => {
    useBeforeUnload()
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { isOwner, ideasSetRunning, setLoadingGenerateIdeasContext } =
        useData()
    const navigate = useNavigate()

    const [modalOpen, setModalOpen] = useState<boolean>(true)
    const [selectedIdea, setSelectedIdea] = useState<SrcRoutersIdeaItem>()
    const [articlesView, setArticlesView] = useState<boolean>(false)

    const { data, error, loading } = useFetch(
        () => fetchIdeas(projectId!, ideasSetId!),
        undefined,
        [projectId, ideasSetId]
    )
    const { fetchData: generateIdeasCall, loading: loadingGenerateIdeas } =
        useFetchOnDemand(
            () => {
                return generateIdeas(projectId!, searchId!)
            },
            undefined,
            StageEnum.Ideas
        )

    useEffect(() => {
        // Only show the modal if:
        // 1. The process is running
        // 2. There are idea sets available
        // 3. The data is not in a loading state
        if (
            ideasSetRunning &&
            data?.results &&
            data.results.length > 0 &&
            !loading
        ) {
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }, [ideasSetRunning, data?.results, loading])

    const handleIdeaClick = (idea: SrcRoutersIdeaItem) => {
        setSelectedIdea(idea)
    }

    const handleNext = () => {
        navigate(
            `/wizard/${projectId}/idea-preview/${ideasSetId}/${selectedIdea?.id}`
        )
    }

    const regenerateIdeas = () => {
        setLoadingGenerateIdeasContext(true)
        // Don't set modalOpen here, let the useEffect handle it
        generateIdeasCall().then((data) => {
            //navigate to the first idea in the list
            if (!data) return
            navigate(
                `/wizard/${projectId}/ideas-generation/${searchId}/${data.ideasSetId}`
            )
        })
    }

    const handleArticlesView = () => {
        setArticlesView(!articlesView)
    }

    return (
        <div className={'flex flex-col items-center gap-8 wizard-width'}>
            {loading && (
                <div className="flex justify-center p-5">
                    <LoadingSpinner color="#FFAC27" />
                </div>
            )}
            {ideasSetRunning && (
                <div className="flex flex-col items-center gap-8 p-4">
                    <div className="flex flex-col items-center">
                        <div className="pb-5">
                            <LoadingSpinner color="#FFAC27" />{' '}
                        </div>
                        <Subtitle text="Your idea set will appear here, we will send you an email once the process finishes." />
                    </div>
                </div>
            )}
            {error && (
                <div className={'dark:text-veryLightGray'}>
                    Error loading ideas.
                </div>
            )}
            {!error && !loading && !ideasSetRunning && (
                <div className={'flex flex-col items-center gap-8 w-full'}>
                    <div
                        className={`flex flex-row w-full items-start gap-8 ${articlesView ? 'w-full lg:max-w-[1000px] xl:max-w-[1100px] 2xl:w-[1500px]' : 'wizard-width'}  relative`}
                    >
                        {!articlesView && (
                            <Card>
                                <div className="flex flex-col  bg-lightGray dark:bg-darkBlue items-center gap-4 p-4 rounded-md">
                                    <div className="flex flex-row items-center w-full">
                                        <div className="flex-1">
                                            <TableViewButton
                                                backgroundColorClass={
                                                    'bg-white dark:bg-bgBlack'
                                                }
                                                text={'Relevant articles'}
                                                onClick={handleArticlesView}
                                                icon={
                                                    <IoEye
                                                        className={`text-blue w-6 h-6`}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div>
                                            {isOwner && (
                                                <HoverOnTables title="Regenerate idea set">
                                                    <IconButton
                                                        loading={
                                                            loadingGenerateIdeas
                                                        }
                                                        onClick={
                                                            regenerateIdeas
                                                        }
                                                        icon={
                                                            <FiRefreshCcw className="text-blue w-6 h-6" />
                                                        }
                                                    />
                                                </HoverOnTables>
                                            )}
                                        </div>
                                    </div>
                                    {data?.results?.map((idea, index) => (
                                        <IdeaCard
                                            key={index}
                                            idea={idea}
                                            selected={selectedIdea?.id || ''}
                                            onClick={handleIdeaClick}
                                        />
                                    ))}
                                </div>
                            </Card>
                        )}
                        {articlesView && (
                            <ArticlesTable
                                articles={data?.articles || []}
                                onClose={() => setArticlesView(false)}
                            />
                        )}
                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                            infoText={<InfoTextIdeaGeneration />}
                        />
                    </div>
                    <div className={`flex gap-4 self-end`}>
                        {isOwner && !articlesView && (
                            <CustomButton
                                isDisabled={
                                    !selectedIdea || loadingGenerateIdeas
                                }
                                text={'Next'}
                                onClick={handleNext}
                            />
                        )}
                    </div>
                </div>
            )}
            <CommentSection projectId={projectId!} stage={StageEnum.Ideas} />
            <InfoModal
                title={'This process may take a few minutes...'}
                text={
                    <div className="flex flex-col items-center">
                        <Subtitle text="Generating your idea set, we will send you an email once it's ready." />
                        <div className="pb-5 pt-5">
                            <LoadingSpinner color="#FFAC27" />
                        </div>
                    </div>
                }
                isOpen={modalOpen} // Mostrar u ocultar el modal según el estado de modalOpen
                closeAction={() => setModalOpen(false)} // Permite cerrar el modal manualmente
            />

            {/*<IdeasHistorySection*/}
            {/*    projectId={projectId!}*/}
            {/*    selectIdeaSet={handleSelectIdea}*/}
            {/*/>*/}
        </div>
    )
}
