import { Message } from '../../api/openapi'

const CommentCard = ({
    isMine,
    comment,
}: {
    isMine: boolean
    comment: Message
}) => {
    return (
        <div
            className={`bg-lightGray dark:bg-darkBlue shadow-${isMine ? 'yellowCustomShadow' : 'blueCustomShadow'} shadow-md py-1 px-2 rounded-md`}
        >
            <div
                className={`flex flex-col items-start justify-between gap-0.5 w-full`}
            >
                {/* Conditionally render the author's name */}
                <div
                    className={`font-bold text-base pb-4 break-words overflow-wrap-anywhere w-full ${isMine ? 'text-yellow' : 'text-blue'}`}
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    {comment.author}:
                </div>
                <div
                    className={`text-black dark:text-white font-normal text-sm pb-4 break-words overflow-wrap-anywhere w-full`}
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    {comment.message}
                </div>
                <div
                    className={`self-end text-veryDarkGray dark:text-veryLightGray font-light text-xs w-full`}
                >
                    {new Date(comment.timestamp).toLocaleString()}
                </div>
            </div>
        </div>
    )
}

export default CommentCard
