export const IconButton = ({
    onClick,
    icon,
    loading,
    buttonSize,
    disabled,
    className,
}: {
    onClick: (event: any) => void
    icon: React.ReactNode
    loading?: boolean
    buttonSize?: number
    disabled?: boolean
    className?: string
}) => {
    return (
        <button
            disabled={loading || disabled}
            onClick={onClick}
            className={`flex items-center justify-center ${buttonSize ? 'w-' + buttonSize + ' h-' + buttonSize : 'w-9 h-9'}
            ${disabled ? 'bg-lightGray text-veryDarkGray shadow-grayCustomShadow cursor-not-allowed' : 'bg-yellow text-blue shadow-yellowCustomShadow'}
            p-1.5 rounded-md transition-transform transform hover:scale-110 ${className}`}
        >
            {loading && (
                <div className="pulsing-dot w-3 h-3 bg-blue rounded-full"></div>
            )}
            {!loading && icon}
        </button>
    )
}
