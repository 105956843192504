/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcRoutersArticleInfoItemV2 } from './SrcRoutersArticleInfoItemV2';
import {
    SrcRoutersArticleInfoItemV2FromJSON,
    SrcRoutersArticleInfoItemV2FromJSONTyped,
    SrcRoutersArticleInfoItemV2ToJSON,
} from './SrcRoutersArticleInfoItemV2';

/**
 * 
 * @export
 * @interface IdeaSetItem
 */
export interface IdeaSetItem {
    /**
     * Search ID
     * @type {string}
     * @memberof IdeaSetItem
     */
    id?: string;
    /**
     * User ID
     * @type {string}
     * @memberof IdeaSetItem
     */
    userId?: string;
    /**
     * ID of the search associated
     * @type {string}
     * @memberof IdeaSetItem
     */
    searchId?: string;
    /**
     * Timestamp of the search
     * @type {string}
     * @memberof IdeaSetItem
     */
    timestamp?: string;
    /**
     * Original user's query
     * @type {string}
     * @memberof IdeaSetItem
     */
    originalQuery?: string;
    /**
     * List of relevant articles and reviews associated to the idea set
     * @type {Array<SrcRoutersArticleInfoItemV2>}
     * @memberof IdeaSetItem
     */
    articles?: Array<SrcRoutersArticleInfoItemV2>;
}

/**
 * Check if a given object implements the IdeaSetItem interface.
 */
export function instanceOfIdeaSetItem(value: object): value is IdeaSetItem {
    return true;
}

export function IdeaSetItemFromJSON(json: any): IdeaSetItem {
    return IdeaSetItemFromJSONTyped(json, false);
}

export function IdeaSetItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdeaSetItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'searchId': json['search_id'] == null ? undefined : json['search_id'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'originalQuery': json['original_query'] == null ? undefined : json['original_query'],
        'articles': json['articles'] == null ? undefined : ((json['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2FromJSON)),
    };
}

export function IdeaSetItemToJSON(value?: IdeaSetItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'search_id': value['searchId'],
        'timestamp': value['timestamp'],
        'original_query': value['originalQuery'],
        'articles': value['articles'] == null ? undefined : ((value['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2ToJSON)),
    };
}

