import { FunctionButton } from '../buttons/FunctionButton'
import { HiOutlineHome } from 'react-icons/hi'
import { FiInfo } from 'react-icons/fi'
import { IoSettingsSharp } from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactNode, useState } from 'react'
import { ProjectSettingsCard } from '../../features/project-settings/ProjectSettingsCard'
import Modal from '../atoms/Modal'
import { MdHistory } from 'react-icons/md'
import { HistoryTable } from '../../features/history-table/HistoryTable'
import { InfoModal } from '../../features/info-modal/InfoModal'
import { useData } from '../../context/WizardContext'

export const FunctionButtonsColumn = ({
    projectId,
    onShowHistoryClick,
    infoText, // Added a new prop for the info modal text
}: {
    projectId: string
    onShowHistoryClick: () => void
    infoText: ReactNode // Text for the info modal
}) => {
    const navigate = useNavigate()
    const location = useLocation()

    // State to control the visibility of the project settings modal
    const [openProjectSettings, setOpenProjectSettings] =
        useState<boolean>(false)

    // State to control the visibility of the history modal
    const [openHistory, setOpenHistory] = useState<boolean>(false)

    // State to control the visibility of the info modal
    const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)

    const { projectData, historyData, loading, error, isOwner, refreshData } =
        useData()

    // Define the dynamic title based on the current page
    let pageTitle = 'INFORMATION' // Default title

    // Set the title based on the current path
    if (location.pathname.includes('/literature-search')) {
        pageTitle = 'Literature Search'
    } else if (location.pathname.includes('/results-extraction')) {
        pageTitle = 'Results Extraction'
    } else if (location.pathname.includes('/ideas-generation')) {
        pageTitle = 'Ideas Generation'
    } else if (location.pathname.includes('/idea-preview')) {
        pageTitle = 'Idea Preview'
    } else if (location.pathname.includes('/protocol-generation')) {
        pageTitle = 'Protocol Generation'
    }

    return (
        <div className="absolute -right-12 flex items-center justify-center">
            <div className="flex flex-col items-center gap-3">
                {/* Home button */}
                <FunctionButton
                    onClick={() => navigate('/')}
                    icon={<HiOutlineHome className="w-6 h-6" />}
                />

                {/* Info button */}
                <FunctionButton
                    onClick={() => setOpenInfoModal(true)} // Open the info modal
                    icon={<FiInfo className="w-6 h-6" />}
                />

                {/* Project settings button (only for owners) */}
                {isOwner && (
                    <FunctionButton
                        onClick={() => setOpenProjectSettings(true)}
                        icon={<IoSettingsSharp className="w-6 h-6" />}
                    />
                )}

                {/* History button */}
                <FunctionButton
                    onClick={() => setOpenHistory(true)}
                    icon={<MdHistory className="w-6 h-6" />}
                />
            </div>

            {/* Project settings modal */}
            <Modal
                isOpen={openProjectSettings}
                onClose={() => setOpenProjectSettings(false)}
            >
                {projectData && !loading && (
                    <ProjectSettingsCard
                        project={projectData}
                        onCancelClose={() => setOpenProjectSettings(false)}
                        onSaveClose={() => {
                            refreshData()
                            setOpenProjectSettings(false)
                        }}
                    />
                )}
            </Modal>

            {/* History modal */}
            <Modal isOpen={openHistory} onClose={() => setOpenHistory(false)}>
                {historyData && historyData.items && (
                    <div className="flex flex-col items-center gap-4">
                        <HistoryTable
                            historyItems={historyData.items}
                            onClose={() => setOpenHistory(false)}
                        />
                    </div>
                )}
                {loading && (
                    <div className={'dark:text-veryLightGray'}>Loading...</div>
                )}
                {error && (
                    <div className={'dark:text-veryLightGray'}>Error...</div>
                )}
            </Modal>

            {/* Info modal */}
            <InfoModal
                title={`${pageTitle} Info`} // Set the dynamic title here
                text={infoText} // Use the text provided via the prop
                isOpen={openInfoModal}
                closeAction={() => setOpenInfoModal(false)} // Close the modal
            />
        </div>
    )
}
