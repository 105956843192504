import React, { useEffect, useState } from 'react'
import { InfoModal } from '../../features/info-modal/InfoModal'
import { useNavigate } from 'react-router-dom'

const SESSION_EXPIRED_EVENT = 'sessionExpired'

const SessionExpiredModal: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const handleSessionExpired = () => {
            setIsOpen(true)
            // Set a timer to redirect after 3 seconds
            const timer = setTimeout(() => {
                setIsOpen(false)
                navigate('/login')
            }, 3000)
            return () => clearTimeout(timer)
        }

        window.addEventListener(SESSION_EXPIRED_EVENT, handleSessionExpired)

        return () => {
            window.removeEventListener(
                SESSION_EXPIRED_EVENT,
                handleSessionExpired
            )
        }
    }, [navigate])

    const handleClose = () => {
        setIsOpen(false)
        navigate('/login')
    }

    return (
        <InfoModal
            title="Session Expired"
            text="Session expired, please log in again"
            isOpen={isOpen}
            closeAction={handleClose}
        />
    )
}

export default SessionExpiredModal
