/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleItem
 */
export interface ArticleItem {
    /**
     * Pubmed ID
     * @type {string}
     * @memberof ArticleItem
     */
    id?: string;
    /**
     * Pubmed Central ID
     * @type {string}
     * @memberof ArticleItem
     */
    pmcId?: string;
    /**
     * DOI
     * @type {string}
     * @memberof ArticleItem
     */
    doi?: string;
    /**
     * Publication date
     * @type {Date}
     * @memberof ArticleItem
     */
    publicationDate: Date;
    /**
     * Publication year
     * @type {number}
     * @memberof ArticleItem
     */
    year: number;
    /**
     * Citation
     * @type {string}
     * @memberof ArticleItem
     */
    citation?: string;
    /**
     * Short version of citation
     * @type {string}
     * @memberof ArticleItem
     */
    shortCitation?: string;
    /**
     * Journal name
     * @type {string}
     * @memberof ArticleItem
     */
    journalName?: string;
    /**
     * Journal ISSN
     * @type {string}
     * @memberof ArticleItem
     */
    journalIssn?: string;
    /**
     * Title
     * @type {string}
     * @memberof ArticleItem
     */
    title?: string;
    /**
     * Abstract
     * @type {string}
     * @memberof ArticleItem
     */
    _abstract?: string;
    /**
     * Whether the articles is review or not
     * @type {boolean}
     * @memberof ArticleItem
     */
    isReview: boolean;
}

/**
 * Check if a given object implements the ArticleItem interface.
 */
export function instanceOfArticleItem(value: object): value is ArticleItem {
    if (!('publicationDate' in value) || value['publicationDate'] === undefined) return false;
    if (!('year' in value) || value['year'] === undefined) return false;
    if (!('isReview' in value) || value['isReview'] === undefined) return false;
    return true;
}

export function ArticleItemFromJSON(json: any): ArticleItem {
    return ArticleItemFromJSONTyped(json, false);
}

export function ArticleItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'pmcId': json['pmc_id'] == null ? undefined : json['pmc_id'],
        'doi': json['doi'] == null ? undefined : json['doi'],
        'publicationDate': (new Date(json['publication_date'])),
        'year': json['year'],
        'citation': json['citation'] == null ? undefined : json['citation'],
        'shortCitation': json['short_citation'] == null ? undefined : json['short_citation'],
        'journalName': json['journal_name'] == null ? undefined : json['journal_name'],
        'journalIssn': json['journal_issn'] == null ? undefined : json['journal_issn'],
        'title': json['title'] == null ? undefined : json['title'],
        '_abstract': json['abstract'] == null ? undefined : json['abstract'],
        'isReview': json['is_review'],
    };
}

export function ArticleItemToJSON(value?: ArticleItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'pmc_id': value['pmcId'],
        'doi': value['doi'],
        'publication_date': ((value['publicationDate']).toISOString().substring(0,10)),
        'year': value['year'],
        'citation': value['citation'],
        'short_citation': value['shortCitation'],
        'journal_name': value['journalName'],
        'journal_issn': value['journalIssn'],
        'title': value['title'],
        'abstract': value['_abstract'],
        'is_review': value['isReview'],
    };
}

