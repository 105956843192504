import React, { useState } from 'react'
import { colorMapping } from '../../utils/colorMappings'
import { IoEye, IoEyeOff } from 'react-icons/io5'

interface CustomInputFieldProps {
    value: string | number
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    placeholder?: string
    disabled?: boolean
    type?: string
    label?: string
    errorMessage?: string | null
    className?: string
    backgroundColor?: string
    multiline?: boolean
    icon?: React.ReactNode
    onHover?: (isHovered: boolean) => void
    hideBorder?: boolean
    onKeyDown?: (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
    style?: React.CSSProperties
    min?: number
    max?: number
    isPassword?: boolean
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
    value,
    onChange,
    placeholder,
    disabled = false,
    type = 'text',
    label,
    errorMessage,
    className = '',
    backgroundColor = 'lightGray',
    multiline = false,
    icon,
    onHover,
    hideBorder = false,
    onKeyDown,
    style,
    min,
    max,
    isPassword = false,
}) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark
    const [showPassword, setShowPassword] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <label className="mb-1.5 text-base text-blue flex items-center">
                    {label}
                    {icon && (
                        <span
                            className="ml-2 cursor-pointer"
                            onMouseEnter={() => onHover?.(true)}
                            onMouseLeave={() => onHover?.(false)}
                        >
                            {icon}
                        </span>
                    )}
                </label>
            )}
            <div className="relative w-full">
                {multiline ? (
                    <textarea
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        disabled={disabled}
                        className={`${hideBorder ? '' : errorMessage ? 'shadow-redCustomShadow' : 'focus:shadow-yellowCustomShadow'} shadow-blueCustomShadow bg-${lightColor} dark:bg-${darkColor} rounded-md px-3 py-1.5 outline-none disabled:cursor-not-allowed text-veryDarkGray dark:text-veryLightGray ${
                            errorMessage ? 'border-red' : 'border-darkGray'
                        } w-full`}
                        rows={4}
                        style={style}
                    />
                ) : (
                    <input
                        type={
                            isPassword
                                ? showPassword
                                    ? 'text'
                                    : 'password'
                                : type
                        }
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        disabled={disabled}
                        className={`${
                            hideBorder
                                ? ''
                                : errorMessage
                                  ? 'shadow-redCustomShadow'
                                  : 'focus:shadow-yellowCustomShadow shadow-blueCustomShadow'
                        } bg-${lightColor} dark:bg-${darkColor} rounded-md px-3 py-1.5 outline-none disabled:cursor-not-allowed text-veryDarkGray dark:text-veryLightGray w-full`}
                        onKeyDown={onKeyDown}
                        style={style}
                        min={min}
                        max={max}
                    />
                )}
                {isPassword && (
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue dark:text-lightGray hover:text-yellow dark:hover:text-yellow transition-colors duration-100 ease-in"
                    >
                        {showPassword ? (
                            <IoEyeOff className="w-5 h-5" />
                        ) : (
                            <IoEye className="w-5 h-5" />
                        )}
                    </button>
                )}
            </div>

            {/* Reserved space for avoiding movement of the UI on error render */}
            <div className="min-h-6">
                {errorMessage && (
                    <span className="text-red text-sm">{errorMessage}</span>
                )}
            </div>
        </div>
    )
}

export default CustomInputField
