import Card from '../atoms/Card'
import { ArticleItem } from '../../api/openapi'

export const ArticleCard = ({
    article,
    onClick,
    keywords,
    className, // Accept className as a prop
}: {
    article: ArticleItem
    onClick?: () => void
    keywords: string[]
    className?: string
}) => {
    const highlightKeywords = (text: string) => {
        if (!keywords.length) return text
        const escapedKeywords = keywords.map((kw) =>
            kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        )
        const regex = new RegExp(`(${escapedKeywords.join('|')})`, 'gi')
        return text.split(regex).map((part, index) =>
            escapedKeywords.some(
                (kw) => kw.toLowerCase() === part.toLowerCase()
            ) ? (
                <span key={index} className="bg-yellow bg-opacity-40">
                    {part}
                </span>
            ) : (
                part
            )
        )
    }

    return (
        <Card>
            <div
                className={`flex flex-col gap-4 px-3 py-2 text-veryDarkGray dark:text-veryLightGray max-h-[66vh] ${className || ''}`}
            >
                <div className="text-base font-semibold text-left">
                    {article.title}
                </div>

                <span>
                    {[
                        { key: 'Author:', value: article.shortCitation! },
                        { key: 'DOI:', value: article.doi },
                        { key: 'PMCID:', value: article.pmcId },
                        { key: 'Journal (ISSN):', value: article.journalIssn },
                    ].map((tag, index) => (
                        <span key={index} className="text-sm">
                            <span className="font-bold">{tag.key}</span>{' '}
                            {tag.value} {index < 3 && ' | '}
                        </span>
                    ))}
                </span>

                <div className="text-sm">
                    <div className="font-bold">Abstract:</div>
                    <div>{highlightKeywords(article._abstract || '')}</div>
                </div>
            </div>
        </Card>
    )
}
