/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcRoutersArticleInfoItemV2
 */
export interface SrcRoutersArticleInfoItemV2 {
    /**
     * Pubmed ID
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    pmid?: string;
    /**
     * Title of the article
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    title?: string;
    /**
     * List of target population of the article
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    population?: string;
    /**
     * List of target intervention of the article
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    intervention?: string;
    /**
     * List of target outcome of the article
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    outcome?: string;
    /**
     * List of conclusions of the article
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    conclusions?: string;
    /**
     * Relevance level (high, medium or low)
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    relevance?: string;
    /**
     * Whether it is a SR ('yes') or no ('no')
     * @type {string}
     * @memberof SrcRoutersArticleInfoItemV2
     */
    isReview?: string;
}

/**
 * Check if a given object implements the SrcRoutersArticleInfoItemV2 interface.
 */
export function instanceOfSrcRoutersArticleInfoItemV2(value: object): value is SrcRoutersArticleInfoItemV2 {
    return true;
}

export function SrcRoutersArticleInfoItemV2FromJSON(json: any): SrcRoutersArticleInfoItemV2 {
    return SrcRoutersArticleInfoItemV2FromJSONTyped(json, false);
}

export function SrcRoutersArticleInfoItemV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersArticleInfoItemV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'pmid': json['pmid'] == null ? undefined : json['pmid'],
        'title': json['title'] == null ? undefined : json['title'],
        'population': json['population'] == null ? undefined : json['population'],
        'intervention': json['intervention'] == null ? undefined : json['intervention'],
        'outcome': json['outcome'] == null ? undefined : json['outcome'],
        'conclusions': json['conclusions'] == null ? undefined : json['conclusions'],
        'relevance': json['relevance'] == null ? undefined : json['relevance'],
        'isReview': json['is_review'] == null ? undefined : json['is_review'],
    };
}

export function SrcRoutersArticleInfoItemV2ToJSON(value?: SrcRoutersArticleInfoItemV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pmid': value['pmid'],
        'title': value['title'],
        'population': value['population'],
        'intervention': value['intervention'],
        'outcome': value['outcome'],
        'conclusions': value['conclusions'],
        'relevance': value['relevance'],
        'is_review': value['isReview'],
    };
}

