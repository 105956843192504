import { ProjectOutput } from '../../api/openapi'
import { IconButton } from '../buttons/IconButton'
import { IoMdSettings } from 'react-icons/io'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import { IoKeySharp } from 'react-icons/io5'
import { MdOutlineHandshake } from 'react-icons/md'
import { LoadingSpinner } from '../atoms/LoadingSpinner'

/**
 * ProjectCard component displays project information including title, description, tags,
 * owner, and collaborators. It supports loading states and different views for owners vs collaborators.
 */
export const ProjectCard = ({
    project,
    onSettingsClick,
    onProjectClick,
    isLoading,
}: {
    project: ProjectOutput
    onSettingsClick: () => void
    onProjectClick: () => void
    isLoading?: boolean
}) => {
    const isOwner =
        project.owner.toLowerCase() ===
        LocalStorageUtil.getEmail()!.toLowerCase()

    return (
        <div className={`flex flex-col items-center gap-2`}>
            {/* Role indicator */}
            {isOwner ? (
                <div className={`flex flex-row items-center gap-1 text-yellow`}>
                    <div className={`font-bold`}>Project Owner</div>
                    <IoKeySharp />
                </div>
            ) : (
                <div
                    className={`flex flex-row items-center gap-1 text-darkGray dark:text-veryLightGray`}
                >
                    <div className={`font-bold`}>Project Collaborator</div>
                    <MdOutlineHandshake />
                </div>
            )}

            <div
                onClick={isLoading ? () => {} : onProjectClick}
                className={`relative flex flex-col justify-between gap-2 bg-lightGray dark:bg-darkBlue project-card-size h-full px-5 pt-4 pb-3 rounded-md
                shadow-blueCustomShadow ${isLoading ? 'cursor-not-allowed shadow-yellowCustomShadow' : 'cursor-pointer'} hover:shadow-yellowCustomShadow`}
            >
                {/* Loading Overlay */}
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md z-10">
                        <div className="flex justify-center p-5">
                            <LoadingSpinner color="white" />
                        </div>
                    </div>
                )}

                <div
                    className={`flex flex-col gap-2 px-5 ${isLoading ? 'blur-sm' : ''}`}
                >
                    <div
                        className={`text-blue text-lg font-bold text-center break-words`}
                    >
                        {project.title.length > 150
                            ? project.title.slice(0, 150) + '...'
                            : project.title}
                    </div>

                    {/*horizontal line*/}
                    <div className={`border-b border-yellow`} />

                    <div
                        className={`flex flex-row gap-x-6 text-sm text-yellow dark:text-yellow`}
                    >
                        {project.tags.map((tag) => (
                            <div key={tag}>{tag}</div>
                        ))}
                    </div>

                    <div
                        className={`text-md text-veryDarkGray dark:text-veryLightGray break-words`}
                    >
                        {project.description.length > 180
                            ? project.description.slice(0, 180) + '...'
                            : project.description}
                    </div>
                </div>

                <div
                    className={`flex flex-row justify-between items-center ${isLoading ? 'blur-sm' : ''}`}
                >
                    <div
                        className={`flex flex-col text-sm text-veryDarkGray dark:text-veryLightGray`}
                    >
                        <div>
                            <span className={`font-bold`}>Owner: </span>{' '}
                            <span>{project.owner}</span>
                        </div>
                        {/* Collaborators section with conditional rendering */}
                        <div className="truncate inline-block max-w-xs">
                            <span className="font-bold">Collaborators: </span>
                            {project.collaborators.length > 0 ? (
                                <span
                                    className="ml-1"
                                    title={project.collaborators.join(', ')}
                                >
                                    {project.collaborators.join(', ')}
                                </span>
                            ) : (
                                <span className="text-gray-500 italic">
                                    No collaborators yet
                                </span>
                            )}
                        </div>
                    </div>

                    {/* Project settings button - only visible to owner */}
                    {isOwner && (
                        <IconButton
                            disabled={isLoading}
                            onClick={(event) => {
                                event.stopPropagation()
                                onSettingsClick()
                            }}
                            icon={<IoMdSettings className={`w-6 h-6`} />}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
