/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcModelsSchemasClinicalTrial
 */
export interface SrcModelsSchemasClinicalTrial {
    /**
     * Clinical Trial ID
     * @type {string}
     * @memberof SrcModelsSchemasClinicalTrial
     */
    nctNumber: string;
    /**
     * Title
     * @type {string}
     * @memberof SrcModelsSchemasClinicalTrial
     */
    studyTitle: string;
    /**
     * Study status
     * @type {string}
     * @memberof SrcModelsSchemasClinicalTrial
     */
    studyStatus: string;
    /**
     * Summary
     * @type {string}
     * @memberof SrcModelsSchemasClinicalTrial
     */
    briefSummary: string;
}

/**
 * Check if a given object implements the SrcModelsSchemasClinicalTrial interface.
 */
export function instanceOfSrcModelsSchemasClinicalTrial(value: object): value is SrcModelsSchemasClinicalTrial {
    if (!('nctNumber' in value) || value['nctNumber'] === undefined) return false;
    if (!('studyTitle' in value) || value['studyTitle'] === undefined) return false;
    if (!('studyStatus' in value) || value['studyStatus'] === undefined) return false;
    if (!('briefSummary' in value) || value['briefSummary'] === undefined) return false;
    return true;
}

export function SrcModelsSchemasClinicalTrialFromJSON(json: any): SrcModelsSchemasClinicalTrial {
    return SrcModelsSchemasClinicalTrialFromJSONTyped(json, false);
}

export function SrcModelsSchemasClinicalTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasClinicalTrial {
    if (json == null) {
        return json;
    }
    return {
        
        'nctNumber': json['nct_number'],
        'studyTitle': json['study_title'],
        'studyStatus': json['study_status'],
        'briefSummary': json['brief_summary'],
    };
}

export function SrcModelsSchemasClinicalTrialToJSON(value?: SrcModelsSchemasClinicalTrial | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nct_number': value['nctNumber'],
        'study_title': value['studyTitle'],
        'study_status': value['studyStatus'],
        'brief_summary': value['briefSummary'],
    };
}

