import apiClient from '../api/apiClient'
import type {
    ListIdeaHistoryItems,
    ListSearchHistoryItems,
    ListTextHistoryItems, SrcModelsSchemasUserHistory,
} from '../api/openapi'

export const getHistory = (projectId: string): Promise<SrcModelsSchemasUserHistory> => {
    return apiClient.fetchUserHistoryV3FetchHistoryPost({
        fetchHistory: {
            projectId,
        },
    })
}

// DEPRECATED
export const getLiteratureSearchHistory = (
    projectId: string
): Promise<ListSearchHistoryItems> => {
    return apiClient.fetchUserSearchHistoryV2FetchSearchHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}

// DEPRECATED
export const getIdeasHistory = (
    projectId: string
): Promise<ListIdeaHistoryItems> => {
    return apiClient.fetchUserIdeaHistoryV2FetchIdeaHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}

// DEPRECATED
export const getProtocolHistory = (
    projectId: string
): Promise<ListTextHistoryItems> => {
    return apiClient.fetchUserTextHistoryV2FetchTextHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}
