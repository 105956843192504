import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
    disableAnimation?: boolean
}

const Modal = ({
    isOpen,
    onClose,
    children,
    disableAnimation = false,
}: ModalProps) => {
    const [isClosing, setIsClosing] = useState(false)

    // UseEffect para manejar el evento de Escape y la prevención del overflow
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose()
            }
        }

        if (isOpen) {
            // Añadir el evento de Escape
            document.addEventListener('keydown', handleKeyDown)
            // Deshabilitar el scroll del body
            document.body.style.overflow = 'hidden'
        } else {
            // Eliminar el evento de Escape cuando el modal no está abierto
            document.removeEventListener('keydown', handleKeyDown)
            // Restablecer el scroll del body cuando el modal se cierra
            document.body.style.overflow = ''
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.body.style.overflow = ''
        }
    }, [isOpen, onClose])

    // Verificar el click fuera del modal para cerrarlo
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        // Si el click está fuera del contenido del modal, cerrar el modal
        if (event.target === event.currentTarget) {
            handleClose()
        }
    }

    const handleClose = () => {
        if (disableAnimation) {
            onClose()
            return
        }
        setIsClosing(true)
        // Esperar a que termine la animación antes de cerrar
        setTimeout(() => {
            setIsClosing(false)
            onClose()
        }, 300) // 300ms es la duración de la animación
    }

    if (!isOpen && !isClosing) {
        return null
    }

    // Usamos React Portal para montar el modal en el body
    return ReactDOM.createPortal(
        <div
            className={`fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 ${
                disableAnimation
                    ? ''
                    : isClosing
                      ? 'animate-fadeOut'
                      : 'animate-fadeIn'
            }`}
            onClick={handleClickOutside}
        >
            <div
                className={`w-auto max-w-[90%] max-h-full relative z-10 ${
                    disableAnimation
                        ? ''
                        : isClosing
                          ? 'animate-slideOut'
                          : 'animate-slideIn'
                }`}
            >
                {/* Contenido del modal */}
                {children}
            </div>
        </div>,
        document.body // Aseguramos que el modal se monte en el body
    )
}

export default Modal
