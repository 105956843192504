/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcRoutersSearchTermResponse
 */
export interface SrcRoutersSearchTermResponse {
    /**
     * 
     * @type {number}
     * @memberof SrcRoutersSearchTermResponse
     */
    statusCode: number;
    /**
     * ID of the search generated
     * @type {string}
     * @memberof SrcRoutersSearchTermResponse
     */
    searchId: string;
    /**
     * Number of articles found
     * @type {number}
     * @memberof SrcRoutersSearchTermResponse
     */
    numArticles: number;
    /**
     * Number of reviews found
     * @type {number}
     * @memberof SrcRoutersSearchTermResponse
     */
    numReviews: number;
    /**
     * List of article IDs
     * @type {Array<any>}
     * @memberof SrcRoutersSearchTermResponse
     */
    articleIds: Array<any>;
    /**
     * List of review IDs
     * @type {Array<any>}
     * @memberof SrcRoutersSearchTermResponse
     */
    reviewIds: Array<any>;
    /**
     * Query executed
     * @type {string}
     * @memberof SrcRoutersSearchTermResponse
     */
    query: string;
    /**
     * List of found articles and their information associated (ENCODED AS STRING)
     * @type {string}
     * @memberof SrcRoutersSearchTermResponse
     */
    articles: string;
    /**
     * List of found reviews and their information associated (ENCODED AS STRING)
     * @type {string}
     * @memberof SrcRoutersSearchTermResponse
     */
    reviews: string;
}

/**
 * Check if a given object implements the SrcRoutersSearchTermResponse interface.
 */
export function instanceOfSrcRoutersSearchTermResponse(value: object): value is SrcRoutersSearchTermResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    if (!('numArticles' in value) || value['numArticles'] === undefined) return false;
    if (!('numReviews' in value) || value['numReviews'] === undefined) return false;
    if (!('articleIds' in value) || value['articleIds'] === undefined) return false;
    if (!('reviewIds' in value) || value['reviewIds'] === undefined) return false;
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    return true;
}

export function SrcRoutersSearchTermResponseFromJSON(json: any): SrcRoutersSearchTermResponse {
    return SrcRoutersSearchTermResponseFromJSONTyped(json, false);
}

export function SrcRoutersSearchTermResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersSearchTermResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'searchId': json['search_id'],
        'numArticles': json['num_articles'],
        'numReviews': json['num_reviews'],
        'articleIds': json['article_ids'],
        'reviewIds': json['review_ids'],
        'query': json['query'],
        'articles': json['articles'],
        'reviews': json['reviews'],
    };
}

export function SrcRoutersSearchTermResponseToJSON(value?: SrcRoutersSearchTermResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'search_id': value['searchId'],
        'num_articles': value['numArticles'],
        'num_reviews': value['numReviews'],
        'article_ids': value['articleIds'],
        'review_ids': value['reviewIds'],
        'query': value['query'],
        'articles': value['articles'],
        'reviews': value['reviews'],
    };
}

