/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcModelsSchemasUserHistoryItem
 */
export interface SrcModelsSchemasUserHistoryItem {
    /**
     * Timestamp of the history entry
     * @type {Date}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    timestamp: Date;
    /**
     * Search ID
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    searchId: string;
    /**
     * Original user's query
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    search: string;
    /**
     * Processed query - search string
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    query: string;
    /**
     * 
     * @type {number}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    numArticles: number;
    /**
     * 
     * @type {number}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    numReviews: number;
    /**
     * ID of the ideas set
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    ideasSetId?: string;
    /**
     * 
     * @type {number}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    numIdeas?: number;
    /**
     * Idea ID associated to the protocol
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    ideaId?: string;
    /**
     * Full-text ID
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    protocolId?: string;
    /**
     * Title of the protocol
     * @type {string}
     * @memberof SrcModelsSchemasUserHistoryItem
     */
    protocolTitle?: string;
}

/**
 * Check if a given object implements the SrcModelsSchemasUserHistoryItem interface.
 */
export function instanceOfSrcModelsSchemasUserHistoryItem(value: object): value is SrcModelsSchemasUserHistoryItem {
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    if (!('search' in value) || value['search'] === undefined) return false;
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('numArticles' in value) || value['numArticles'] === undefined) return false;
    if (!('numReviews' in value) || value['numReviews'] === undefined) return false;
    return true;
}

export function SrcModelsSchemasUserHistoryItemFromJSON(json: any): SrcModelsSchemasUserHistoryItem {
    return SrcModelsSchemasUserHistoryItemFromJSONTyped(json, false);
}

export function SrcModelsSchemasUserHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasUserHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'timestamp': (new Date(json['timestamp'])),
        'searchId': json['search_id'],
        'search': json['search'],
        'query': json['query'],
        'numArticles': json['num_articles'],
        'numReviews': json['num_reviews'],
        'ideasSetId': json['ideas_set_id'] == null ? undefined : json['ideas_set_id'],
        'numIdeas': json['num_ideas'] == null ? undefined : json['num_ideas'],
        'ideaId': json['idea_id'] == null ? undefined : json['idea_id'],
        'protocolId': json['protocol_id'] == null ? undefined : json['protocol_id'],
        'protocolTitle': json['protocol_title'] == null ? undefined : json['protocol_title'],
    };
}

export function SrcModelsSchemasUserHistoryItemToJSON(value?: SrcModelsSchemasUserHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timestamp': ((value['timestamp']).toISOString()),
        'search_id': value['searchId'],
        'search': value['search'],
        'query': value['query'],
        'num_articles': value['numArticles'],
        'num_reviews': value['numReviews'],
        'ideas_set_id': value['ideasSetId'],
        'num_ideas': value['numIdeas'],
        'idea_id': value['ideaId'],
        'protocol_id': value['protocolId'],
        'protocol_title': value['protocolTitle'],
    };
}

