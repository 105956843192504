/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcRoutersIdeaItem } from './SrcRoutersIdeaItem';
import {
    SrcRoutersIdeaItemFromJSON,
    SrcRoutersIdeaItemFromJSONTyped,
    SrcRoutersIdeaItemToJSON,
} from './SrcRoutersIdeaItem';
import type { SrcRoutersArticleInfoItemV2 } from './SrcRoutersArticleInfoItemV2';
import {
    SrcRoutersArticleInfoItemV2FromJSON,
    SrcRoutersArticleInfoItemV2FromJSONTyped,
    SrcRoutersArticleInfoItemV2ToJSON,
} from './SrcRoutersArticleInfoItemV2';
import type { SrcRoutersClinicalTrial } from './SrcRoutersClinicalTrial';
import {
    SrcRoutersClinicalTrialFromJSON,
    SrcRoutersClinicalTrialFromJSONTyped,
    SrcRoutersClinicalTrialToJSON,
} from './SrcRoutersClinicalTrial';

/**
 * 
 * @export
 * @interface QuestionsResponseV2
 */
export interface QuestionsResponseV2 {
    /**
     * 
     * @type {number}
     * @memberof QuestionsResponseV2
     */
    statusCode: number;
    /**
     * ID of the set of ideas generated
     * @type {string}
     * @memberof QuestionsResponseV2
     */
    ideasSetId: string;
    /**
     * List of genereated ideas
     * @type {Array<SrcRoutersIdeaItem>}
     * @memberof QuestionsResponseV2
     */
    ideas: Array<SrcRoutersIdeaItem>;
    /**
     * List of articles, and their information, that have been used for idea generation
     * @type {Array<SrcRoutersArticleInfoItemV2>}
     * @memberof QuestionsResponseV2
     */
    articles: Array<SrcRoutersArticleInfoItemV2>;
    /**
     * List of clinical trials relevant to the ideas set
     * @type {Array<SrcRoutersClinicalTrial>}
     * @memberof QuestionsResponseV2
     */
    clinicalTrials: Array<SrcRoutersClinicalTrial>;
}

/**
 * Check if a given object implements the QuestionsResponseV2 interface.
 */
export function instanceOfQuestionsResponseV2(value: object): value is QuestionsResponseV2 {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('ideasSetId' in value) || value['ideasSetId'] === undefined) return false;
    if (!('ideas' in value) || value['ideas'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('clinicalTrials' in value) || value['clinicalTrials'] === undefined) return false;
    return true;
}

export function QuestionsResponseV2FromJSON(json: any): QuestionsResponseV2 {
    return QuestionsResponseV2FromJSONTyped(json, false);
}

export function QuestionsResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionsResponseV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'ideasSetId': json['ideas_set_id'],
        'ideas': ((json['ideas'] as Array<any>).map(SrcRoutersIdeaItemFromJSON)),
        'articles': ((json['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2FromJSON)),
        'clinicalTrials': ((json['clinical_trials'] as Array<any>).map(SrcRoutersClinicalTrialFromJSON)),
    };
}

export function QuestionsResponseV2ToJSON(value?: QuestionsResponseV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'ideas_set_id': value['ideasSetId'],
        'ideas': ((value['ideas'] as Array<any>).map(SrcRoutersIdeaItemToJSON)),
        'articles': ((value['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2ToJSON)),
        'clinical_trials': ((value['clinicalTrials'] as Array<any>).map(SrcRoutersClinicalTrialToJSON)),
    };
}

