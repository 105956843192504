import React from 'react'
import Modal from '../atoms/Modal'
import Card from '../atoms/Card'
import { CustomButton } from '../buttons/CustomButton'
import { SrcModelsSchemasUserProfileOutput } from '../../api/openapi'

interface SubscriptionModalProps {
    isOpen: boolean
    onClose: () => void
    userProfile: SrcModelsSchemasUserProfileOutput
}

export const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
    isOpen,
    onClose,
    userProfile,
}) => {
    const handlePurchaseClick = () => {
        const stripeLink = userProfile.hasSubscription
            ? 'https://buy.stripe.com/test_9AQ172d7tfm0e8o3ck'
            : 'https://buy.stripe.com/test_fZe8zu6J5ddS0hy4gn'
        window.open(stripeLink, '_blank')
    }

    // Calculate percentage for progress bars
    const calculatePercentage = (used: number, limit: number) => {
        if (limit === 0) return 0
        return Math.min(Math.round((used / limit) * 100), 100)
    }

    // Get subscription data with defaults if not available
    const subscription = userProfile.subscription || {
        name: userProfile.hasSubscription ? 'Premium' : 'Basic',
        search: { used: 0, limit: 0 },
        ideas: { used: 0, limit: 0 },
        protocols: { used: 0, limit: 0 },
        collaborators_limit: 0,
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Card>
                <div className="flex flex-col gap-4 p-6 min-w-[500px]">
                    <h2 className="text-2xl font-bold text-yellow">
                        Subscription Details
                    </h2>

                    <div className="flex flex-col gap-2">
                        <div className="text-veryDarkGray dark:text-veryLightGray">
                            <span className="font-semibold">
                                Subscription Status:{' '}
                            </span>
                            {subscription.name}
                        </div>

                        <div className="text-veryDarkGray dark:text-veryLightGray">
                            <span className="font-semibold">
                                Available Protocols:{' '}
                            </span>
                            {userProfile.numAvailableProtocols}
                        </div>
                    </div>

                    <div className="mt-2 border-t border-yellow pt-4">
                        <div className="flex flex-col gap-4">
                            {/* Search Usage */}
                            <div>
                                <div className="flex justify-between mb-1">
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        Search Queries
                                    </span>
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        {subscription.search.used} /{' '}
                                        {subscription.search.limit}
                                    </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div
                                        className="bg-yellow h-2.5 rounded-full"
                                        style={{
                                            width: `${calculatePercentage(subscription.search.used, subscription.search.limit)}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>

                            {/* Ideas Usage */}
                            <div>
                                <div className="flex justify-between mb-1">
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        Ideas Generated
                                    </span>
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        {subscription.ideas.used} /{' '}
                                        {subscription.ideas.limit}
                                    </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div
                                        className="bg-yellow h-2.5 rounded-full"
                                        style={{
                                            width: `${calculatePercentage(subscription.ideas.used, subscription.ideas.limit)}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>

                            {/* Protocols Usage */}
                            <div>
                                <div className="flex justify-between mb-1">
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        Protocols Generated
                                    </span>
                                    <span className="text-veryDarkGray dark:text-veryLightGray">
                                        {subscription.protocols.used} /{' '}
                                        {subscription.protocols.limit}
                                    </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2.5">
                                    <div
                                        className="bg-yellow h-2.5 rounded-full"
                                        style={{
                                            width: `${calculatePercentage(subscription.protocols.used, subscription.protocols.limit)}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <CustomButton
                            onClick={handlePurchaseClick}
                            text="Purchase More Protocols"
                            backgroundColor="blue"
                            textColor="white"
                        />
                    </div>
                </div>
            </Card>
        </Modal>
    )
}
