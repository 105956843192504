/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcModelsSchemasUserHistoryItem } from './SrcModelsSchemasUserHistoryItem';
import {
    SrcModelsSchemasUserHistoryItemFromJSON,
    SrcModelsSchemasUserHistoryItemFromJSONTyped,
    SrcModelsSchemasUserHistoryItemToJSON,
} from './SrcModelsSchemasUserHistoryItem';

/**
 * 
 * @export
 * @interface SrcModelsSchemasUserHistory
 */
export interface SrcModelsSchemasUserHistory {
    /**
     * List of history entries
     * @type {Array<SrcModelsSchemasUserHistoryItem>}
     * @memberof SrcModelsSchemasUserHistory
     */
    items?: Array<SrcModelsSchemasUserHistoryItem>;
}

/**
 * Check if a given object implements the SrcModelsSchemasUserHistory interface.
 */
export function instanceOfSrcModelsSchemasUserHistory(value: object): value is SrcModelsSchemasUserHistory {
    return true;
}

export function SrcModelsSchemasUserHistoryFromJSON(json: any): SrcModelsSchemasUserHistory {
    return SrcModelsSchemasUserHistoryFromJSONTyped(json, false);
}

export function SrcModelsSchemasUserHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasUserHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(SrcModelsSchemasUserHistoryItemFromJSON)),
    };
}

export function SrcModelsSchemasUserHistoryToJSON(value?: SrcModelsSchemasUserHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(SrcModelsSchemasUserHistoryItemToJSON)),
    };
}

