import ToggleButtonGroup from '../../components/atoms/ToggleButtonGroup'
import { ArticleCard } from '../../components/molecules/ArticleCard'
import { ArticlesListCard } from '../../components/molecules/ArticlesListCard'
import { CustomButton } from '../../components/buttons/CustomButton'
import {
    addArticleToProject,
    getArticlesAndReviews,
} from '../../middleware/searchService'
import React, { useEffect, useState } from 'react'
import { ArticleItem, StageEnum } from '../../api/openapi'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowToggleButton } from '../../components/atoms/ArrowToggleButton'
import CustomInputField from '../../components/atoms/CustomInputField'
import Card from '../../components/atoms/Card'
import { generateIdeas } from '../../middleware/ideasService'
import CommentSection from '../../features/comment-section/CommentSection'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { useData } from '../../context/WizardContext'
import { IoSearch } from 'react-icons/io5'
import { colorMapping } from '../../utils/colorMappings'
import { InfoModal } from '../../features/info-modal/InfoModal'
import { InfoTextResultsExtraction } from '../../components/atoms/InfoTexts'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import { Subtitle } from '../../components/atoms/Subtitle'
import useBeforeUnload from '../../hooks/useBeforeUnload'

export const ResultsExtraction = () => {
    useBeforeUnload()
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { isOwner, loadingGenerateIdeas, setLoadingGenerateIdeasContext } =
        useData()
    const navigate = useNavigate()
    const lightColor = colorMapping['white']?.light
    const darkColor = colorMapping['white']?.dark

    const [search, setSearch] = useState<string>('')
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [presentedArticles, setPresentedArticles] = useState<ArticleItem[]>(
        []
    )
    const [selected, setSelected] = useState<string>('Articles')
    const [selectedArticle, setSelectedArticle] = useState<ArticleItem | null>(
        null
    )
    const [addArticle, setAddArticle] = useState(false)
    const [addedArticles, setAddedArticles] = useState<string[]>([])
    const [modalOpen, setModalOpen] = useState(false)

    // Fetch articles and reviews
    const {
        fetchData: getArticlesAndReviewsCall,
        data,
        loading: loadingGetArticles,
        error: errorGetArticles,
        clearData,
    } = useFetchOnDemand(
        () => {
            return getArticlesAndReviews(projectId!, searchId!)
        },
        (data) => {
            setPresentedArticles(data?.articlesRetrieved || [])
            return data
        },
        StageEnum.Search
    )

    // Add articles to project
    const { fetchData: addArticleCall, loading: loadingAddArticles } =
        useFetchOnDemand(
            () => {
                return addArticleToProject(projectId!, searchId!, addedArticles)
            },
            undefined,
            StageEnum.Search
        )

    // Generate ideas for the project
    const { fetchData: generateIdeasCall } = useFetchOnDemand(
        () => {
            return generateIdeas(projectId!, searchId!)
        },
        undefined,
        StageEnum.Ideas
    )

    useEffect(() => {
        clearData()
        getArticlesAndReviewsCall()
    }, [searchId])

    // Filter the articles/reviews based on search criteria
    useEffect(() => {
        if (data?.articlesRetrieved && data?.reviewsRetrieved) {
            if (search === '') {
                setPresentedArticles(
                    selected.includes('Articles')
                        ? data.articlesRetrieved
                        : data.reviewsRetrieved
                )
            } else {
                setPresentedArticles(
                    selected.includes('Articles')
                        ? data.articlesRetrieved.filter(
                              (article) =>
                                  article.title
                                      ?.toLowerCase()
                                      .includes(search.toLowerCase()) ||
                                  article._abstract
                                      ?.toLowerCase()
                                      .includes(search.toLowerCase())
                          )
                        : data.reviewsRetrieved.filter(
                              (article) =>
                                  article.title
                                      ?.toLowerCase()
                                      .includes(search.toLowerCase()) ||
                                  article._abstract
                                      ?.toLowerCase()
                                      .includes(search.toLowerCase())
                          )
                )
            }
        }
    }, [search])

    // Toggle between articles and reviews
    const handleSearchToggle = (selected: string) => {
        setSelected(selected)
        setPresentedArticles(
            selected.includes('Articles')
                ? data?.articlesRetrieved || []
                : data?.reviewsRetrieved || []
        )
    }

    // Handle the selection of an article
    const handleArticleClick = (doi: string) => {
        if (selected.includes('Articles')) {
            setSelectedArticle(
                data?.articlesRetrieved!.find(
                    (article) => article.doi === doi
                ) || null
            )
        } else {
            setSelectedArticle(
                data?.reviewsRetrieved!.find(
                    (article) => article.doi === doi
                ) || null
            )
        }
    }

    // Add articles to project after validation
    const handleAddArticle = () => {
        addArticleCall().then(() => {
            setAddArticle(false)
            setAddedArticles([])
        })
    }

    // Navigate to the next page and generate ideas
    const handleNext = () => {
        setLoadingGenerateIdeasContext(true)
        setModalOpen(true)
        setModalOpen(true)
        generateIdeasCall()
            .then((response) => {
                if (response && response.ideasSetId) {
                    navigate(
                        '/wizard/' +
                            projectId +
                            '/ideas-generation/' +
                            searchId +
                            '/' +
                            response!.ideasSetId
                    )
                }
            })
            .finally(() => {
                setLoadingGenerateIdeasContext(false)
            })
    }

    // Scroll to the bottom of the page
    function handleScroll() {
        setTimeout(() => {
            window.scrollTo({
                top:
                    document.documentElement.scrollHeight ||
                    document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            })
        }, 0)
    }

    return (
        <div className="flex flex-col items-stretch gap-3 w-full px-8 wizard-width">
            {!loadingGetArticles &&
                !errorGetArticles &&
                data?.articlesRetrieved &&
                data?.reviewsRetrieved && (
                    <div className={'flex flex-row items-start gap-8 relative'}>
                        <div className="grid grid-cols-2 justify-between gap-4 w-full">
                            <div className={`flex flex-col gap-4 w-full`}>
                                <div
                                    className={`flex flex-row justify-between items-center`}
                                >
                                    <div className={`flex items-center`}>
                                        <ToggleButtonGroup
                                            options={[
                                                `${data?.articlesRetrieved.length} Articles`,
                                                `${data?.reviewsRetrieved.length} Reviews`,
                                            ]}
                                            onToggle={handleSearchToggle}
                                        />
                                    </div>

                                    <div
                                        className={`flex flex-row items-center shadow-blueCustomShadow bg-${lightColor} dark:bg-${darkColor} rounded-md min-h-[37.6px] px-2 ml-1 gap-1`}
                                    >
                                        <IoSearch
                                            className={`text-blue cursor-pointer w-6 h-6`}
                                            onClick={() => {
                                                setShowSearch(!showSearch)
                                                setSearch('')
                                            }}
                                        />
                                        <CustomInputField
                                            value={search}
                                            placeholder={'Search...'}
                                            hideBorder={true}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            className={`${showSearch ? 'block' : 'hidden'}`}
                                            backgroundColor={'white'}
                                        />
                                    </div>
                                </div>

                                <ArticlesListCard
                                    backgroundColor={'white'}
                                    onClick={(doi) => {
                                        handleArticleClick(doi)
                                    }}
                                    articles={presentedArticles}
                                    disableSelect={false}
                                />
                            </div>

                            {selectedArticle && (
                                <div className="flex flex-col gap-4 h-full flex-grow">
                                    <div className="flex flex-col flex-grow h-full">
                                        <ArticleCard
                                            keywords={data.keywords || []}
                                            article={selectedArticle}
                                            className="flex flex-col h-full overflow-y-auto min-h-[65vh]"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                            infoText={<InfoTextResultsExtraction />}
                        />
                    </div>
                )}

            {errorGetArticles && (
                <div className="flex gap-4 justify-center">
                    {errorGetArticles}
                </div>
            )}
            {loadingGetArticles && (
                <div className="flex justify-center p-5">
                    <LoadingSpinner color="#FFAC27" />
                </div>
            )}

            {!loadingGetArticles && !errorGetArticles && isOwner && (
                <div className={`flex flex-col justify-end gap-4`}>
                    <ArrowToggleButton
                        onClick={handleScroll}
                        value={addArticle}
                        toggle={() => setAddArticle(!addArticle)}
                        label={'Add article'}
                    />
                    {addArticle && (
                        <Card>
                            <div className={`flex flex-col gap-4`}>
                                <div
                                    className={`text-sm text-darkGray font-normal`}
                                >
                                    If you want to add more articles or reviews
                                    to the pool of results, introduce the
                                    corresponding DOI and click Add article.
                                </div>
                                <CustomInputField
                                    value={addedArticles.join(',')}
                                    placeholder={'Enter DOIs here'}
                                    onChange={(e) =>
                                        setAddedArticles(
                                            e.target.value.split(',')
                                        )
                                    }
                                />
                                <div className={`flex gap-4 justify-center`}>
                                    <CustomButton
                                        text={'Add article'}
                                        onClick={() => handleAddArticle()}
                                        loading={loadingAddArticles}
                                    />
                                </div>
                            </div>
                        </Card>
                    )}
                </div>
            )}

            {!loadingGetArticles && !errorGetArticles && isOwner && (
                <div className="flex gap-4 justify-end">
                    <CustomButton
                        text={'Generate ideas!'}
                        onClick={handleNext}
                        loading={loadingGenerateIdeas}
                    />
                </div>
            )}

            <InfoModal
                title={'This process may take a few minutes...'}
                text={
                    <div className="flex flex-col items-center">
                        <Subtitle text="Generating your idea set, we will send you an email once it's ready." />
                        <div className="pb-5 pt-5">
                            <LoadingSpinner color="#FFAC27" />
                        </div>
                    </div>
                }
                isOpen={modalOpen && loadingGenerateIdeas}
                closeAction={() => setModalOpen(false)}
            />
            <CommentSection projectId={projectId!} stage={StageEnum.Search} />
        </div>
    )
}
