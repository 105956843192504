import { useEffect, useRef, useState } from 'react'
import CommentCard from './CommentCard'
import CustomInputField from '../../components/atoms/CustomInputField'
import { getMessages, sendMessage } from '../../middleware/messagesService'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { StageEnum } from '../../api/openapi'
import { IconButton } from '../../components/buttons/IconButton'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import { IoMdSend } from 'react-icons/io'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'

interface CommentSectionProps {
    projectId: string
    stage: StageEnum
}

const CommentSection = ({ projectId, stage }: CommentSectionProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [comment, setComment] = useState('')
    const [sending, setSending] = useState(false) // State for sending status
    const [errorMessage, setErrorMessage] = useState<string | null>(null) // State to hold error message
    const commentSectionRef = useRef<HTMLDivElement | null>(null)

    const {
        fetchData: getComments,
        data: comments,
        clearData,
        loading,
        error,
    } = useFetchOnDemand(
        () => getMessages(projectId),
        (data) => {
            switch (stage) {
                case StageEnum.Ideas:
                    return data.ideas
                case StageEnum.Protocol:
                    return data.protocol
                case StageEnum.Search:
                    return data.search
                default:
                    return []
            }
        }
    )

    useEffect(() => {
        if (isOpen) {
            getComments()
        } else {
            clearData()
        }
    }, [isOpen])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                commentSectionRef.current &&
                !commentSectionRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [isOpen])

    const toggleCommentSection = () => {
        setIsOpen(!isOpen)
    }

    // Function to handle the send button click
    const onSend = () => {
        if (comment.trim() === '') {
            // Error if the comment is empty
            setErrorMessage(
                'Empty comment, please write at least one character'
            )
            return
        }

        if (comment.length > 300) {
            // Error if the comment is longer than 300 characters
            setErrorMessage('Text should be less than 300 characters.')
            return
        }

        setSending(true)
        sendMessage(projectId, stage, comment).then(() => {
            setComment('')
            setSending(false)
            setErrorMessage(null) // Reset error message after successful send
            getComments()
        })
    }

    return (
        <div ref={commentSectionRef}>
            {/* Comment Section */}
            <div
                className={`fixed right-0 top-[310px] -translate-y-[160px] bg-white dark:bg-bgBlack shadow-blueCustomShadow w-80 h-[calc(100%-200px)]
                    rounded-md shadow-md shadow-blue
                    transition-transform duration-300 transform ${isOpen ? 'translate-x-0 z-10' : 'translate-x-full'}`}
            >
                <div
                    className={`absolute top-1/3 left-[-40px] transform -translate-y-1/2 bg-white dark:bg-bgBlack text-white
                        cursor-pointer w-[40px] h-[90px] flex items-center justify-center shadow-blueCustomShadow hover:scale-110 rounded-l-lg transition-transform duration-300 ${
                            isOpen ? 'translate-x-0' : 'translate-x-[5px]'
                        }`}
                    onClick={toggleCommentSection}
                >
                    <span className="block -rotate-90 whitespace-nowrap text-blue text-sm font-bold bg-white dark:bg-bgBlack">
                        Comments
                    </span>
                </div>

                {/* Comments Content */}
                <div className="flex flex-col gap-2 p-3 h-[calc(100%-170px)] overflow-y-auto shadow-[0px_4px_6px_-4px_#FFAC27] overflow-x-hidden">
                    {loading && (
                        <div>
                            <div className="text-darkGray dark:text-veryLightGray flex justify-center items-center pt-10 font-bold ">
                                Loading your comments
                            </div>

                            <div className="pt-5 flex justify-center items-center">
                                <LoadingSpinner color="#FFAC27" />
                            </div>
                        </div>
                    )}
                    {error && (
                        <div
                            className={'text-darkGray dark:text-veryLightGray'}
                        >
                            Error loading comments.
                        </div>
                    )}
                    {comments &&
                        !loading &&
                        comments.map((comment, index) => (
                            <CommentCard
                                isMine={
                                    comment.author ===
                                    LocalStorageUtil.getEmail()
                                }
                                comment={comment}
                                key={index}
                            />
                        ))}
                    {comments && comments.length === 0 && (
                        <div
                            className={
                                'dark:text-veryLightGray text-veryDarkGray'
                            }
                        >
                            No comments yet
                        </div>
                    )}
                </div>

                {/* Input Area */}
                <div className="absolute bottom-0 left-0 w-full ">
                    <div className="flex flex-row items-center rounded-md p-2">
                        {/* Input Field */}
                        <CustomInputField
                            placeholder="Write a comment..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            disabled={sending}
                            multiline={true}
                            className="flex-1 text-dark rounded-md p-2 w-full min-h-[50px] max-h-[150px]"
                            errorMessage={errorMessage} // Passing the error message
                        />
                        {/* Button */}
                        <IconButton
                            onClick={onSend}
                            icon={<IoMdSend className={`w-6 h-6`} />}
                            loading={sending} // Pass the sending state as the loading prop
                            disabled={sending} // Disable the button while sending
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentSection
