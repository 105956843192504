/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionsResponse
 */
export interface QuestionsResponse {
    /**
     * 
     * @type {number}
     * @memberof QuestionsResponse
     */
    statusCode: number;
    /**
     * ID of the set of ideas generated
     * @type {string}
     * @memberof QuestionsResponse
     */
    ideasSetId: string;
    /**
     * List of genereated ideas
     * @type {Array<any>}
     * @memberof QuestionsResponse
     */
    ideas: Array<any>;
    /**
     * List of articles, and their information, that have been used for idea generation
     * @type {Array<any>}
     * @memberof QuestionsResponse
     */
    articles: Array<any>;
    /**
     * List of clinical trials relevant to the ideas set
     * @type {Array<any>}
     * @memberof QuestionsResponse
     */
    clinicalTrials: Array<any>;
}

/**
 * Check if a given object implements the QuestionsResponse interface.
 */
export function instanceOfQuestionsResponse(value: object): value is QuestionsResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('ideasSetId' in value) || value['ideasSetId'] === undefined) return false;
    if (!('ideas' in value) || value['ideas'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('clinicalTrials' in value) || value['clinicalTrials'] === undefined) return false;
    return true;
}

export function QuestionsResponseFromJSON(json: any): QuestionsResponse {
    return QuestionsResponseFromJSONTyped(json, false);
}

export function QuestionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'ideasSetId': json['ideas_set_id'],
        'ideas': json['ideas'],
        'articles': json['articles'],
        'clinicalTrials': json['clinical_trials'],
    };
}

export function QuestionsResponseToJSON(value?: QuestionsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'ideas_set_id': value['ideasSetId'],
        'ideas': value['ideas'],
        'articles': value['articles'],
        'clinical_trials': value['clinicalTrials'],
    };
}

