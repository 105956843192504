/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcRoutersSearchArticleResponse
 */
export interface SrcRoutersSearchArticleResponse {
    /**
     * 
     * @type {number}
     * @memberof SrcRoutersSearchArticleResponse
     */
    statusCode: number;
    /**
     * List of DOIs added correctly
     * @type {Array<any>}
     * @memberof SrcRoutersSearchArticleResponse
     */
    validDois: Array<any>;
    /**
     * List of DOIs containing errors
     * @type {Array<any>}
     * @memberof SrcRoutersSearchArticleResponse
     */
    errorDois: Array<any>;
    /**
     * List of articles found, and their information attached
     * @type {Array<any>}
     * @memberof SrcRoutersSearchArticleResponse
     */
    articles: Array<any>;
    /**
     * List of reviews found, and their information attached
     * @type {Array<any>}
     * @memberof SrcRoutersSearchArticleResponse
     */
    reviews: Array<any>;
    /**
     * Whether the articles and reviews where saved in the database. Possible values: 0 or 1
     * @type {number}
     * @memberof SrcRoutersSearchArticleResponse
     */
    saved: number;
}

/**
 * Check if a given object implements the SrcRoutersSearchArticleResponse interface.
 */
export function instanceOfSrcRoutersSearchArticleResponse(value: object): value is SrcRoutersSearchArticleResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('validDois' in value) || value['validDois'] === undefined) return false;
    if (!('errorDois' in value) || value['errorDois'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    if (!('saved' in value) || value['saved'] === undefined) return false;
    return true;
}

export function SrcRoutersSearchArticleResponseFromJSON(json: any): SrcRoutersSearchArticleResponse {
    return SrcRoutersSearchArticleResponseFromJSONTyped(json, false);
}

export function SrcRoutersSearchArticleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersSearchArticleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'validDois': json['valid_dois'],
        'errorDois': json['error_dois'],
        'articles': json['articles'],
        'reviews': json['reviews'],
        'saved': json['saved'],
    };
}

export function SrcRoutersSearchArticleResponseToJSON(value?: SrcRoutersSearchArticleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'valid_dois': value['validDois'],
        'error_dois': value['errorDois'],
        'articles': value['articles'],
        'reviews': value['reviews'],
        'saved': value['saved'],
    };
}

