import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { useFetch } from '../../hooks/useFetch'
import { useData } from '../../context/WizardContext'

import Card from '../../components/atoms/Card'
import { CustomButton } from '../../components/buttons/CustomButton'
import DynamicListManager from '../../components/molecules/DynamicListManager'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { InfoTextProtocolGeneration } from '../../components/atoms/InfoTexts'
import { Subtitle } from '../../components/atoms/Subtitle'
import Modal from '../../components/atoms/Modal'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CommentSection from '../../features/comment-section/CommentSection'
import { InfoModal } from '../../features/info-modal/InfoModal'
import useBeforeUnload from '../../hooks/useBeforeUnload'

import {
    generateProtocol,
    getFullText,
    getFullTextFile,
} from '../../middleware/protocolsService'
import { getIndividualIdea } from '../../middleware/ideasService'
import {
    type FileEnum,
    StageEnum,
    SrcModelsSchemasUserHistoryItem,
} from '../../api/openapi'

export const ProtocolGeneration = () => {
    useBeforeUnload()

    const {
        isOwner,
        protocolsRunning,
        selectedAuthors,
        selectedInstitutions,
        setSelectedAuthors,
        setSelectedInstitutions,
        historyData,
    } = useData()

    const navigate = useNavigate()
    const { projectId } = useParams<{ projectId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { protocolId } = useParams<{ protocolId: string }>()

    const [showModal, setShowModal] = useState(false)
    const [showLoadingModal, setShowLoadingModal] = useState(false)
    const [protocolGenerated, setProtocolGenerated] = useState(!!protocolId)
    const [currentHistoryProtocolId, setCurrentHistoryProtocolId] = useState<
        string | null
    >(null)
    const [loadingProtocol, setLoadingProtocol] = useState(false) // New state

    const { data: idea, loading } = useFetch(() =>
        getIndividualIdea(projectId!, ideaId!)
    )

    const {
        fetchData: getProtocol,
        data: protocol,
        error: getProtocolError,
        loading: getProtocolLoading,
    } = useFetchOnDemand(
        () => getFullText(protocolId!, projectId!),
        undefined,
        StageEnum.Protocol
    )

    const {
        fetchData: fetchGenerateProtocol,
        loading: loadingGenerateProtocol,
    } = useFetchOnDemand(
        () =>
            generateProtocol(
                projectId!,
                ideaId!,
                selectedAuthors.join(','),
                selectedInstitutions.join(',')
            ),
        undefined,
        StageEnum.Protocol
    )

    const { fetchData: getFileCall } = useFetchOnDemand(
        (format: FileEnum) => getFullTextFile(protocolId!, projectId!, format),
        undefined,
        StageEnum.Protocol
    )

    const [showInfoModal, setShowInfoModal] = useState(false)

    useEffect(() => {
        if (protocolId) {
            setProtocolGenerated(true)
            setLoadingProtocol(true) // Start loading
            getProtocol().then(() => {
                setLoadingProtocol(false) // Stop loading when data is fetched
            })
        }
    }, [protocolId])

    // useEffect to set the most recent history entry
    useEffect(() => {
        if (historyData && historyData.items && historyData.items.length > 0) {
            const currentHistory =
                historyData.items.find(
                    (project) =>
                        project.ideasSetId === ideasSetId &&
                        project.ideaId === ideaId
                ) || null
            if (currentHistory) {
                setCurrentHistoryProtocolId(currentHistory.protocolId || null)
            }
        }
    }, [historyData])

    const handleGenerateProtocol = () => {
        let hasError = false

        if (selectedAuthors.length === 0) {
            toast.error('You must add at least one author.')
            hasError = true
        }
        if (selectedInstitutions.length === 0) {
            toast.error('You must add at least one organization/institution.')
            hasError = true
        }

        if (hasError) return

        setShowLoadingModal(true)
        fetchGenerateProtocol().then((data) => {
            setProtocolGenerated(true)
            setShowLoadingModal(false)
            if (data)
                navigate(
                    `/wizard/${projectId}/protocol-generation/${ideasSetId}/${ideaId}/${data.fulltextId}`
                )
        })
    }

    const handleDocumentDownload = (format: FileEnum) => {
        getFileCall(format).then((data?: Blob) => {
            if (!data) return
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = `protocol.${format}`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
            setShowModal(false)
        })
    }

    const handleGoToHistoryProtocol = () => {
        if (currentHistoryProtocolId) {
            navigate(
                `/wizard/${projectId}/protocol-generation/${ideasSetId}/${ideaId}/${currentHistoryProtocolId}`
            )
        }
    }
    //Helper function to check if protocols are running
    const isProtocolRunning =
        protocolsRunning !== null && protocolsRunning.length > 0

    return (
        <div className={'flex flex-col items-center gap-6 wizard-width'}>
            {/* Display the most recent entry information */}

            <div className={'w-full'}>
                <div
                    className={
                        'flex flex-col items-start gap-8 relative w-full'
                    }
                >
                    <FunctionButtonsColumn
                        projectId={projectId!}
                        onShowHistoryClick={() => {}}
                        infoText={<InfoTextProtocolGeneration />}
                    />
                    {/* Conditionally render the Title Card and DynamicListManager */}
                    {/* New condition: Hide if a protocol is supposed to be displayed */}
                    {!protocol?.fulltext && !loadingProtocol && (
                        <Card className="mb-4">
                            <div className="flex flex-col items-left gap-8">
                                <div className="flex flex-col items-left gap-0.5 rounded-md ">
                                    <div
                                        className={`text-base text-blue font-bold`}
                                    >
                                        Title selected
                                    </div>
                                    <Card backgroundColor={'lightGray'}>
                                        <div
                                            className={`text-sm font-bold text-veryDarkGray dark:text-veryLightGray`}
                                        >
                                            {loading
                                                ? 'Loading...'
                                                : idea?.title}
                                        </div>
                                    </Card>
                                </div>

                                <Card backgroundColor={'lightGray'}>
                                    <div className="flex flex-col items-left gap-5">
                                        <div className="flex flex-col items-left gap-0.5">
                                            <div
                                                className={`text-base text-blue font-medium`}
                                            >
                                                Authors
                                            </div>
                                            <DynamicListManager
                                                onListChange={
                                                    setSelectedAuthors
                                                }
                                                initialItems={selectedAuthors}
                                                placeholder={'Insert author'}
                                                backgroundColor={'white'}
                                                disabled={
                                                    isProtocolRunning ||
                                                    loadingGenerateProtocol
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-col items-left gap-0.5">
                                            <div
                                                className={`text-base text-blue font-medium`}
                                            >
                                                Organizations / institutions
                                            </div>

                                            <DynamicListManager
                                                onListChange={
                                                    setSelectedInstitutions
                                                }
                                                initialItems={
                                                    selectedInstitutions
                                                }
                                                placeholder={
                                                    'Insert institution'
                                                }
                                                backgroundColor={'white'}
                                                disabled={
                                                    isProtocolRunning ||
                                                    loadingGenerateProtocol
                                                }
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </Card>
                    )}
                </div>

                {/* Section where the generated protocol will appear */}

                <div>
                    <Card>
                        {/* Show loading if protocol is being fetched */}
                        {loadingProtocol ? (
                            <div className="flex flex-col items-center gap-8 p-4">
                                <div className="pb-5">
                                    <LoadingSpinner color="#FFAC27" />
                                </div>
                                <Subtitle text="Loading protocol..." />
                            </div>
                        ) : protocolGenerated && protocol?.fulltext ? (
                            <div
                                className={'p-4'}
                                dangerouslySetInnerHTML={{
                                    __html: protocol?.fulltext || '',
                                }}
                            />
                        ) : (
                            <div className="flex flex-col items-center gap-8 p-4">
                                {/* Show the loading message if protocolsRunning or loadingGenerateProtocol are true. */}
                                {isProtocolRunning ||
                                loadingGenerateProtocol ? ( //Check `isProtocolRunning`
                                    <div className="flex flex-col items-center">
                                        <div className="pb-5">
                                            <LoadingSpinner color="#FFAC27" />
                                        </div>
                                        <Subtitle text="Generating your protocol, we will send you an email once your protocol is ready." />
                                        <div className="text-gray dark:text-darkGray text-center pt-5">
                                            Caution! realoading the page may
                                            cause unexpected behaviour on
                                            running processes.
                                        </div>
                                    </div>
                                ) : (
                                    <Subtitle text='Your protocol will appear here, add authors and organizations and click "Generate Protocol!".' />
                                )}
                            </div>
                        )}
                    </Card>
                </div>
            </div>

            {/* Generate protocol button */}
            {!protocolGenerated && (
                <div className={`flex self-end`}>
                    <CustomButton
                        text={'Generate Protocol!'}
                        onClick={handleGenerateProtocol}
                        isDisabled={
                            isProtocolRunning || loadingGenerateProtocol
                        }
                        loading={isProtocolRunning || loadingGenerateProtocol} //Check `isProtocolRunning`
                    />
                </div>
            )}

            {/* Button for downloading if the protocol exists */}
            {protocolGenerated && isOwner && protocol?.fulltext && (
                <div className={`flex flex-row gap-2 self-end`}>
                    <CustomButton
                        text={'Download'}
                        onClick={() => setShowModal(true)}
                    />
                </div>
            )}

            {/* Download Modal */}
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <Card>
                    <div className={'flex flex-col gap-4 p-4 items-center'}>
                        <div className={'text-lg font-bold text-blue'}>
                            Download Protocol
                        </div>
                        <div className={'flex flex-row gap-4'}>
                            <CustomButton
                                text={'PDF'}
                                onClick={() => handleDocumentDownload('pdf')}
                            />
                            <CustomButton
                                text={'DOCX'}
                                onClick={() => handleDocumentDownload('docx')}
                            />
                        </div>
                    </div>
                </Card>
            </Modal>

            <CommentSection projectId={projectId!} stage={StageEnum.Protocol} />
        </div>
    )
}
