import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { StageEnum } from '../api/openapi'

export const useFetchOnDemand = <T, R = T>(
    apiFunc: (arg?: any) => Promise<T>,
    transform?: (data: T) => R,
    stage?: StageEnum
) => {
    const [data, setData] = useState<R | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    // Return a promise so it can be awaited
    const fetchData = useCallback(
        async (arg?: any) => {
            setLoading(true)
            setError(null)
            clearData()

            try {
                const result = await apiFunc(arg)
                const transformedData = transform
                    ? transform(result)
                    : (result as unknown as R)
                setData(transformedData)
                return transformedData // Resolve the promise with data
            } catch (err: any) {
                setError(err.message || 'Error fetching data')

                if (err.response?.status === 402) {
                    let errorMessage =
                        'You have reached your subscription limits, go to your profile settings if you want to upgrade.'

                    if (stage) {
                        switch (stage) {
                            case StageEnum.Search:
                                errorMessage =
                                    'You can not make more searches with your current subscription, go to your profile settings if you want to upgrade.'
                                break
                            case StageEnum.Ideas:
                                errorMessage =
                                    "You don't have any more idea set generations with your current subscription, go to your profile settings if you want to upgrade."
                                break
                            case StageEnum.Protocol:
                                errorMessage =
                                    "You don't have any more protocol draft generations with your current subscription, go to your profile settings if you want to upgrade."
                                break
                        }
                    }

                    toast.error(errorMessage, {
                        style: {
                            backgroundColor: '#FF3D3D',
                            color: 'white',
                            borderRadius: '8px',
                            fontSize: '16px',
                            padding: '10px',
                        },
                    })
                } else {
                    // Other errors
                    toast.error(err.message || 'An error occurred')
                }
            } finally {
                setLoading(false)
            }
        },
        [apiFunc, transform, stage]
    )

    const clearData = () => {
        setData(null)
    }

    return { data, loading, error, fetchData, clearData }
}
