import type {
    FetchFulltextResponse,
    FileEnum,
    IntroTextResponse,
} from '../api/openapi'
import apiClient from '../api/apiClient'

export const generateProtocol = (
    projectId: string,
    selectedIdeaId: string,
    authors: string,
    institutions: string
): Promise<IntroTextResponse> => {
    return apiClient.generateProtocolApiV3GenerateIntroPost({
        introTextRequestV2: {
            projectId,
            selectedIdeaId,
            authors,
            institutions,
            timestamp: new Date().toISOString(),
        },
    })
}

export const getFullText = (
    fulltextId: string,
    projectId: string
): Promise<FetchFulltextResponse> => {
    return apiClient.fetchFulltextV3FetchFulltextPost({
        fetchFulltext: {
            fulltextId,
            projectId,
        },
    })
}

export const getFullTextFile = (
    fulltextId: string,
    projectId: string,
    format: FileEnum
): Promise<Blob> => {
    return apiClient.fetchFulltextV3FetchFulltextFilePost({
        fetchFulltextFile: {
            fulltextId,
            projectId,
            format,
        },
    })
}
