/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcRoutersIdeaItem } from './SrcRoutersIdeaItem';
import {
    SrcRoutersIdeaItemFromJSON,
    SrcRoutersIdeaItemFromJSONTyped,
    SrcRoutersIdeaItemToJSON,
} from './SrcRoutersIdeaItem';
import type { SrcRoutersArticleInfoItemV2 } from './SrcRoutersArticleInfoItemV2';
import {
    SrcRoutersArticleInfoItemV2FromJSON,
    SrcRoutersArticleInfoItemV2FromJSONTyped,
    SrcRoutersArticleInfoItemV2ToJSON,
} from './SrcRoutersArticleInfoItemV2';

/**
 * 
 * @export
 * @interface ListIdeaItems
 */
export interface ListIdeaItems {
    /**
     * List of ideas
     * @type {Array<SrcRoutersIdeaItem>}
     * @memberof ListIdeaItems
     */
    results?: Array<SrcRoutersIdeaItem>;
    /**
     * List of articles, and their information, that have been used for idea generation
     * @type {Array<SrcRoutersArticleInfoItemV2>}
     * @memberof ListIdeaItems
     */
    articles: Array<SrcRoutersArticleInfoItemV2>;
}

/**
 * Check if a given object implements the ListIdeaItems interface.
 */
export function instanceOfListIdeaItems(value: object): value is ListIdeaItems {
    if (!('articles' in value) || value['articles'] === undefined) return false;
    return true;
}

export function ListIdeaItemsFromJSON(json: any): ListIdeaItems {
    return ListIdeaItemsFromJSONTyped(json, false);
}

export function ListIdeaItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIdeaItems {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(SrcRoutersIdeaItemFromJSON)),
        'articles': ((json['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2FromJSON)),
    };
}

export function ListIdeaItemsToJSON(value?: ListIdeaItems | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(SrcRoutersIdeaItemToJSON)),
        'articles': ((value['articles'] as Array<any>).map(SrcRoutersArticleInfoItemV2ToJSON)),
    };
}

