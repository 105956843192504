import apiClient from '../api/apiClient'
import type {
    IdeaSetItem, IdeasSetResponse,
    ListIdeaItems, SrcModelsSchemasIdeaItem,
} from '../api/openapi'

export const generateIdeas = (
    projectId: string,
    searchId: string
): Promise<IdeasSetResponse> => {
    return apiClient.getResearchQuestionsV3IdeasGeneratorPost({
        questionsRequest: {
            projectId,
            searchId,
            timestamp: new Date().toISOString(),
        },
    })
}

export const getIndividualIdea = (
    projectId: string,
    ideaId: string
): Promise<SrcModelsSchemasIdeaItem> => {
    return apiClient.fetchIndividualIdeaV3FetchIndividualIdeaPost({
        fetchIdeaSet: {
            projectId,
            ideaId,
        },
    })
}

export const fetchIdeas = (
    projectId: string,
    ideasSetId: string
): Promise<ListIdeaItems> => {
    return apiClient.fetchIdeasV3FetchIdeasPost({
        fetchIdeas: {
            projectId,
            ideasSetId,
        },
    })
}
