/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectStatus
 */
export interface ProjectStatus {
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    searchId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    ideasSetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    ideaId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    fulltextId?: string;
    /**
     * Datetime of project version
     * @type {string}
     * @memberof ProjectStatus
     */
    createdAt?: string;
}

/**
 * Check if a given object implements the ProjectStatus interface.
 */
export function instanceOfProjectStatus(value: object): value is ProjectStatus {
    return true;
}

export function ProjectStatusFromJSON(json: any): ProjectStatus {
    return ProjectStatusFromJSONTyped(json, false);
}

export function ProjectStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'searchId': json['search_id'] == null ? undefined : json['search_id'],
        'ideasSetId': json['ideas_set_id'] == null ? undefined : json['ideas_set_id'],
        'ideaId': json['idea_id'] == null ? undefined : json['idea_id'],
        'fulltextId': json['fulltext_id'] == null ? undefined : json['fulltext_id'],
        'createdAt': json['created_at'] == null ? undefined : json['created_at'],
    };
}

export function ProjectStatusToJSON(value?: ProjectStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search_id': value['searchId'],
        'ideas_set_id': value['ideasSetId'],
        'idea_id': value['ideaId'],
        'fulltext_id': value['fulltextId'],
        'created_at': value['createdAt'],
    };
}

