import React, { useState, useEffect } from 'react'
import { colorMapping } from '../../utils/colorMappings'

interface CardProps {
    backgroundColor?: string
    children: React.ReactNode
    minHeight?: string
    className?: string
    onClose?: () => void
    isClosing?: boolean
}

const Card = ({
    backgroundColor = 'white',
    children,
    minHeight, // Optional prop to define a minimum height
    className = '', // Optional className prop
    onClose,
    isClosing = false,
}: CardProps) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark

    // Add a small delay to the closing animation to prevent flickering
    const [showClosingAnimation, setShowClosingAnimation] = useState(false)

    useEffect(() => {
        if (isClosing) {
            // Small delay before showing the closing animation
            const timer = setTimeout(() => {
                setShowClosingAnimation(true)
            }, 50)
            return () => clearTimeout(timer)
        } else {
            setShowClosingAnimation(false)
        }
    }, [isClosing])

    return (
        <div
            className={`bg-${lightColor} dark:bg-${darkColor}
                        shadow-blueCustomShadow transition-colors duration-100 ease-in
                         p-4 rounded-md w-full overflow-y-auto 
                         ${showClosingAnimation ? 'animate-slideOut' : 'animate-slideIn'}
                        ${className}`} // Append the custom className
            style={{ minHeight: minHeight || 'auto' }} // Apply minHeight if provided
        >
            {children}
        </div>
    )
}

export default Card
