import React from 'react'

interface LoginButtonProps {
    text: string
    onClick: () => void
    icon?: React.ReactNode
    className?: string
}

const LoginButton: React.FC<LoginButtonProps> = ({
    text,
    onClick,
    icon,
    className = '',
}) => {
    return (
        <button
            onClick={onClick}
            className={`flex shadow-blueCustomShadow items-center justify-start rounded-md bg-lightGray dark:bg-darkBlue text-darkGray hover:text-blue dark:text-gray dark:hover:text-blue
             hover:shadow-yellowCustomShadow 
            transition-all duration-300 ease-in-out transform hover:scale-110
            px-4 py-2 font-bold text-xl w-full ${className}`}
        >
            {icon}
            {text}
        </button>
    )
}

export default LoginButton
