import React, { useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import type { SrcRoutersArticleInfoItemV2 } from '../../api/openapi'
import Card from '../../components/atoms/Card'
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg'
import { IconButton } from '../../components/buttons/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HoverOnTables from '../../components/atoms/HoverOnTables'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

import { Tooltip as ReactTooltip } from 'react-tooltip' // Import with alias "ReactTooltip"

export const ArticlesTable = ({
    articles,
    onClose,
}: {
    articles: SrcRoutersArticleInfoItemV2[]
    onClose: () => void
}) => {
    const [isClosing, setIsClosing] = useState(false)

    const handleClose = () => {
        setIsClosing(true)
        setTimeout(() => {
            setIsClosing(false)
            onClose()
        }, 300)
    }

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title - Authors',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    {params.row.pmid ? (
                        <a
                            href={`https://pubmed.ncbi.nlm.nih.gov/${params.row.pmid}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm font-bold text-blue hover:text-yellow dark:text-blue-400 dark:hover:text-blue-500"
                        >
                            {params.value}
                        </a>
                    ) : (
                        <p className="text-sm font-bold text-veryDarkGray dark:text-veryLightGray">
                            {params.value}
                        </p>
                    )}
                </HoverOnTables>
            ),
        },
        {
            field: 'population',
            headerName: 'Population',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </p>
                </HoverOnTables>
            ),
        },
        {
            field: 'intervention',
            headerName: 'Intervention',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </p>
                </HoverOnTables>
            ),
        },
        {
            field: 'outcome',
            headerName: 'Outcome',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </p>
                </HoverOnTables>
            ),
        },
        {
            field: 'relevance',
            headerName: 'Relevance',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </p>
                </HoverOnTables>
            ),
        },
        {
            field: 'isReview',
            headerName: 'Type',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            valueGetter: (params: any) =>
                params.value === 'No' ? 'Article' : 'Review',
            renderCell: (params) => (
                <HoverOnTables title={params.value}>
                    <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                        {params.value}
                    </p>
                </HoverOnTables>
            ),
        },
    ]

    return (
        <Card
            className={`w-full flex-grow ${isClosing ? 'animate-slideOut' : 'animate-slideIn'}`}
        >
            <div className="flex flex-col w-full h-full text-veryDarkGray">
                <div className="pr-4 flex mr-auto mb-4 items-center justify-between w-full">
                    <IconButton onClick={handleClose} icon={<BackIcon />} />
                    <AiOutlineQuestionCircle
                        data-tooltip-id="info-tooltip"
                        data-tooltip-html={`In this table Erudita shows the most relevant articles it used to generate your ideas.`}
                        className="cursor-pointer text-blue w-6 h-6 transition-transform transform hover:scale-110"
                    />
                    {/* Tooltip element */}
                    <ReactTooltip
                        className="z-[9999]"
                        id="info-tooltip"
                        place="left"
                        openOnClick
                        clickable
                        style={{
                            backgroundColor: '#EBF0F3',
                            color: '#767676',
                            border: '1px solid #767676',
                            boxShadow: '0px 4px 6px rgba(118, 118, 118, 0.6)',
                        }}
                    />
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        hideFooter={true}
                        rows={articles.map((article) => ({
                            ...article,
                            id: article.pmid,
                        }))}
                        columns={columns}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: '#EBF0F3',
                            backgroundColor: '#EBF0F3',
                        }}
                        className="dark:bg-darkBlue dark:border-darkBlue"
                    />
                </div>
            </div>
        </Card>
    )
}
