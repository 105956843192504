/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SrcRoutersClinicalTrial } from './SrcRoutersClinicalTrial';
import {
    SrcRoutersClinicalTrialFromJSON,
    SrcRoutersClinicalTrialFromJSONTyped,
    SrcRoutersClinicalTrialToJSON,
} from './SrcRoutersClinicalTrial';

/**
 * 
 * @export
 * @interface ListClinicalTrials
 */
export interface ListClinicalTrials {
    /**
     * 
     * @type {Array<SrcRoutersClinicalTrial>}
     * @memberof ListClinicalTrials
     */
    clinicalTrials: Array<SrcRoutersClinicalTrial>;
}

/**
 * Check if a given object implements the ListClinicalTrials interface.
 */
export function instanceOfListClinicalTrials(value: object): value is ListClinicalTrials {
    if (!('clinicalTrials' in value) || value['clinicalTrials'] === undefined) return false;
    return true;
}

export function ListClinicalTrialsFromJSON(json: any): ListClinicalTrials {
    return ListClinicalTrialsFromJSONTyped(json, false);
}

export function ListClinicalTrialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListClinicalTrials {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicalTrials': ((json['clinical_trials'] as Array<any>).map(SrcRoutersClinicalTrialFromJSON)),
    };
}

export function ListClinicalTrialsToJSON(value?: ListClinicalTrials | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clinical_trials': ((value['clinicalTrials'] as Array<any>).map(SrcRoutersClinicalTrialToJSON)),
    };
}

