/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchHistoryItem } from './SearchHistoryItem';
import {
    SearchHistoryItemFromJSON,
    SearchHistoryItemFromJSONTyped,
    SearchHistoryItemToJSON,
} from './SearchHistoryItem';
import type { TextHistoryItem } from './TextHistoryItem';
import {
    TextHistoryItemFromJSON,
    TextHistoryItemFromJSONTyped,
    TextHistoryItemToJSON,
} from './TextHistoryItem';
import type { IdeaHistoryItem } from './IdeaHistoryItem';
import {
    IdeaHistoryItemFromJSON,
    IdeaHistoryItemFromJSONTyped,
    IdeaHistoryItemToJSON,
} from './IdeaHistoryItem';

/**
 * 
 * @export
 * @interface SrcRoutersUserHistory
 */
export interface SrcRoutersUserHistory {
    /**
     * History of searches
     * @type {Array<SearchHistoryItem>}
     * @memberof SrcRoutersUserHistory
     */
    searches?: Array<SearchHistoryItem>;
    /**
     * List of ideas
     * @type {Array<IdeaHistoryItem>}
     * @memberof SrcRoutersUserHistory
     */
    ideas?: Array<IdeaHistoryItem>;
    /**
     * History of protocols
     * @type {Array<TextHistoryItem>}
     * @memberof SrcRoutersUserHistory
     */
    protocols?: Array<TextHistoryItem>;
}

/**
 * Check if a given object implements the SrcRoutersUserHistory interface.
 */
export function instanceOfSrcRoutersUserHistory(value: object): value is SrcRoutersUserHistory {
    return true;
}

export function SrcRoutersUserHistoryFromJSON(json: any): SrcRoutersUserHistory {
    return SrcRoutersUserHistoryFromJSONTyped(json, false);
}

export function SrcRoutersUserHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersUserHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'searches': json['searches'] == null ? undefined : ((json['searches'] as Array<any>).map(SearchHistoryItemFromJSON)),
        'ideas': json['ideas'] == null ? undefined : ((json['ideas'] as Array<any>).map(IdeaHistoryItemFromJSON)),
        'protocols': json['protocols'] == null ? undefined : ((json['protocols'] as Array<any>).map(TextHistoryItemFromJSON)),
    };
}

export function SrcRoutersUserHistoryToJSON(value?: SrcRoutersUserHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searches': value['searches'] == null ? undefined : ((value['searches'] as Array<any>).map(SearchHistoryItemToJSON)),
        'ideas': value['ideas'] == null ? undefined : ((value['ideas'] as Array<any>).map(IdeaHistoryItemToJSON)),
        'protocols': value['protocols'] == null ? undefined : ((value['protocols'] as Array<any>).map(TextHistoryItemToJSON)),
    };
}

