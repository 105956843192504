import { useGoogleLogin } from '@react-oauth/google'
import Card from '../../components/atoms/Card'
import { Subtitle } from '../../components/atoms/Subtitle'
import LoginButton from '../../components/buttons/LoginButton'
import { FaGoogle, FaLinkedin } from 'react-icons/fa'
import { TfiMicrosoftAlt } from 'react-icons/tfi'
import { CustomButton } from '../../components/buttons/CustomButton'
import { Title } from '../../components/atoms/Title'
import { ReactComponent as SmallLogo } from '../../assets/small-logo.svg'
import {
    googleAuthorization,
    microsoftAuthorization,
    emailPasswordLogin,
} from '../../middleware/authService'
import { useEffect, useState, useRef } from 'react'
import { msalInstance } from '../../utils/authConfig'
import { useNavigate } from 'react-router-dom'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import CustomInputField from '../../components/atoms/CustomInputField'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import { UserProfileCard } from '../../components/molecules/UserProfileCard'
import { InfoModal } from '../../features/info-modal/InfoModal'

export function Login() {
    const [isMsalInitialized, setIsMsalInitialized] = useState(false)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPasswordLogin, setShowPasswordLogin] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const formRef = useRef<HTMLDivElement>(null)
    const [showProfileCard, setShowProfileCard] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)

    // Estado para mostrar u ocultar el formulario
    const [showForm, setShowForm] = useState(false)
    // Estado para ocultar el botón después de hacer clic
    const [showButton, setShowButton] = useState(true)

    // Función para manejar el clic en el botón
    const handleButtonClick = () => {
        setShowForm(true) // Muestra el formulario al hacer clic
        setShowButton(false) // Oculta el botón
        // Scroll to form after a small delay to ensure the form is rendered
        setTimeout(() => {
            formRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }, 100)
    }

    const handleProfileCardCloseAttempt = (isProfileFilled: boolean) => {
        if (!isProfileFilled) {
            setShowInfoModal(true)
        }
    }

    const handleInfoModalClose = () => {
        setShowInfoModal(false)
    }

    const handleProfileCardSaved = () => {
        setShowProfileCard(false)
        navigate('/')
    }

    const handleWelcomeModalClose = () => {
        setShowWelcomeModal(false)
    }

    // Initialize MSAL on component mount
    useEffect(() => {
        msalInstance.initialize().then(() => setIsMsalInitialized(true))
    }, [])

    const handleGoogleLogin = async () => {
        setLoading(true)
        try {
            await googleLogin()
        } catch (error) {
            setLoading(false)
        }
    }

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (response) => {
            setLoading(true)
            try {
                const data = await googleAuthorization(response.code)

                // Verificar si la autenticación fue exitosa
                if (!data.result || !data.jwtToken) {
                    toast.error('Authentication failed. Please try again.')
                    setLoading(false)
                    return
                }

                // First clear any existing auth data
                LocalStorageUtil.clearAll()
                // Then set new auth data
                LocalStorageUtil.setToken(data.jwtToken)
                LocalStorageUtil.setRefreshToken(data.refreshToken)
                const email = JSON.parse(
                    atob(data.jwtToken.split('.')[1])
                ).user_email
                LocalStorageUtil.setEmail(email)

                // Force a page reload to ensure clean state
                if (data.hasProfile) {
                    window.location.href = '/'
                } else {
                    window.location.href = '/create-profile'
                }
            } catch (error: any) {
                toast.error(
                    'Failed to authenticate with Google. Please try again.'
                )
                setLoading(false)
            }
        },
        onError: () => {
            setLoading(false)
            toast.error('Google login failed. Please try again.')
        },
    })

    const microsoftLogin = async () => {
        if (!isMsalInitialized) {
            return
        }

        try {
            // Trigger Microsoft sign-in with a popup
            const loginResponse = await msalInstance.loginPopup({
                scopes: ['User.Read'], // Define your desired scopes (e.g., User.Read for basic profile access)
            })

            // Retrieve the access token (or ID token if no permissions were requested)
            const accessToken = loginResponse.accessToken

            // Send the access token to your backend for further processing
            await microsoftAuthorization(accessToken)
        } catch (error) {
            // Handle error silently
        }
    }

    const linkedInLogin = () => {
        const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID || ''
        const redirectUri = encodeURIComponent(
            process.env.REACT_APP_LINKEDIN_REDIRECT_URI || ''
        )
        const scope = encodeURIComponent('email profile openid')

        // Construct LinkedIn authorization URL
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state='123456'&scope=${scope}`

        // Redirect user to LinkedIn in the same tab
        window.location.href = linkedInAuthUrl
    }

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const validateForm = () => {
        let isValid = true
        setEmailError('')
        setPasswordError('')

        if (!email) {
            setEmailError('Email is required')
            isValid = false
        } else if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address')
            isValid = false
        }

        if (!password) {
            setPasswordError('Password is required')
            isValid = false
        }

        return isValid
    }

    const handleEmailPasswordLogin = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!validateForm()) {
            return
        }

        setLoading(true)
        try {
            const response = await emailPasswordLogin(email, password)
            const { jwtToken, refreshToken, userEmail, hasProfile } = response
            LocalStorageUtil.setToken(jwtToken)
            LocalStorageUtil.setRefreshToken(refreshToken)
            LocalStorageUtil.setEmail(userEmail)
            if (hasProfile) {
                navigate('/')
            } else {
                navigate('/create-profile')
            }
        } catch (error: any) {
            if (error.message?.toLowerCase().includes('invalid credentials')) {
                toast.error('No account found with these credentials')
            } else {
                toast.error(error.message || 'An error occurred during login')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex items-center flex-col">
            {!loading && (
                <div className="md:w-[500px] h-full flex items-center flex-col">
                    {/* ↓ Title & subtitle section ↓  */}
                    <div className="flex items-center flex-col">
                        <div className="flex flex-col items-center pb-10">
                            <SmallLogo className="h-[80px] w-[80px]" />
                        </div>
                    </div>
                    <Card>
                        <div className="flex items-center flex-col">
                            {/* ↓ Flex container for both sections ↓  */}
                            <div className="flex flex-col items-center justify-center p-5">
                                <Subtitle text="Sign-in to your account." />

                                {/* ↓ Login with providers buttons ↓ */}
                                <div className="flex flex-col items-stretch p-5">
                                    {!showPasswordLogin && (
                                        <>
                                            <div className="flex items-center p-5">
                                                <LoginButton
                                                    text={'Sign-in with GOOGLE'}
                                                    onClick={handleGoogleLogin}
                                                    icon={
                                                        <FaGoogle className="h-7 w-7 mr-2 text-blue" />
                                                    }
                                                />
                                            </div>
                                            <div className="flex items-center p-5">
                                                <LoginButton
                                                    text={
                                                        'Sign-in with LINKEDIN'
                                                    }
                                                    onClick={linkedInLogin}
                                                    icon={
                                                        <FaLinkedin className="h-7 w-7 mr-2 text-blue" />
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* ↓ Separator ↓ */}
                                <div className="flex items-center justify-center w-full">
                                    <div className="flex-grow border-t-2 border-yellow"></div>
                                    <span className="mx-2 text-veryDarkGray dark:text-veryLightGray">
                                        or
                                    </span>
                                    <div className="flex-grow border-t-2 border-yellow"></div>
                                </div>
                                <div>
                                    {/* El botón que controla la visibilidad y desaparece después de hacer clic */}
                                    {showButton && (
                                        <div className="p-5">
                                            <CustomButton
                                                text="Sign-in with password"
                                                onClick={handleButtonClick}
                                                backgroundColor="yellow"
                                                textColor="white"
                                            />
                                        </div>
                                    )}

                                    {/* Mostrar el formulario solo si showForm es true */}
                                    {showForm && (
                                        <div
                                            ref={formRef}
                                            className="flex items-center flex-col p-14"
                                        >
                                            <form
                                                onSubmit={
                                                    handleEmailPasswordLogin
                                                }
                                                className="w-full space-y-4 flex items-center flex-col"
                                                noValidate
                                            >
                                                <CustomInputField
                                                    type="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setEmailError('')
                                                    }}
                                                    errorMessage={emailError}
                                                    className="w-[300px]"
                                                />
                                                <CustomInputField
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                        setPasswordError('')
                                                    }}
                                                    errorMessage={passwordError}
                                                    className="w-[300px]"
                                                    isPassword={true}
                                                />
                                                <CustomButton
                                                    text="Sign-in"
                                                    onClick={() =>
                                                        handleEmailPasswordLogin(
                                                            new Event(
                                                                'submit'
                                                            ) as any
                                                        )
                                                    }
                                                    backgroundColor="blue"
                                                    textColor="white"
                                                />
                                                <div className="text-center mt-2">
                                                    <a
                                                        href="/recover-password"
                                                        className="text-sm text-blue hover:text-blue-600 transition-colors"
                                                    >
                                                        Recover password
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <p className="text-center pr-5 pl-5 text-gray dark:text-darkGray">
                                By Signing-in, you accept Erudita's{' '}
                                <a
                                    href="https://erudita.tech/terms-of-service/"
                                    target="_blank"
                                    className="text-blue hover:text-[#FFAC27] hover:scale-105 transition-all"
                                >
                                    Terms & Conditions
                                </a>{' '}
                                and{' '}
                                <a
                                    href="https://erudita.tech/privacy-policy/"
                                    target="_blank"
                                    className="text-blue hover:text-[#FFAC27] hover:scale-105 transition-all"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </p>
                        </div>
                    </Card>
                    {/* ↓ create account button ↓ */}

                    <Subtitle className="pt-5" text="Don't have an account? " />
                    <a
                        href="https://erudita.tech/pricing"
                        target="_blank"
                        className="text-blue hover:text-[#FFAC27] hover:scale-105 transition-all"
                    >
                        Register now!
                    </a>
                </div>
            )}
            {loading && (
                <div className="flex items-center justify-center min-h-screen">
                    <Card>
                        <div className="flex flex-col items-center gap-4 p-8">
                            <Subtitle text="Loading, please wait." />
                            <LoadingSpinner color="#FFAC27" />
                        </div>
                    </Card>
                </div>
            )}
            {showProfileCard && (
                <UserProfileCard
                    onClose={(isFilled) => {
                        handleProfileCardCloseAttempt(isFilled)
                    }}
                    isModalOpen={true}
                    disableClose={true}
                    onProfileSaved={handleProfileCardSaved}
                />
            )}
            <InfoModal
                isOpen={showInfoModal}
                closeAction={handleInfoModalClose}
                title="Profile information Required"
                text="You must fill in your profile information before continuing to Erudita."
            />
            <InfoModal
                isOpen={showWelcomeModal}
                closeAction={handleWelcomeModalClose}
                title="Welcome to Erudita!"
                text="Before continuing to the platform we need some information about you in order to be able to give you the best experience possible, please, fill the form before continuing."
            />
        </div>
    )
}

export default Login
