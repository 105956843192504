/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SrcRoutersClinicalTrial
 */
export interface SrcRoutersClinicalTrial {
    /**
     * Clinical Trial ID
     * @type {string}
     * @memberof SrcRoutersClinicalTrial
     */
    nCTNumber: string;
    /**
     * Title
     * @type {string}
     * @memberof SrcRoutersClinicalTrial
     */
    studyTitle: string;
    /**
     * Study status
     * @type {string}
     * @memberof SrcRoutersClinicalTrial
     */
    studyStatus: string;
    /**
     * Summary
     * @type {string}
     * @memberof SrcRoutersClinicalTrial
     */
    briefSummary: string;
}

/**
 * Check if a given object implements the SrcRoutersClinicalTrial interface.
 */
export function instanceOfSrcRoutersClinicalTrial(value: object): value is SrcRoutersClinicalTrial {
    if (!('nCTNumber' in value) || value['nCTNumber'] === undefined) return false;
    if (!('studyTitle' in value) || value['studyTitle'] === undefined) return false;
    if (!('studyStatus' in value) || value['studyStatus'] === undefined) return false;
    if (!('briefSummary' in value) || value['briefSummary'] === undefined) return false;
    return true;
}

export function SrcRoutersClinicalTrialFromJSON(json: any): SrcRoutersClinicalTrial {
    return SrcRoutersClinicalTrialFromJSONTyped(json, false);
}

export function SrcRoutersClinicalTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersClinicalTrial {
    if (json == null) {
        return json;
    }
    return {
        
        'nCTNumber': json['NCT_number'],
        'studyTitle': json['Study_title'],
        'studyStatus': json['Study_status'],
        'briefSummary': json['Brief_summary'],
    };
}

export function SrcRoutersClinicalTrialToJSON(value?: SrcRoutersClinicalTrial | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'NCT_number': value['nCTNumber'],
        'Study_title': value['studyTitle'],
        'Study_status': value['studyStatus'],
        'Brief_summary': value['briefSummary'],
    };
}

