import Card from '../atoms/Card'
import React, { useEffect, useState } from 'react'
import { ArticleItem } from '../../api/openapi'
import { colorMapping } from '../../utils/colorMappings'

/**
 * ArticlesListCard component displays a list of articles with their titles and citations.
 * It supports DOI linking and article selection functionality.
 */
export const ArticlesListCard = ({
    articles,
    onClick,
    backgroundColor,
    disableSelect,
}: {
    articles: ArticleItem[]
    onClick?: (doi: string) => void
    backgroundColor: string
    disableSelect: boolean
}) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark
    const [selected, setSelected] = useState<string>('')

    // Set initial selection when articles change
    useEffect(() => {
        if (articles.length > 0) {
            setSelected(articles[0].doi!)
            if (onClick) onClick(articles[0].doi!)
        }
    }, [articles])

    const onSelect = (doi: string) => {
        if (selected === doi) return
        setSelected(doi)
        if (onClick) onClick(doi)
    }

    /**
     * Safely constructs a DOI URL with error handling
     * @param doi The DOI string to convert to a URL
     * @returns A valid DOI URL or null if construction fails
     */
    const getDoiUrl = (doi: string): string | null => {
        try {
            // Ensure DOI is properly formatted and encoded
            const cleanDoi = doi.trim().replace(/^doi:/, '')
            return `https://doi.org/${encodeURIComponent(cleanDoi)}`
        } catch (error) {
            return null
        }
    }

    return (
        <Card backgroundColor={backgroundColor}>
            {/*be careful with height and overflow-y-auto in the div below*/}
            <div
                className={`flex flex-col gap-4 bg-${lightColor} dark:bg-${darkColor} w-full max-h-[60vh]`}
            >
                {articles.map((article: ArticleItem, index) => {
                    const doiUrl = article.doi ? getDoiUrl(article.doi) : null
                    return (
                        <div
                            key={article.id}
                            className={`flex flex-col gap-2 ${disableSelect ? '' : 'cursor-pointer'} ${selected === article.doi && !disableSelect ? 'text-blue dark:text-yellow' : 'text-darkGray dark:text-veryLightGray'}`}
                            onClick={() => onSelect(article.doi!)}
                        >
                            <div className={`text-base font-bold text-left`}>
                                {/* Only render DOI link if URL construction was successful */}
                                {doiUrl ? (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue hover:text-yellow"
                                        href={doiUrl}
                                        onClick={(e) => e.stopPropagation()} // Prevent article selection when clicking DOI link
                                    >
                                        {article.title}
                                    </a>
                                ) : (
                                    <p>{article.title}</p>
                                )}
                            </div>

                            <div className={`text-sm text-left`}>
                                {article.shortCitation}{' '}
                            </div>

                            {/* Horizontal line between articles */}
                            {index !== articles.length - 1 && (
                                <div className={`border-b border-yellow`} />
                            )}
                        </div>
                    )
                })}
            </div>
        </Card>
    )
}
