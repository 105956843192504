import apiClient from '../api/apiClient'
import type {
    PreviewSearchResponse,
    SearchHistoryItem,
    SearchQueryGenerationResponse, SrcModelsSchemasSearchArticleResponse,
    SrcModelsSchemasSearchTermResponse,
} from '../api/openapi'

export const previewSearchPubmed = (
    query: string,
    minYear: number,
    maxYear: number
): Promise<PreviewSearchResponse> => {
    return apiClient.previewSearchPubmedV3SearchPreviewPost({
        previewSearch: {
            query,
            minYear,
            maxYear,
            timestamp: new Date().toISOString(),
        },
    })
}

export const generatePicoQuery = (
    population: string,
    intervention: string,
    others: string,
    outcome: string
): Promise<SearchQueryGenerationResponse> => {
    return apiClient.generateSearchQueryFromPicoV3GeneratePicoQueryPost({
        searchQueryPICO: {
            population,
            intervention,
            outcome,
            others: others,
            timestamp: new Date().toISOString(),
        },
    })
}

export const createSearch = (
    projectId: string,
    searchQuery: string = '',
    pubmedQuery: string,
    minYear: number,
    maxYear: number,
    keywords: any[]
): Promise<SrcModelsSchemasSearchTermResponse> => {
    return apiClient.articlesSearchPubmedV3SearchPost({
        searchTerm: {
            projectId,
            searchQuery,
            pubmedQuery,
            minYear,
            maxYear,
            keywords,
            timestamp: new Date().toISOString(),
        },
    })
}

export const getArticlesAndReviews = (
    projectId: string,
    searchId: string
): Promise<SearchHistoryItem> => {
    return apiClient.fetchUserIndividualSearchV3FetchIndividualSearchPost({
        fetchIndividualSearch: {
            projectId,
            searchId,
        },
    })
}

export const addArticleToProject = (
    projectId: string,
    searchId: string,
    dois: string[]
): Promise<SrcModelsSchemasSearchArticleResponse> => {
    return apiClient.articleSearchPubmedV3SearchArticlePost({
        searchArticle: {
            projectId,
            dois,
            searchId,
            timestamp: new Date().toISOString(),
        },
    })
}
