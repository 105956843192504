/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleItem } from './ArticleItem';
import {
    ArticleItemFromJSON,
    ArticleItemFromJSONTyped,
    ArticleItemToJSON,
} from './ArticleItem';

/**
 * 
 * @export
 * @interface ListArticlesItem
 */
export interface ListArticlesItem {
    /**
     * Articles
     * @type {Array<ArticleItem>}
     * @memberof ListArticlesItem
     */
    articles?: Array<ArticleItem>;
    /**
     * Reviews
     * @type {Array<ArticleItem>}
     * @memberof ListArticlesItem
     */
    reviews?: Array<ArticleItem>;
}

/**
 * Check if a given object implements the ListArticlesItem interface.
 */
export function instanceOfListArticlesItem(value: object): value is ListArticlesItem {
    return true;
}

export function ListArticlesItemFromJSON(json: any): ListArticlesItem {
    return ListArticlesItemFromJSONTyped(json, false);
}

export function ListArticlesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListArticlesItem {
    if (json == null) {
        return json;
    }
    return {
        
        'articles': json['articles'] == null ? undefined : ((json['articles'] as Array<any>).map(ArticleItemFromJSON)),
        'reviews': json['reviews'] == null ? undefined : ((json['reviews'] as Array<any>).map(ArticleItemFromJSON)),
    };
}

export function ListArticlesItemToJSON(value?: ListArticlesItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articles': value['articles'] == null ? undefined : ((value['articles'] as Array<any>).map(ArticleItemToJSON)),
        'reviews': value['reviews'] == null ? undefined : ((value['reviews'] as Array<any>).map(ArticleItemToJSON)),
    };
}

