import type { BinaryResultsResponse } from '../api/openapi'
import apiClient from '../api/apiClient'
import LocalStorageUtil from '../utils/LocalStorageUtil'

export const sendFeedback = (
    feedback: string
): Promise<BinaryResultsResponse> => {
    return apiClient.submitFeedbackV3SubmitFeedbackPost({
        feedbackFormV2: {
            userEmail: LocalStorageUtil.getEmail() || '',
            text: feedback,
            timestamp: new Date(),
        },
    })
}
