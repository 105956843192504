import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import apiClient from '../../api/apiClient'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import Card from '../../components/atoms/Card'
import { Subtitle } from '../../components/atoms/Subtitle'
import { CustomButton } from '../../components/buttons/CustomButton'
import CustomInputField from '../../components/atoms/CustomInputField'
import { ReactComponent as SmallLogo } from '../../assets/small-logo.svg'
import { Title } from '../../components/atoms/Title'

export function SetPassword() {
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const validatePassword = (pass: string) => {
        if (pass.length < 8) {
            return 'Password must be at least 8 characters long'
        }
        if (!/[A-Z]/.test(pass)) {
            return 'Password must contain at least one uppercase letter'
        }
        if (!/[a-z]/.test(pass)) {
            return 'Password must contain at least one lowercase letter'
        }
        if (!/[0-9]/.test(pass)) {
            return 'Password must contain at least one number'
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(pass)) {
            return 'Password must contain at least one special character'
        }
        return ''
    }

    const handlePasswordChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPassword = e.target.value
        setPassword(newPassword)
        setPasswordError(validatePassword(newPassword))

        if (confirmPassword && newPassword !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match')
        } else {
            setConfirmPasswordError('')
        }
    }

    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newConfirmPassword = e.target.value
        setConfirmPassword(newConfirmPassword)

        if (newConfirmPassword !== password) {
            setConfirmPasswordError('Passwords do not match')
        } else {
            setConfirmPasswordError('')
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        const passwordValidationError = validatePassword(password)
        if (passwordValidationError) {
            setPasswordError(passwordValidationError)
            return
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match')
            return
        }

        setLoading(true)
        try {
            await apiClient.setPasswordV3SetPasswordPost({
                verification_token: searchParams.get('token') || '',
                password: password,
            })
            toast.success('Password set successfully')
            window.location.href = '/login'
        } catch (error: any) {
            toast.error(
                error.message || 'An error occurred while setting the password'
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex items-center flex-col">
            {!loading && (
                <div className="px-2 md:w-[500px] h-full flex items-center flex-col">
                    <div className="flex items-center flex-col">
                        <div className="flex flex-col items-center pb-10">
                            <SmallLogo className="h-[80px] w-[80px]" />
                        </div>
                    </div>
                    <Card>
                        <div className="flex flex-col items-center">
                            <Subtitle
                                className="text-4xl text-darkgray dark:text-gray pt-5"
                                text={
                                    'Welcome to Erudita, please, create a password.'
                                }
                            />
                        </div>
                        <div className="flex items-center flex-col">
                            <div className="flex flex-col items-center justify-center p-5">
                                <div className="flex flex-col items-center justify-center  p-5">
                                    <div className="text-sm text-darkGray">
                                        Your password must contain:
                                        <ul className="list-disc list-inside mt-2">
                                            <li>At least 8 characters</li>
                                            <li>
                                                At least one uppercase letter
                                            </li>
                                            <li>
                                                At least one lowercase letter
                                            </li>
                                            <li>At least one number</li>
                                            <li>
                                                At least one special character
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex flex-col items-center justify-center p-5">
                                    <form
                                        onSubmit={handleSubmit}
                                        className="w-full space-y-4 flex items-center flex-col"
                                    >
                                        <CustomInputField
                                            type="password"
                                            placeholder="New Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            errorMessage={passwordError}
                                            className="w-[300px]"
                                            isPassword={true}
                                        />
                                        <CustomInputField
                                            type="password"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={
                                                handleConfirmPasswordChange
                                            }
                                            errorMessage={confirmPasswordError}
                                            className="w-[300px]"
                                            isPassword={true}
                                        />
                                        <CustomButton
                                            text="Set Password"
                                            onClick={() =>
                                                handleSubmit(
                                                    new Event('submit') as any
                                                )
                                            }
                                            backgroundColor="blue"
                                            textColor="white"
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
            {loading && (
                <div className="flex items-center h-[100px] justify-center text-center text-veryDarkGray dark:text-veryLightGray">
                    <LoadingSpinner color="#FFAC27" />
                </div>
            )}
        </div>
    )
}
