import React, { ReactNode } from 'react'

export const InfoTextIdeaPreview = () => {
    return (
        <div>
            <p className="text-center">
                In this step, you can preview the selected idea, with relevant
                information about the topic and its outcome.
                <br />
                <br />
                The top section shows the title and details of the selected
                topic.
                <br />
                <br />
                The middle section displays the articles and reviews to be used
                in the protocol draft.
                <br />
                <br />
                The bottom section shows journals ranked by impact factor.
                <br />
                <br />
                You can also view a table with relevant clinical trials by
                clicking the "Relevant clinical trials" button on the top left.
            </p>
        </div>
    )
}

export const InfoTextIdeaGeneration = () => {
    return (
        <div className="text-center space-y-4">
            <p>
                In this step, you can see all suggested ideas generated from the
                previous pool of articles/reviews.
            </p>
            <p>
                Below each suggested title, you'll find all the project details
                and relevant information.
            </p>
            <p>
                If you're not satisfied, click the{' '}
                <strong>“Recalculate button”</strong> for a new set of ideas.
            </p>
            <p>
                To understand how Erudita generated these results, click the{' '}
                <strong>“Relevant articles”</strong> button to see all articles
                considered.
            </p>
            <p>
                When you're ready, select the idea you like most and click{' '}
                <strong>“Next”</strong> to proceed to the idea preview.
            </p>
        </div>
    )
}

export const InfoTextLiteratureSearch = () => {
    return (
        <div className="text-center">
            <p>
                In this step, you'll search the PubMed database. Erudita will
                condense the results, which you can refine.
            </p>

            <p className="font-bold mt-4">
                You can perform different types of search:
            </p>
            <ul className="list-none mt-4 space-y-4">
                <li>
                    <span className="font-bold">Quick search:</span> Enter words
                    related to your idea (like a search engine).
                </li>
                <li>
                    <span className="font-bold">Advanced search:</span> Use
                    boolean search nomenclature (minor syntax errors may cause
                    issues).
                </li>
                <li>
                    <span className="font-bold">PICO/PECO framework:</span>{' '}
                    Build a query with refined keywords, no boolean required.
                    <br />
                    <br />
                    You can filter by date, and once satisfied, click the search
                    button. You can tweak your query and regenerate if needed.
                    <br />
                    <br />
                    When you're happy with the query, click “Extract results” to
                    proceed.
                </li>
            </ul>
        </div>
    )
}

export const InfoTextProtocolGeneration = () => {
    return (
        <div>
            <p className="text-center">
                This is the final step. Here, you can add the authors and
                collaborators of the project.
                <br />
                <br />
                Afterward, click “Generate protocol!” to start generating your
                protocol draft.
                <br />
                <br />
                Hover over highlighted text for definitions of terms used.
                <br />
                <br />
                You can download the draft as .PDF or .DOCX by clicking the
                “Download” button.
                <br />
                <br />
                The project will be saved in your "History" section, allowing
                you to revisit it anytime.
            </p>
        </div>
    )
}

export const InfoTextResultsExtraction = () => {
    return (
        <div className="text-center space-y-4">
            <p>
                Here, you can view all the results extracted by Erudita from
                your previous query.
            </p>
            <p>
                You can preview articles and reviews and use the magnifying
                glass to search for terms within them.
            </p>
            <p>
                Clicking a title will display its details on the right side,
                highlighting terms from your query.
            </p>
            <p>
                If any article is missing, manually add it by expanding the “Add
                articles” dropdown at the bottom.
            </p>
            <p>
                When you're ready, click the <strong>“Generate ideas!”</strong>{' '}
                button to continue.
            </p>
        </div>
    )
}
