import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/atoms/Card'
import { Subtitle } from '../../components/atoms/Subtitle'
import { Title } from '../../components/atoms/Title'
import CustomInputField from '../../components/atoms/CustomInputField'
import { CustomButton } from '../../components/buttons/CustomButton'
import { toast } from 'react-toastify'
import apiClient from '../../api/apiClient'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import { ReactComponent as SmallLogo } from '../../assets/small-logo.svg'

export function RecoverPassword() {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        // Validación del correo electrónico
        if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Please enter a valid email address.')
            return
        }

        setLoading(true)
        try {
            await apiClient.passwordResetV3PasswordResetPost({ email })
            toast.info(
                'If your email exists in our database, you will receive an email with instructions to change your password'
            )
            // Limpiar el email y el error después de enviar el formulario
            setEmail('')
            setEmailError(null)

            setTimeout(() => {
                navigate('/login')
            }, 3000)
        } catch (error: any) {
            toast.info(
                'If your email exists in our database, you will receive an email with instructions to change your password'
            )
            // Limpiar el email y el error en caso de fallo también
            setEmail('')
            setEmailError(null)

            setTimeout(() => {
                navigate('/login')
            }, 3000)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex items-center justify-center ">
            <div className="w-full  md:w-[660px] h-full flex flex-col items-center justify-center">
                <div className="flex items-center flex-col">
                    <div className="flex flex-col items-center pb-10">
                        <SmallLogo className="h-[80px] w-[80px]" />
                    </div>
                </div>
                <Card>
                    {!loading && (
                        <div className="flex items-center flex-col">
                            {/* ↓ Title & subtitle section ↓ */}
                            <div className="flex flex-col items-center pt-5">
                                <Subtitle
                                    className="text-4xl text-darkgray dark:text-gray"
                                    text={
                                        'Enter your email and click send reset link and we '
                                    }
                                />
                                <Subtitle
                                    className="text-4xl text-darkgray dark:text-gray"
                                    text={
                                        'will send you a temporary recovery link to your email.'
                                    }
                                />
                            </div>

                            {/* ↓ Form section ↓ */}
                            <div className="flex flex-col items-center justify-center p-10">
                                <form
                                    onSubmit={handleSubmit}
                                    className="w-full space-y-4"
                                    noValidate
                                >
                                    <div className="flex flex-col space-y-2">
                                        <CustomInputField
                                            type="email"
                                            placeholder="E-mail"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            errorMessage={emailError}
                                            className="bg-transparent border-none focus:outline-none w-[300px]"
                                        />
                                    </div>
                                    <div className="flex justify-center">
                                        <CustomButton
                                            text="Send reset link"
                                            onClick={() =>
                                                handleSubmit(
                                                    new Event('submit') as any
                                                )
                                            }
                                            backgroundColor="blue"
                                            textColor="white"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    {loading && (
                        <div className="flex items-center justify-center h-[400px]">
                            <LoadingSpinner color="#FFAC27" />
                        </div>
                    )}
                </Card>
            </div>
        </div>
    )
}
