import Card from '../../components/atoms/Card'
import { Title } from '../../components/atoms/Title'
import Modal from '../../components/atoms/Modal'
import React, { ReactNode, useEffect } from 'react'

interface InfoModalProps {
    title: string
    text: ReactNode
    isOpen: boolean
    closeAction: () => void
}

export const InfoModal = ({
    title,
    text,
    isOpen,
    closeAction,
}: InfoModalProps) => {
    // Close modal pressing ESC
    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeAction()
            }
        }

        // Add listener for ESC key
        if (isOpen) {
            document.addEventListener('keydown', handleEscKey)
        }

        // Clean listener once modal is closed
        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [isOpen, closeAction])

    return (
        <Modal isOpen={isOpen} onClose={closeAction}>
            <Card>
                <div
                    className={
                        'flex flex-col gap-4 items-center md:max-w-[500px] lg:max-w-[700px] text-veryDarkGray dark:text-veryLightGray'
                    }
                >
                    <Title text={title} />
                    <div className={'text-center'}>
                        <p>{text}</p>
                    </div>
                </div>
            </Card>
        </Modal>
    )
}
