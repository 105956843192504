import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { linkedInAuthorization } from '../../middleware/authService'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import { toast } from 'react-toastify'
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner'
import Card from '../../components/atoms/Card'
import { Subtitle } from '../../components/atoms/Subtitle'

const LinkedInRedirect = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        const handleAuthorization = async () => {
            try {
                // Parse the authorization code
                const queryParams = new URLSearchParams(location.search)
                const code = queryParams.get('code')
                const error = queryParams.get('error')
                const errorDescription = queryParams.get('error_description')

                if (error) {
                    throw new Error(
                        errorDescription || 'LinkedIn authorization failed'
                    )
                }

                if (!code) {
                    throw new Error(
                        'No authorization code received from LinkedIn'
                    )
                }

                const data = await linkedInAuthorization(code)

                if (!data || !data.jwtToken) {
                    throw new Error('Authentication failed - no token received')
                }

                // Save the token in local storage
                LocalStorageUtil.setToken(data.jwtToken)
                LocalStorageUtil.setRefreshToken(data.refreshToken)
                const email = JSON.parse(
                    atob(data.jwtToken.split('.')[1])
                ).user_email
                LocalStorageUtil.setEmail(email)

                // Navigate based on profile status
                if (data.hasProfile) {
                    window.location.href = '/'
                } else {
                    navigate('/create-profile')
                }
            } catch (error: any) {
                setError(
                    error.message ||
                        'An unexpected error occurred during LinkedIn authentication'
                )
                toast.error(
                    error.message || 'Failed to authenticate with LinkedIn'
                )
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            } finally {
                setIsLoading(false)
            }
        }

        handleAuthorization()
    }, [location, navigate])

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Card>
                    <div className="flex flex-col items-center gap-4 p-8">
                        <Subtitle text="Loading, please wait." />
                        <LoadingSpinner color="#2874A6" />
                    </div>
                </Card>
            </div>
        )
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Card>
                    <div className="flex flex-col items-center gap-4 p-8">
                        <div className="text-red">{error}</div>
                    </div>
                </Card>
            </div>
        )
    }

    return null
}

export default LinkedInRedirect
