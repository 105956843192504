/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SrcModelsSchemasUserProfileOutput
 */
export interface SrcModelsSchemasUserProfileOutput {
    /**
     * User email
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    userEmail?: string
    /**
     * User first name
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    firstName: string
    /**
     * User last name
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    lastName: string
    /**
     * Social media profile link
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    otherProfile: string
    /**
     * Company
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    company: string
    /**
     * User's job title
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    jobTitle: string
    /**
     * User's job industry
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    industry: string
    /**
     * Country
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    country: string
    /**
     * Whether user wants to receive communications
     * @type {boolean}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    communications: boolean
    /**
     * Whether user wants to get notified when a long running process finishes
     * @type {boolean}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    notifications: boolean
    /**
     * User's pubmed API key
     * @type {string}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    pubmedApiKey?: string
    /**
     * Number of protocols available for the user (protocols being generated are not substracted from this number).
     * @type {number}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    numAvailableProtocols: number
    /**
     * Whether user has an active subscription or not
     * @type {boolean}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    hasSubscription: boolean
    /**
     * User's subscription details
     * @type {object}
     * @memberof SrcModelsSchemasUserProfileOutput
     */
    subscription?: {
        name: string
        search: {
            used: number
            limit: number
        }
        ideas: {
            used: number
            limit: number
        }
        protocols: {
            used: number
            limit: number
        }
        collaborators_limit: number
    }
}

/**
 * Check if a given object implements the SrcModelsSchemasUserProfileOutput interface.
 */
export function instanceOfSrcModelsSchemasUserProfileOutput(
    value: object
): value is SrcModelsSchemasUserProfileOutput {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false
    if (!('lastName' in value) || value['lastName'] === undefined) return false
    if (!('otherProfile' in value) || value['otherProfile'] === undefined)
        return false
    if (!('company' in value) || value['company'] === undefined) return false
    if (!('jobTitle' in value) || value['jobTitle'] === undefined) return false
    if (!('industry' in value) || value['industry'] === undefined) return false
    if (!('country' in value) || value['country'] === undefined) return false
    if (!('communications' in value) || value['communications'] === undefined)
        return false
    if (!('notifications' in value) || value['notifications'] === undefined)
        return false
    if (
        !('numAvailableProtocols' in value) ||
        value['numAvailableProtocols'] === undefined
    )
        return false
    if (!('hasSubscription' in value) || value['hasSubscription'] === undefined)
        return false
    return true
}

export function SrcModelsSchemasUserProfileOutputFromJSON(
    json: any
): SrcModelsSchemasUserProfileOutput {
    return SrcModelsSchemasUserProfileOutputFromJSONTyped(json, false)
}

export function SrcModelsSchemasUserProfileOutputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SrcModelsSchemasUserProfileOutput {
    if (json == null) {
        return json
    }
    return {
        userEmail: json['user_email'] == null ? undefined : json['user_email'],
        firstName: json['first_name'],
        lastName: json['last_name'],
        otherProfile: json['other_profile'],
        company: json['company'],
        jobTitle: json['job_title'],
        industry: json['industry'],
        country: json['country'],
        communications: json['communications'],
        notifications: json['notifications'],
        pubmedApiKey:
            json['pubmed_api_key'] == null ? undefined : json['pubmed_api_key'],
        numAvailableProtocols: json['num_available_protocols'],
        hasSubscription: json['has_subscription'],
        subscription:
            json['subscription'] == null ? undefined : json['subscription'],
    }
}

export function SrcModelsSchemasUserProfileOutputToJSON(
    value?: SrcModelsSchemasUserProfileOutput | null
): any {
    if (value == null) {
        return value
    }
    return {
        user_email: value['userEmail'],
        first_name: value['firstName'],
        last_name: value['lastName'],
        other_profile: value['otherProfile'],
        company: value['company'],
        job_title: value['jobTitle'],
        industry: value['industry'],
        country: value['country'],
        communications: value['communications'],
        notifications: value['notifications'],
        pubmed_api_key: value['pubmedApiKey'],
        num_available_protocols: value['numAvailableProtocols'],
        has_subscription: value['hasSubscription'],
        subscription: value['subscription'],
    }
}
