/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectOutput } from './ProjectOutput';
import {
    ProjectOutputFromJSON,
    ProjectOutputFromJSONTyped,
    ProjectOutputToJSON,
} from './ProjectOutput';
import type { UserHistoryV2 } from './UserHistoryV2';
import {
    UserHistoryV2FromJSON,
    UserHistoryV2FromJSONTyped,
    UserHistoryV2ToJSON,
} from './UserHistoryV2';

/**
 * 
 * @export
 * @interface SrcRoutersProjectData
 */
export interface SrcRoutersProjectData {
    /**
     * Project settings information
     * @type {ProjectOutput}
     * @memberof SrcRoutersProjectData
     */
    information?: ProjectOutput;
    /**
     * Project history
     * @type {UserHistoryV2}
     * @memberof SrcRoutersProjectData
     */
    history?: UserHistoryV2;
}

/**
 * Check if a given object implements the SrcRoutersProjectData interface.
 */
export function instanceOfSrcRoutersProjectData(value: object): value is SrcRoutersProjectData {
    return true;
}

export function SrcRoutersProjectDataFromJSON(json: any): SrcRoutersProjectData {
    return SrcRoutersProjectDataFromJSONTyped(json, false);
}

export function SrcRoutersProjectDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcRoutersProjectData {
    if (json == null) {
        return json;
    }
    return {
        
        'information': json['information'] == null ? undefined : ProjectOutputFromJSON(json['information']),
        'history': json['history'] == null ? undefined : UserHistoryV2FromJSON(json['history']),
    };
}

export function SrcRoutersProjectDataToJSON(value?: SrcRoutersProjectData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'information': ProjectOutputToJSON(value['information']),
        'history': UserHistoryV2ToJSON(value['history']),
    };
}

