/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleItem } from './ArticleItem';
import {
    ArticleItemFromJSON,
    ArticleItemFromJSONTyped,
    ArticleItemToJSON,
} from './ArticleItem';
import type { Journal } from './Journal';
import {
    JournalFromJSON,
    JournalFromJSONTyped,
    JournalToJSON,
} from './Journal';
import type { SrcModelsSchemasClinicalTrial } from './SrcModelsSchemasClinicalTrial';
import {
    SrcModelsSchemasClinicalTrialFromJSON,
    SrcModelsSchemasClinicalTrialFromJSONTyped,
    SrcModelsSchemasClinicalTrialToJSON,
} from './SrcModelsSchemasClinicalTrial';

/**
 * 
 * @export
 * @interface SrcModelsSchemasIdeaItem
 */
export interface SrcModelsSchemasIdeaItem {
    /**
     * Idea ID
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    id?: string;
    /**
     * ID of ideas set associated
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    ideasSetId?: string;
    /**
     * [old] Idea ID
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    ideaIds?: string;
    /**
     * Title of the idea
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    title?: string;
    /**
     * Number of articles associated to the idea
     * @type {number}
     * @memberof SrcModelsSchemasIdeaItem
     */
    numArticles?: number;
    /**
     * Number of reviews associated to the idea
     * @type {number}
     * @memberof SrcModelsSchemasIdeaItem
     */
    numReviews?: number;
    /**
     * List article associated to the idea
     * @type {Array<ArticleItem>}
     * @memberof SrcModelsSchemasIdeaItem
     */
    articles: Array<ArticleItem>;
    /**
     * List review associated to the idea
     * @type {Array<ArticleItem>}
     * @memberof SrcModelsSchemasIdeaItem
     */
    reviews: Array<ArticleItem>;
    /**
     * 
     * @type {ArticleItem}
     * @memberof SrcModelsSchemasIdeaItem
     */
    lastReview?: ArticleItem | null;
    /**
     * Idea's target outcome
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    targetOutcome?: string;
    /**
     * List of journals associated to the idea
     * @type {Array<Journal>}
     * @memberof SrcModelsSchemasIdeaItem
     */
    potentialJournals?: Array<Journal>;
    /**
     * Whether the idea is high impact (1) or not (0)
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    isBlueOcean?: string;
    /**
     * Datetime of idea creation
     * @type {string}
     * @memberof SrcModelsSchemasIdeaItem
     */
    createdAt?: string;
    /**
     * Number of weeks since last review
     * @type {number}
     * @memberof SrcModelsSchemasIdeaItem
     */
    numWeeksSince?: number;
    /**
     * List of clinical trial associated to the idea
     * @type {Array<SrcModelsSchemasClinicalTrial>}
     * @memberof SrcModelsSchemasIdeaItem
     */
    clinicalTrials?: Array<SrcModelsSchemasClinicalTrial>;
}

/**
 * Check if a given object implements the SrcModelsSchemasIdeaItem interface.
 */
export function instanceOfSrcModelsSchemasIdeaItem(value: object): value is SrcModelsSchemasIdeaItem {
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    return true;
}

export function SrcModelsSchemasIdeaItemFromJSON(json: any): SrcModelsSchemasIdeaItem {
    return SrcModelsSchemasIdeaItemFromJSONTyped(json, false);
}

export function SrcModelsSchemasIdeaItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SrcModelsSchemasIdeaItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'ideasSetId': json['ideas_set_id'] == null ? undefined : json['ideas_set_id'],
        'ideaIds': json['idea_ids'] == null ? undefined : json['idea_ids'],
        'title': json['title'] == null ? undefined : json['title'],
        'numArticles': json['num_articles'] == null ? undefined : json['num_articles'],
        'numReviews': json['num_reviews'] == null ? undefined : json['num_reviews'],
        'articles': ((json['articles'] as Array<any>).map(ArticleItemFromJSON)),
        'reviews': ((json['reviews'] as Array<any>).map(ArticleItemFromJSON)),
        'lastReview': json['last_review'] == null ? undefined : ArticleItemFromJSON(json['last_review']),
        'targetOutcome': json['target_outcome'] == null ? undefined : json['target_outcome'],
        'potentialJournals': json['potential_journals'] == null ? undefined : ((json['potential_journals'] as Array<any>).map(JournalFromJSON)),
        'isBlueOcean': json['is_blue_ocean'] == null ? undefined : json['is_blue_ocean'],
        'createdAt': json['created_at'] == null ? undefined : json['created_at'],
        'numWeeksSince': json['num_weeks_since'] == null ? undefined : json['num_weeks_since'],
        'clinicalTrials': json['clinical_trials'] == null ? undefined : ((json['clinical_trials'] as Array<any>).map(SrcModelsSchemasClinicalTrialFromJSON)),
    };
}

export function SrcModelsSchemasIdeaItemToJSON(value?: SrcModelsSchemasIdeaItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ideas_set_id': value['ideasSetId'],
        'idea_ids': value['ideaIds'],
        'title': value['title'],
        'num_articles': value['numArticles'],
        'num_reviews': value['numReviews'],
        'articles': ((value['articles'] as Array<any>).map(ArticleItemToJSON)),
        'reviews': ((value['reviews'] as Array<any>).map(ArticleItemToJSON)),
        'last_review': ArticleItemToJSON(value['lastReview']),
        'target_outcome': value['targetOutcome'],
        'potential_journals': value['potentialJournals'] == null ? undefined : ((value['potentialJournals'] as Array<any>).map(JournalToJSON)),
        'is_blue_ocean': value['isBlueOcean'],
        'created_at': value['createdAt'],
        'num_weeks_since': value['numWeeksSince'],
        'clinical_trials': value['clinicalTrials'] == null ? undefined : ((value['clinicalTrials'] as Array<any>).map(SrcModelsSchemasClinicalTrialToJSON)),
    };
}

