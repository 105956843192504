import ArrowButton from '../buttons/ArrowButton'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../context/WizardContext'
import { useEffect, useState } from 'react'
import { SrcModelsSchemasUserHistoryItem } from '../../api/openapi'
import { GoDotFill } from 'react-icons/go'
import { ProtocolRunning } from '../../api/openapi'

export const WizardProgressBar = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()
    const {
        historyData,
        loading,
        error,
        projectData,
        refreshData,
        protocolsRunning,
    } = useData()
    const [currentHistory, setCurrentHistory] =
        useState<SrcModelsSchemasUserHistoryItem | null>(null)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        //find the history item of current project
        if (!historyData || !historyData.items) {
            return
        }
        setCurrentHistory(
            historyData.items.find(
                (project) =>
                    project.searchId === searchId ||
                    project.ideasSetId === ideasSetId ||
                    project.ideaId === ideaId
            ) || null
        )
    }, [historyData])

    // Define the steps of the wizard as an array
    let steps = [
        {
            name: 'Literature Search',
            path: '/literature-search',
            disabled: false,
        },
        {
            name: 'Results extraction',
            path: '/results-extraction',
            disabled: !searchId && !currentHistory?.searchId,
        },
        {
            name: 'Ideas generation',
            path: '/ideas-generation',
            disabled: !currentHistory?.ideasSetId && !ideasSetId,
        },
        {
            name: 'Idea preview',
            path: '/idea-preview',
            disabled: !currentHistory?.ideaId && !ideaId,
        },
        {
            name: 'Protocol generation',
            path: '/protocol-generation',
            disabled: !currentHistory?.protocolId && !ideaId,
        },
    ]

    // Find the current step based on the current URL path
    const currentStepIndex = steps.findIndex((step) =>
        location.pathname.includes(step.path)
    )

    // Function to handle navigation to the previous step
    async function onClickBack() {
        if (currentStepIndex > 0) {
            if (currentStepIndex === 1) {
                navigate(`/wizard/${projectId}/literature-search/${searchId}`)
            }
            if (currentStepIndex === 2) {
                if (searchId)
                    navigate(
                        `/wizard/${projectId}/results-extraction/${searchId}`
                    )
            }
            if (currentStepIndex === 3) {
                if (currentHistory?.ideasSetId && currentHistory?.searchId)
                    navigate(
                        `/wizard/${projectId}/ideas-generation/${currentHistory?.searchId}/${currentHistory?.ideasSetId}`
                    )
            }
            if (currentStepIndex === 4) {
                if (ideaId) {
                    navigate(
                        `/wizard/${projectId}/idea-preview/${currentHistory?.ideasSetId}/${ideaId}`
                    )
                }
            }
        }
    }

    // Function to handle navigation to the next step
    async function onClickNext() {
        if (currentStepIndex < steps.length - 1) {
            // Idea Preview special case
            if (currentStepIndex === 3) {
                let protocolIdToUse: string | null = null

                // Check if projectData and historyData are available
                if (projectData && historyData && historyData.items) {
                    // Find the history item that matches the current ideaId
                    const currentHistoryItem:
                        | SrcModelsSchemasUserHistoryItem
                        | undefined = historyData.items.find(
                        (item: SrcModelsSchemasUserHistoryItem) =>
                            item.ideaId === ideaId
                    )

                    // If a matching history item is found and it has a protocolId, use that ID
                    if (currentHistoryItem && currentHistoryItem.protocolId) {
                        protocolIdToUse = currentHistoryItem.protocolId
                    }
                }

                //Refresh data to be sure protocolsRunning is up to date

                await refreshData()

                const isProtocolRunning =
                    protocolsRunning && protocolsRunning.length > 0 // correctly check if there are protocols running
                if (protocolIdToUse && !isProtocolRunning) {
                    navigate(
                        `/wizard/${projectId}/protocol-generation/${ideasSetId}/${ideaId}/${protocolIdToUse}`
                    )
                } else {
                    navigate(
                        `/wizard/${projectId}/protocol-generation/${ideasSetId}/${ideaId}`
                    )
                }

                return
            }

            if (currentStepIndex === 0) {
                if (searchId) {
                    await refreshData()
                    navigate(
                        `/wizard/${projectId}/results-extraction/${searchId}`
                    )
                }
            }
            if (currentStepIndex === 1) {
                if (currentHistory?.ideasSetId) {
                    await refreshData()
                    navigate(
                        `/wizard/${projectId}/ideas-generation/${searchId}/${currentHistory?.ideasSetId}`
                    )
                }
            }
            if (currentStepIndex === 2) {
                if (currentHistory?.ideaId) {
                    await refreshData()
                    navigate(
                        `/wizard/${projectId}/idea-preview/${currentHistory?.ideasSetId}/${currentHistory?.ideaId}`
                    )
                }
            }

            if (currentStepIndex === 4) {
            }
        }
    }

    return (
        <div className={'flex flex-row gap-x-7 items-center'}>
            {/* Back button */}
            <div className={'transition-transform transform hover:scale-125'}>
                <ArrowButton
                    onClick={onClickBack}
                    direction={'left'}
                    disabled={currentStepIndex === 0}
                    iconSize={4}
                />
            </div>

            {/* Progress steps */}
            <div className={'flex flex-row gap-x-4 items-center text-blue'}>
                {steps.map((step, index) => (
                    <div
                        key={step.path}
                        className={'flex flex-row items-center gap-x-4'}
                    >
                        <div
                            className={`text-sm ${
                                index === currentStepIndex
                                    ? 'font-bold text-blue underline' // Highlight current step
                                    : 'text-blue'
                            }
                            ${step.disabled && 'text-gray'}
                            `}
                        >
                            {step.name}
                        </div>

                        {/* Vertical line */}
                        {index < steps.length - 1 && (
                            <GoDotFill className={'text-yellow'} />
                        )}
                    </div>
                ))}
            </div>

            {/* Next button */}
            <div className={'transition-transform transform hover:scale-125'}>
                <ArrowButton
                    onClick={onClickNext}
                    direction={'right'}
                    disabled={
                        currentStepIndex === steps.length - 1 ||
                        steps[currentStepIndex + 1].disabled
                    }
                    iconSize={4}
                />
            </div>
        </div>
    )
}
