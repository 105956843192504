import { FaMinus } from 'react-icons/fa'

export const MinusButton = ({
    onClick,
    iconSize = 6,
    disabled = false,
}: {
    onClick: () => void
    iconSize?: number
    disabled?: boolean
}) => {
    // Map iconSize to appropriate Tailwind classes
    const sizeClasses = {
        2: 'w-4 h-4',
        3: 'w-6 h-6',
        4: 'w-8 h-8',
        5: 'w-10 h-10',
        6: 'w-12 h-12',
        7: 'w-14 h-14',
        8: 'w-16 h-16',
    }

    const iconSizeClasses = {
        2: 'w-2 h-2',
        3: 'w-3 h-3',
        4: 'w-4 h-4',
        5: 'w-5 h-5',
        6: 'w-6 h-6',
        7: 'w-7 h-7',
        8: 'w-8 h-8',
    }

    // Default to size 6 if the provided size is not in our mapping
    const buttonSizeClass =
        sizeClasses[iconSize as keyof typeof sizeClasses] || sizeClasses[6]
    const iconSizeClass =
        iconSizeClasses[iconSize as keyof typeof iconSizeClasses] ||
        iconSizeClasses[6]

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`flex items-center justify-center rounded-full transition-transform transform hover:scale-110
            ${disabled ? 'bg-lightGray text-veryDarkGray cursor-not-allowed' : 'bg-yellow text-white'} ${buttonSizeClass} p-1`}
        >
            <FaMinus className={iconSizeClass} />
        </button>
    )
}
